// import ConfigGlobalParametersContext from "../contexts/ConfigGlobalParameters.context";

export const PAGE_INFO = {
  globalParametersView: {
    TotalSIDCount: "Total SID Count",
    TotalAIDCount: "Total AID Count",
    AdminAction: "Admin Action",
    CumulativePhyliValue: "Cumulative Phyli Value",
    WaitingSyncPairing: "Waiting Sync List Pairing",
    LastGlobalConsensus: "Last Global Consensus",
    PrimarySyncTime: "Primary Sync Time",
    MaxBridgeInterval: "Max Bridge Interval",
    MinSyncListSize: "Min Sync List Size",
    MissedSyncTime: "Missed Sync Inactive Time",
    InactiveDisenrollment: "Inactive Disenrollment",
    CurrentPhyliPrice: "Current Phyli Price",
  },
  globalConsensusView: {
    GlobalPhyliBalance: "Global Phyli Balance",
    GlobalPhyliHolds: "Global Phyli Holds",
    TotalBlocksCreated: "Total Blocks Created",
    PeersUnderConsensus: "Peers Under Consensus",
  },
  bridgeConsensusView: {
    TotalPhyliTransferred: "Total Phyli Transferred",
    GlobalHoldMargin: "Global Hold Margin",
    BridgeHoldMargin: "Bridge Hold Margin",
    CumulativeBridgeTrust: "Cumulative Bridge Trust",
    NumberOfSL: "Number of SL/Ledgers",
    GlobalPLPercentage: "Global PL Percentage",
  },
  syncListView: {
    BlocksLast24: "Blocks Last 24hrs",
    GlobalHoldMargin: "Global Hold Margin",
    HoldMargin: "Hold Margin",
    CumlativeTrust: "Cumulative Trust",
    NumberOfLedgers: "Number of Ledgers",
    TrustSTDeviation: "Trust ST Deviation",
    LicenseType: "License Type",
    image: "https://crea.coop/wp-content/uploads/2021/08/map-2021-scaled.jpg",
  },
  peerLedgerDBView: {
    PhyliBalance: "Phyli Balance",
    PhyliHold: "Phyli Hold",
    StampBalance: "Stamp Balance",
    LicenseType: "License Type",
    TransctionContractNum: "Transaction Contract Number",
    SyncListNumber: "SyncList Number",
  },
  peerLedgerBCView: {
    BlockType: "Block Type",
    BlockTime: "Block Time",
    BlockNumber: "Block Number",
    PreviousHash: "Previous Hash",
    BlockHash: "Block Hash",
  },
  dataPath: "",

  globalLast: `${""}/last`, //we named this file globalLast but it points to /last instead of /global/last       from globals.context
  globalPageDown: `${""}/global/pageDown`,
  globalParameters: `${""}/global/parameters`,
  globalStats: `${""}/global/stats`,
  ConfigGlobalParameters: `${""}/config/GlobalParameters`,
  primaryRange: `${""}/primary/range`,
  bridgePageUp: `${""}/bridge/pageUp`,
  contentLast: `${""}/content/last`,
  contentPageDown: `${""}/content/pageDown`,
  peers: `${""}/peers/`,
  psl: `${""}/psl/`,
  last: `${""}/last`, // this file also points to last.         from peerLedgerBCView.component
  deserialized: `${""}/deserialized`, // only works on global 30, related to pearLedgerDCview.component

  // http://localhost:42074
  // https://api.philos.us:42074
};
