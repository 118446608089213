export const toggleMenu = (evt, slider) => {
  console.log("toggle menu", slider);

  console.log(evt, "event");
  // CHECK BUTTON ID FOR DISPLAY
  if (evt === ""){evt = 'slide-close';}
  if (
    slider.slideMenu &&
    evt === "slide-close" &&
    slider.slideState === "partial"
  ) {
    slider.setSlideMenu(false);
    slider.setSlideState("");
  } else if (!slider.slideMenu === "" && evt === "show-content") {

    slider.slideMenu(true);
    slider.setSlideState("partial");
  } else if (slider.slideMenu && evt === "show-content") {
    slider.setSlideState("show");
    slider.setSlideData(evt);
  } else if (evt === "slide-close" && slider.slideState === "show") {
    slider.setSlideState("partial");
  } else if (evt === "show-content-sync" && slider.slideState === "partial") {
 
    slider.setSlideState("show");
    slider.setSlideData(evt);
  } else if (evt === 'show-content-sync') {
    slider.setSlideState("partial");
    slider.setSlideMenu(1);
    slider.setSlideData(evt);
  }   else if (  (1 <= evt.item.BlockType <=4 )) {
    // console.log('HIT');
    slider.setSlideData2(evt);

    slider.setSlideMenu(true);
    slider.setSlideState("show");
    slider.setSlideData('primary');

    // console.log('================================================================================================================================',slider);
  
  }   

};
