import { createContext, useState } from "react";

// import { BridgesContext } from "./Bridges.context";
// import { PAGE_INFO } from "../data/pageInfo.data";

export const PrimaryContext = createContext();

const PrimaryContextProvider = (props) => {
  const [primaryList, setPrimaryList] = useState([
    {
      isLoading: true,
      isError: false,
      bridge: 0,
      peerNum: 0,
      primaryData: [],
    },
  ]);
  return <PrimaryContext.Provider value={{ primaryList, setPrimaryList }}>{props.children}</PrimaryContext.Provider>;
};

export default PrimaryContextProvider;
// export const useFetchPrimary = () => {
//   const { primaryList, setPrimaryList } = useContext(PrimaryContext);
//   const { bridges } = useContext(BridgesContext);
//   let newIndex = -1;

//   const fetchPrimary = async (blockNumber, peerNumber) => {
//     bridges.map((bridge, index) => {
//       bridge.bridgeData.map((item) => {
//         if (item.BlockNumber === parseInt(blockNumber)) {
//           newIndex = index;
//         }
//         return newIndex;
//       });
//       return newIndex;
//     });

//     try {
//       const setGlobal = await fetch(`${PAGE_INFO.dataPath}/global/${bridges[newIndex].global}`, {
//         method: "GET",
//         mode: "cors",
//         credentials: "same-origin",
//       });
//       const setBridge = await fetch(`${PAGE_INFO.dataPath}/bridge/${blockNumber}`, {
//         method: "GET",
//         mode: "cors",
//         credentials: "same-origin",
//       });
//       console.log("find solution", setGlobal, setBridge);
//       const resPrimary = await fetch(`${PAGE_INFO.dataPath}/primary/range`, {
//         method: "GET",
//         mode: "cors",
//         credentials: "same-origin",
//       });
//       const primaryData = await resPrimary.json();
//       if (primaryList.isLoading) {
//         console.log("Loading primary list");
//       }
//       if (String(primaryData) !== "{Error: RNF}") {
//         setPrimaryList([
//           {
//             isLoading: false,
//             isError: false,
//             isClicked: false,
//             bridge: blockNumber,
//             peerNumber: peerNumber,
//             primaryData: primaryData,
//           },
//         ]);
//       } else {
//         setPrimaryList([
//           {
//             isLoading: false,
//             isError: false,
//             isClicked: false,
//             bridge: blockNumber,
//             peerNumber: peerNumber,
//             primaryData: [],
//           },
//         ]);
//       }
//     } catch (error) {
//       setPrimaryList([
//         {
//           isLoading: false,
//           isError: error,
//           isClicked: false,
//           bridge: 0,
//           peerNumber: 0,
//           primaryData: [],
//         },
//       ]);
//     }
//   };
//   return {
//     primaryList: primaryList,
//     setPrimaryList: setPrimaryList,
//     fetchPrimary: fetchPrimary,
//   };
// };
