import React from "react";
import { useState } from "react";

import "./SubmitContract.styles.scss";

const SubmitContract = () => {
  const [contract, setContract] = useState("");
  const [newUrl, setNewUrl] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    // fetch(newUrl, {
    //   mode: "no-cors",
    //   method: "POST",
    //   headers: { "Content-Type": "application/json" },
    //   body: JSON.stringify(contract),
    // }).then(() => {
    //   console.log("contract submitted");
    // });
    alert("This functionality is not available during demo.");
  };

  return (
    <div className='content-view'>
      <div className='contract'>
        <h1>Submit Contract Data</h1>
        <form onSubmit={handleSubmit}>
          <label className='label'>Transaction contract execution input</label>
          <textarea
            className='input input-area'
            name='contract'
            value={contract}
            onChange={(e) => setContract(e.target.value)}
            placeholder='This functionality is not available during demo'
          />
          <label className='label'>Broker agent URL</label>
          <input
            className='input'
            name='newUrl'
            type='text'
            value={newUrl}
            onChange={(e) => setNewUrl(e.target.value)}
            placeholder='This functionality is not available during demo'
          />
          <input className='button' type='submit' value='Submit Contract' />
        </form>
      </div>
    </div>
  );
};

export default SubmitContract;
