export const SUBSTATIONS = [
  {
    FacilityID: "A1021",
    FacilityType: "SUB",
    FacilityName: "RidgeGlen Neighborhood",
    Latitude: 39.833109,
    Longitude: -105.205187,
    CustomerType: "R",
    Description: "Residential Area",
    Transactions: 48,
  },
  {
    FacilityID: "A1022",
    FacilityType: "SUB",
    FacilityName: "Green Stone ",
    Latitude: 39.829003,
    Longitude: -105.118409,
    CustomerType: "R",
    Description: "Residential Area",
    Transactions: 22,
  },
  {
    FacilityID: "A1023",
    FacilityType: "SUB",
    FacilityName: "Hinton Village ",
    Latitude: 39.991421,
    Longitude: -104.756174,
    CustomerType: "R",
    Description: "Residential Area",
    Transactions: 57,
  },
  {
    FacilityID: "A1024",
    FacilityType: "SUB",
    FacilityName: "The Palmview ",
    Latitude: 39.98066,
    Longitude: -104.821414,
    CustomerType: "R",
    Description: "Residential Area",
    Transactions: 34,
  },
  {
    FacilityID: "A1025",
    FacilityType: "SUB",
    FacilityName: "Tower View Com",
    Latitude: 39.975927,
    Longitude: -105.0284,
    CustomerType: "R",
    Description: "Residential Area",
    Transactions: 28,
  },
  {
    FacilityID: "B2022",
    FacilityType: "SUB",
    FacilityName: "Farmers Industrial Park",
    Latitude: 39.926725,
    Longitude: -105.097737,
    CustomerType: "C",
    Description: "Commercial Area",
    Transactions: 54,
  },
  {
    FacilityID: "B2023",
    FacilityType: "SUB",
    FacilityName: "Iconic Industrials ",
    Latitude: 39.892371,
    Longitude: -104.772855,
    CustomerType: "C",
    Description: "Commercial Area",
    Transactions: 33,
  },
  {
    FacilityID: "B2024",
    FacilityType: "SUB",
    FacilityName: "Cloud Group",
    Latitude: 39.888053,
    Longitude: -104.831302,
    CustomerType: "C",
    Description: "Commercial Area",
    Transactions: 45,
  },
  {
    FacilityID: "B2025",
    FacilityType: "SUB",
    FacilityName: "Center Source ",
    Latitude: 40.050877,
    Longitude: -104.93342,
    CustomerType: "C",
    Description: "Commercial Area",
    Transactions: 42,
  },
  {
    FacilityID: "B2026",
    FacilityType: "SUB",
    FacilityName: "Blake Source ",
    Latitude: 40.084743,
    Longitude: -104.973659,
    CustomerType: "C",
    Description: "Commercial Area",
    Transactions: 41,
  },
  {
    FacilityID: "C3033",
    FacilityType: "SUB",
    FacilityName: "Fremont Station",
    Latitude: 40.079104,
    Longitude: -105.030796,
    CustomerType: "SU",
    Description: "SubStation Step-up",
    Transactions: 12,
  },
  {
    FacilityID: "C3034",
    FacilityType: "SUB",
    FacilityName: "Top Energy ",
    Latitude: 40.018395,
    Longitude: -105.067016,
    CustomerType: "S",
    Description: "SubStation Step-up",
    Transactions: 23,
  },
  {
    FacilityID: "C3035",
    FacilityType: "SUB",
    FacilityName: "The ComProp",
    Latitude: 40.121091,
    Longitude: -104.912027,
    CustomerType: "",
    Description: "SubStation Step-up",
    Transactions: 32,
  },
  {
    FacilityID: "C3036",
    FacilityType: "SUB",
    FacilityName: "Building & Blocks ",
    Latitude: 40.137516,
    Longitude: -104.906279,
    CustomerType: "",
    Description: "SubStation Step-up",
    Transactions: 21,
  },
  {
    FacilityID: "C3037",
    FacilityType: "SUB",
    FacilityName: "Voluminous Sector ",
    Latitude: 40.098498,
    Longitude: -104.767657,
    CustomerType: "",
    Description: "SubStation Step-up",
    Transactions: 42,
  },
  {
    FacilityID: "D4044",
    FacilityType: "SUB",
    FacilityName: "Glen Station",
    Latitude: 40.077621,
    Longitude: -104.802947,
    CustomerType: "SD",
    Description: "SubStation Secondary Step-Down",
    Transactions: 32,
  },
  {
    FacilityID: "D4045",
    FacilityType: "SUB",
    FacilityName: "Atomic Blocks ",
    Latitude: 40.137117,
    Longitude: -104.950543,
    CustomerType: "",
    Description: "SubStation Secondary Step-Down",
    Transactions: 41,
  },
  {
    FacilityID: "D4046",
    FacilityType: "SUB",
    FacilityName: "Hyland Energys Station ",
    Latitude: 40.094925,
    Longitude: -104.986017,
    CustomerType: "",
    Description: "SubStation Secondary Step-Down",
    Transactions: 23,
  },
  {
    FacilityID: "D4047",
    FacilityType: "SUB",
    FacilityName: "Tramjet Corp Station ",
    Latitude: 40.089107,
    Longitude: -104.610179,
    CustomerType: "",
    Description: "SubStation Secondary Step-Down",
    Transactions: 22,
  },
  {
    FacilityID: "D4048",
    FacilityType: "SUB",
    FacilityName: "Sector10 Station ",
    Latitude: 40.067333,
    Longitude: -104.643624,
    CustomerType: "",
    Description: "SubStation Secondary Step-Down",
    Transactions: 42,
  },
  {
    FacilityID: "E5055",
    FacilityType: "SUB",
    FacilityName: "Property One ",
    Latitude: 40.4137,
    Longitude: -104.949865,
    CustomerType: "SP",
    Description: "Substation Primary  Step-Down",
    Transactions: 15,
  },
  {
    FacilityID: "E5056",
    FacilityType: "SUB",
    FacilityName: "Arrrow Head Inc ",
    Latitude: 40.3878,
    Longitude: -104.967873,
    CustomerType: "",
    Description: "Substation Primary Step-Down",
    Transactions: 23,
  },
  {
    FacilityID: "E5057",
    FacilityType: "SUB",
    FacilityName: "Apex Energy ",
    Latitude: 40.112528,
    Longitude: -104.498987,
    CustomerType: "",
    Description: "Substation Primary Step-Down",
    Transactions: 22,
  },
  {
    FacilityID: "E5058",
    FacilityType: "SUB",
    FacilityName: "Landmark Supply ",
    Latitude: 40.099597,
    Longitude: -104.531466,
    CustomerType: "",
    Description: "Substation Primary Step-Down",
    Transactions: 18,
  },
  {
    FacilityID: "E5059",
    FacilityType: "SUB",
    FacilityName: "BuckHorn Energy Plant ",
    Latitude: 40.024961,
    Longitude: -104.727899,
    CustomerType: "",
    Description: "Substation Primary Step-Down",
    Transactions: 33,
  },
  {
    FacilityID: "F6066",
    FacilityType: "SUB",
    FacilityName: "WideField Substation",
    Latitude: 40.004188,
    Longitude: -104.741685,
    CustomerType: "SS",
    Description: "Substation Switching",
    Transactions: 22,
  },
  {
    FacilityID: "F6067",
    FacilityType: "SUB",
    FacilityName: "The Greens ",
    Latitude: 40.009277,
    Longitude: -104.719315,
    CustomerType: "",
    Description: "Substation Switching",
    Transactions: 33,
  },
  {
    FacilityID: "F6068",
    FacilityType: "SUB",
    FacilityName: "Second Hand ",
    Latitude: 40.25397,
    Longitude: -104.996106,
    CustomerType: "",
    Description: "Substation Switching",
    Transactions: 22,
  },
  {
    FacilityID: "F6069",
    FacilityType: "SUB",
    FacilityName: "Amor Industrial",
    Latitude: 40.236082,
    Longitude: -104.98655,
    CustomerType: "",
    Description: "Substation Switching",
    Transactions: 44,
  },
  {
    FacilityID: "F6070",
    FacilityType: "SUB",
    FacilityName: "Anchor85",
    Latitude: 39.897463,
    Longitude: -104.96302,
    CustomerType: "",
    Description: "Substation Switching",
    Transactions: 42,
  },
  {
    FacilityID: "G7077",
    FacilityType: "SUB",
    FacilityName: "Lux District ",
    Latitude: 39.885396,
    Longitude: -104.998581,
    CustomerType: "SC",
    Description: "Substation Collector",
    Transactions: 32,
  },
  {
    FacilityID: "G7078",
    FacilityType: "SUB",
    FacilityName: "Altwater Supply ",
    Latitude: 39.916192,
    Longitude: -104.944996,
    CustomerType: "",
    Description: "Substation Collector",
    Transactions: 43,
  },
  {
    FacilityID: "G7079",
    FacilityType: "SUB",
    FacilityName: "Bio Corp ",
    Latitude: 39.93266,
    Longitude: -104.966578,
    CustomerType: "",
    Description: "Substation Collector",
    Transactions: 24,
  },
  {
    FacilityID: "G7080",
    FacilityType: "SUB",
    FacilityName: "Pro Scout Services ",
    Latitude: 39.938834,
    Longitude: -104.907917,
    CustomerType: "",
    Description: "Substation Collector",
    Transactions: 34,
  },
  {
    FacilityID: "G7081",
    FacilityType: "SUB",
    FacilityName: "Lone Star Compac ",
    Latitude: 39.894101,
    Longitude: -105.119168,
    CustomerType: "",
    Description: "Substation Collector",
    Transactions: 22,
  },
];
