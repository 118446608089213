import { useState, useContext } from "react";

// *****************************
// ***** IMPORT COMPONENTS *****
// *****************************
import SmallCard from "../../components/smallCard/SmallCard.component";
import SyncListTrustChart from "../../components/charts/syncList/SyncListTrustChart";
import SyncListCreditsChart from "../../components/charts/syncList/SyncListCreditsChart";
import SyncListHoldChart from "../../components/charts/syncList/SyncListHoldChart";
import ErrorPage from "../../components/ErrorPage/404Error.component";
// import PrimaryContentView from "../../pages/primaryContentView/PrimaryContentView.page";

// *********************************
// ***** IMPORT CONTEXT / DATA *****
// *********************************
import { SyncListContext } from "../../contexts/SyncList.context";
// import { BridgesContext } from "../../contexts/Bridges.context";
import { SelectedBlockContext } from "../../contexts/SelectedBlock.context";
import { PAGE_INFO } from "../../data/pageInfo.data";
// import GLOBAL_STATS from "../../data/globalStats.data"; // TEMPORARY

// *************************
// ***** IMPORT STYLES *****
// *************************
import "./secondaryContentView.styles.scss";

const SecondaryContentView = (slider) => {
  const [isActive, setIsActive] = useState(false);
  let currentSync = { bridge: 0, sync: {} };
  const syncLists = useContext(SyncListContext);
  const selectedBlock = useContext(SelectedBlockContext);
  const handleClick = () => {
    setIsActive((current) => !current);
  };
  console.log(syncLists.syncLists[1]);

  if (selectedBlock.selectedBlock.item !== undefined) {
    syncLists.syncLists.map((sync) => {
      sync.syncData.map((syncItem) => {
        if (syncItem.SyncListNumber === selectedBlock.selectedBlock.item.SyncListNumber) {
          currentSync = { bridge: sync.bridge, syncData: syncItem };
        }
        return syncItem;
      });
      return sync;
    });
  } else {
    return <ErrorPage />;
  }
  return (
    <div className='syncList-view'>
      <div className='card-row'>
        <SmallCard className='card card-12' title={PAGE_INFO.syncListView.BlocksLast24} content='205' />
        <SmallCard className='card card-12' title={PAGE_INFO.syncListView.GlobalHoldMargin} content={`1%`} />
        <SmallCard className='card card-12' title={PAGE_INFO.syncListView.HoldMargin} content={`1%`} />
        <SmallCard className='card card-12' title={PAGE_INFO.syncListView.CumlativeTrust} content={`1%`} />
        <SmallCard className='card card-12' title={PAGE_INFO.syncListView.NumberOfLedgers} content={`3`} />
        <SmallCard className='card card-12' title={PAGE_INFO.syncListView.TrustSTDeviation} content='+1.88%' />
        <SmallCard className='card card-12' title={PAGE_INFO.syncListView.LicenseType} content='Utility' />
      </div>
      <div className='syncList-container'>
        <div className='syncList-left'>
          <div className='sync-banner'>
            <h2>{`Requesting SyncList:${currentSync.syncData.SyncListNumber}`}</h2>
            <span>{`from Bridge ${currentSync.bridge}`}</span>
            <h3>{`23,900 Phyli`}</h3>
          </div>
          <div className='buttons-row'>
            <button className='selected'>{`${currentSync.syncData.SyncListNumber}`}</button>

            <button
              style={{
                backgroundColor: isActive ? "rgba(0, 255, 0, 0.5)" : "",
                color: isActive ? "selected" : "",
              }}
              onClick={handleClick}
            >
              0071
            </button>
            <button
              style={{
                backgroundColor: isActive ? "rgba(0, 255, 0, 0.5)" : "",
                color: isActive ? "selected" : "",
              }}
              onClick={handleClick}
            >
              0886
            </button>
            <button
              style={{
                backgroundColor: isActive ? "rgba(0, 255, 0, 0.5)" : "",
                color: isActive ? "selected" : "",
              }}
              onClick={handleClick}
            >
              0034
            </button>
          </div>
          <div className='charts-row'>
            <div className='chart-holder'>
              <SyncListTrustChart />
            </div>
            <div className='chart-holder'>
              <SyncListCreditsChart />
            </div>
            <div className='chart-holder'>
              <SyncListHoldChart />
            </div>
          </div>
          <h2>Block Distribution</h2>
          <div className='dist-banner'>
            <p>{`Sync List Leader: ${currentSync.syncData.SyncListNumber}`}</p>
            <p>{`Registered Name: Global Solar, Inc`}</p>
            <p>{`License Type: 234`}</p>
            <p>{`Date SL Created: DT Stamp`}</p>
            <p className='p-center'>Peer Ledgers</p>
            <div className='buttons-row'>
              {currentSync.syncData.Peer !== []
                ? currentSync.syncData.Peer.map((item) => {
                    return <button>{item.SIDNumber}</button>;
                  })
                : null}
            </div>
          </div>
        </div>
        <div className='syncList-right'>
          <img src={PAGE_INFO.syncListView.image} alt='map for Colorado' />
        </div>
      </div>
    </div>
  );
};

export default SecondaryContentView;
