import React from 'react';

import './SmallCard.styles.scss';

const SmallCard = (props) => {
    return (
        <div className={props.className}>
            <h3>{props.title}</h3>
            <h2>{props.content}</h2>
        </div>
    );
}

export default SmallCard;