import { createContext, useState, useContext } from "react";
import { BridgesContext } from "./Bridges.context";
import { GlobalsContext } from "./globals.context";
import { SyncListContext } from "./SyncList.context";

export const SelectedBlockContext = createContext();

const SelectedBlockContextProvider = (props) => {
  const [selectedBlock, setSelectedBlock] = useState(-1);

  return (
    <SelectedBlockContext.Provider value={{ selectedBlock, setSelectedBlock }}>
      {props.children}
    </SelectedBlockContext.Provider>
  );
};

export const useGetSelectedBlock = () => {
  const { selectedBlock, setSelectedBlock } = useContext(SelectedBlockContext);
  const { bridges } = useContext(BridgesContext);
  const { globals } = useContext(GlobalsContext);
  const { syncLists } = useContext(SyncListContext);

  const fetchBlock = (blockNumber) => {
    if (!globals.isLoading) {
      globals.globalData.map((global) =>
        global.BlockNumber === parseInt(blockNumber) ? setSelectedBlock(global) : null
      );
      // globals.globalData.map((global) => {

      //   if (global.BlockNumber === parseInt(blockNumber)) {
      //     setSelectedBlock(global);
      //   }
      // });
    }
    if (bridges.length > 1) {
      bridges.map((bridge) => {
        if (bridge.bridgeData !== []) {
          bridge.bridgeData.map((item) => {
            if (item.BlockNumber === parseInt(blockNumber)) {
              setSelectedBlock(item);
            }
            return item;
          });
        }
        return bridge;
      });
    }
    if (syncLists.length > 1) {
      syncLists.map((sync) => {
        if (sync.syncData !== []) {
          sync.syncData.map((item) => {
            if (item.SyncListNumber === parseInt(blockNumber)) {
              setSelectedBlock({ item: item, bridge: sync.bridge });
            }
            return item;
          });
        }
        return sync;
      });
    }
  };

  return { selectedBlock: selectedBlock, getSelectedBlock: fetchBlock };
};

export default SelectedBlockContextProvider;
