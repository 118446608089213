import React, { useContext } from "react";
import { Chart } from "react-chartjs-2";

// *******************************
// ***** IMPORT CONTEXT/DATA *****
// *******************************
import { BridgesContext } from "../../../contexts/Bridges.context";
import { GlobalsContext } from "../../../contexts/globals.context";

function BridgeConsensuLine({ treeData }) {
  const { bridges } = useContext(BridgesContext);
  const { selectedGlobal } = useContext(GlobalsContext);
  let graphBridges = [];

  bridges.map((bridge) => {
    if (bridge.global === selectedGlobal) {
      graphBridges = bridge.bridgeData;
    }
    return graphBridges;
  });
  // console.log(graphBridges);
  const data = {
    labels: graphBridges.map((item) => item.BlockNumber).reverse(),
    datasets: [
      {
        label: "Phyli Transferred",
        // data: [],
        data: [10, 4, 20, 30, 40, 20, 50, 22, 55],
        borderColor: ["rgba(255, 0, 0, 1.0)"],
        backgroundColor: ["rgba(255, 0, 0, 0.5)"],
        lineTension: 0.1,
        order: 2,
        fill: true,
      },
      {
        label: "Available Phyli",
        data: [8, 2, 10, 25, 30, 15, 45, 20, 42],
        borderColor: ["rgba(0, 255, 0, 1.0)"],
        backgroundColor: ["rgba(0, 255, 0, 0.5)"],
        lineTension: 0.1,
        order: 1,
        fill: true,
      },
      {
        label: "Hold Phyli",
        data: [6, 0, 8, 23, 25, 9, 42, 18, 40],
        borderColor: ["rgba(0, 0, 255, 1.0)"],
        backgroundColor: ["rgba(0, 0, 255, 0.5)"],
        lineTension: 0.1,
        order: 0,
        fill: true,
      },
    ],
  };

  const options = {
    responsive: true,
    title: {
      display: false,
      text: "Bridge Counts",
    },
    scales: {
      x:{} ,
        // {
        //     gridLines: {
        //         offsetGridLines: true
        //     }
        // }
      
      y: 
        {
          ticks: {
            min: 0,
            max: 100,
            stepsize: 1,
          },
        },
      
    },
  };

  return (
    <div className='chart-size-bridge'>
      <Chart type='line' data={data} options={options} height='175' />
    </div>
  );
}

export default BridgeConsensuLine;
