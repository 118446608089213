import { useQuery } from "react-query";
import { PAGE_INFO } from "../data/pageInfo.data";

const fetchBridgeDeserializedData = async (bridgeInfo) => {
  await fetch(`${PAGE_INFO.dataPath}/global/${bridgeInfo.global}`);
  await fetch(`${PAGE_INFO.dataPath}/bridge/${bridgeInfo.bridge.BlockNumber}`);
  const bridgeDeserialized = await fetch(`${PAGE_INFO.dataPath}/bridge/deserialized`);
  return bridgeDeserialized.json();
};

export const useBridgeDeserializedFetch = (bridgeInfo, deserializedSuccess) => {
  return useQuery(["get-deserializedBridges", bridgeInfo], () => fetchBridgeDeserializedData(bridgeInfo), {
    enabled: false,
    onSuccess: deserializedSuccess,
  });
};
