import { useQuery } from "react-query";
import { PAGE_INFO } from "../data/pageInfo.data";

const fetchMapContent = async () => {
  await fetch(`${PAGE_INFO.dataPath}/global/last`);
  await fetch(`${PAGE_INFO.dataPath}/bridge/last`);
  await fetch(`${PAGE_INFO.dataPath}/peers/2`);
  await fetch(`${PAGE_INFO.dataPath}/tempContent/first`);
  const content = await fetch(`${PAGE_INFO.dataPath}/tempContent/pageUp`);
  return content.json();
};

export const useFetchMapContent = (contentSuccess) => {
  return useQuery("get-mapContent", fetchMapContent, { onSuccess: contentSuccess });
};

const fetchDeserializedMapContent = async (tempContent) => {
  await fetch(`${PAGE_INFO.dataPath}/global/last`);
  await fetch(`${PAGE_INFO.dataPath}/bridge/last`);
  await fetch(`${PAGE_INFO.dataPath}/peers/2`);

  await tempContent.tipData.forEach(async (item, index) => {
    setTimeout(async () => {
      await fetch(`${PAGE_INFO.dataPath}/tempContent/${item.BlockNumber}`);
      const block = await fetch(`${PAGE_INFO.dataPath}/tempContent/deserialized`);
      // console.log(await block.json());
      return block.json();
    }, index * 10000);
    // const desContent = await fetch(`${PAGE_INFO.dataPath}/tempContent/deserialized`);
    // await console.log(desContent);
    // const resContent = await desContent.json();
    // await newContent.push(resContent);
  });
};

export const useFetchDeserialzedMapContent = (tempContent, deserializedSuccess) => {
  return useQuery(["getDeserializedMapData", tempContent], () => fetchDeserializedMapContent(tempContent), {
    enabled: false,
    onSuccess: deserializedSuccess,
  });
};
