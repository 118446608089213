import React from "react";
import { Navigate } from "react-router-dom";
import "./404ErrorPage.styles.scss";

const ErrorPage = () => {
  return (
    <div>
      {/* <h1>404 Error</h1>
      <h2>Page not found ....</h2> */}
      <Navigate to='/' />.
    </div>
  );
};

export default ErrorPage;
