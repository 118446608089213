import React from "react";
// import ReactDOM from "react-dom";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { QueryClientProvider, QueryClient } from "react-query";

import GlobalsContextProvider from "./contexts/globals.context";
// import GliceContextProvider from "./contexts/glice/Glice.context";
import SelectedBlockContextProvider from "./contexts/SelectedBlock.context";
import PrimaryContextProvider from "./contexts/Primary.context";
import BridgesContextProvider from "./contexts/Bridges.context";
import SyncListContextProvider from "./contexts/SyncList.context";
import ContentContextProvider from "./contexts/Content.context";
import { LoginProvider } from "./contexts/Login.contexts";
// import PeersContextProvider from "./contexts/Peers.context";

import "./index.scss";
import App from "./App";
import * as serviceWorker from "./serviceWorker";

const root = createRoot(document.getElementById("root"));
const queryClient = new QueryClient();

root.render(
  <React.StrictMode>
    {/* <GliceContextProvider> */}
    <QueryClientProvider client={queryClient}>
      <GlobalsContextProvider>
        <BridgesContextProvider>
          <SyncListContextProvider>
            <PrimaryContextProvider>
              <ContentContextProvider>
                <SelectedBlockContextProvider>
                  <BrowserRouter>
                    <LoginProvider>
                      <App />
                    </LoginProvider>
                  </BrowserRouter>
                </SelectedBlockContextProvider>
              </ContentContextProvider>
            </PrimaryContextProvider>
          </SyncListContextProvider>
        </BridgesContextProvider>
      </GlobalsContextProvider>
    </QueryClientProvider>
    {/* </GliceContextProvider> */}
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
