// import { interpolateRgbBasis } from "d3-interpolate";
import React from "react";
import { Bar } from "react-chartjs-2";

function PeerLedgerBar({ currentSyncList }) {
  console.log(currentSyncList);
  if (currentSyncList === undefined) {
    console.log("it was true");
  }
  const data = {
    labels: [
      "12 AM",
      "1 AM",
      "2 AM",
      "3 AM",
      "4 AM",
      "5 AM",
      "6 AM",
      "7 AM",
      "8 AM",
      "9 AM",
      "10 AM",
      "11 AM",
      "12 PM",
      // "1 PM",
      // "2 PM",
      // "3 PM",
      // "4 PM",
      // "5 PM",
      // "6 PM",
      // "7 PM",
      // "8 PM",
      // "9 PM",
      // "10 PM",
      // "11 PM",
    ],
    datasets: [
      {
        label: 2,
        barPercentage: 0.1,
        barThickness: 20,
        maxBarThickness: 40,
        minBarLength: 2,
        data: [
          150, 11, 25, 33, 75, 85, 38, 42, 54, 62, 78, 44, 98, 43, 27, 64, 125,
          114, 128, 99, 76, 68, 121, 98, 105, 54, 63, 78, 106, 79, 103, 127, 89,
          65, 108, 110, 48, 97, 112, 123, 96, 57, 69, 72, 56, 156, 221, 79, 64,
          79, 99,
        ],
        backgroundColor: "rgba(0, 0, 255, 0.6)",
        onClick: function (event) {
          var element = document.event.target;
          alert(element.value);
        },
      },

      {
        label: 2,
        barPercentage: 0.1,
        barThickness: 20,
        maxBarThickness: 40,
        minBarLength: 2,
        data: [
          57, 11, 25, 33, 75, 85, 38, 125, 78, 99, 145, 79, 86, 127, 105, 98,
          156, 175, 46, 78, 87, 176, 65, 189, 89, 97, 65, 79, 67, 56, 112, 49,
          98, 76, 77, 43, 122, 110, 98, 97, 67, 158, 76, 92, 67, 87, 112, 143,
          56,
        ],
        backgroundColor: "rgba(255, 0, 0, 0.6)",
      },
      {
        label: 2,
        barPercentage: 0.1,
        barThickness: 20,
        maxBarThickness: 40,
        minBarLength: 2,
        data: [
          152, 11, 25, 33, 75, 85, 38, 27, 145, 97, 56, 69, 98, 74, 64, 112,
          125, 89, 97, 112, 110, 67, 98, 76, 45, 122, 87, 90, 65, 76, 89, 97,
          23, 45, 123, 156, 98, 100, 39, 67, 76, 89, 87, 88, 65, 47, 76, 87, 92,
        ],
        backgroundColor: "rgba(0, 255, 0, 0.6)",
      },
    ],
  };

  const options = {
    // title: {
    //     display: true,
    //     text: 'Bridge Counts',
    // },
    scales: {
      x: { stacked: true },
      y: { stacked: true },
    },
    responsive: true,
    maintainAspectRatio: false,
  };

  return (
    <div className='chart-size'>
      <Bar data={data} options={options} height={"30%"} width={"30%"} />
    </div>
  );
}

export default PeerLedgerBar;
