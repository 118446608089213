import { useQuery } from "react-query";

// *************************
// ***** IMPORT STYLES *****
// *************************
import "./GlobalParametersView_1.styles.scss";

// *****************************
// ***** IMPORT COMPONENTS *****
// *****************************
import SmallCard from "../../components/smallCard/SmallCard.component";
import ParametersPie from "../../components/charts/GlobalParameters/ParametersPieChart";

// *******************************
// ***** IMPORT CONTEXT/DATA *****
// *******************************
import { PAGE_INFO } from "../../data/pageInfo.data";

const fetchParamData = async () => {
  const paramResponse = await fetch(`${PAGE_INFO.dataPath}/config/GlobalParameters`);
  return paramResponse.json();
};

const GlobalParametersView_1 = () => {
  const { data: paramData, status: paramStatus } = useQuery("fetchParam", fetchParamData);

  if (paramStatus === "loading") {
    return (
      <div className='global-param-view'>
        <h1>Loading Data....</h1>
      </div>
    );
  }
  if (paramStatus === "error") {
    return (
      <div className='global-param-view'>
        <h1>Error Loading Data....</h1>
      </div>
    );
  }

  return (
    <div className='global-param-view'>
      <div className='card-row'>
        <SmallCard
          className='card card-15'
          title={PAGE_INFO.globalParametersView.TotalSIDCount}
          content={paramData[0]["TotalSIDCount"]}
        />
        <SmallCard
          className='card card-15'
          title={PAGE_INFO.globalParametersView.TotalAIDCount}
          content={paramData[0]["TotalAIDCount"]}
        />
        <SmallCard
          className='card card-15'
          title={PAGE_INFO.globalParametersView.AdminAction}
          content={paramData[0]["OpenAdminActionCount"]}
        />
        <SmallCard
          className='card card-15'
          title={PAGE_INFO.globalParametersView.CumulativePhyliValue}
          content={`${paramData[0]["CumulativePhyliValue"] / 1000000} Mil`}
        />
        <SmallCard
          className='card card-15'
          title={PAGE_INFO.globalParametersView.WaitingSyncPairing}
          content={paramData[0]["PeersWaitingSyncList"]}
        />
        <SmallCard
          className='card card-15'
          title={PAGE_INFO.globalParametersView.LastGlobalConsensus}
          content={`${paramData[0]["LastGlobalConsensusPercent"] * 100}%`}
        />
      </div>
      <div className='data-container'>
        <div className='data-list'>
          <h2>
            {PAGE_INFO.globalParametersView.PrimarySyncTime}: {paramData[0]["PrimarySyncInterval"]}
          </h2>
          <h2>
            {PAGE_INFO.globalParametersView.MaxBridgeInterval}: {paramData[0]["MaxBridgeInterval"]}
          </h2>
          <h2>
            {PAGE_INFO.globalParametersView.MinSyncListSize}: {paramData[0]["MinimumPrimarySyncSize"]}
          </h2>
          <h2>
            {PAGE_INFO.globalParametersView.MissedSyncTime}: {`${paramData[0]["MissedSyncInactiveInterval"]}`}
          </h2>
          <h2>
            {PAGE_INFO.globalParametersView.InactiveDisenrollment}: {`${paramData[0]["InactiveDisenrollment"]}`}
          </h2>
          <h2>
            {PAGE_INFO.globalParametersView.CurrentPhyliPrice}: {paramData[0]["CurrentPhyliPrice"]}{" "}
          </h2>
        </div>
        <div className='pie-chart'>
          <h1>SID Status</h1>
          <ParametersPie pieData={paramData[0]} />
        </div>
      </div>
    </div>
  );
};

export default GlobalParametersView_1;
