import { useState, useContext } from "react";
import { NavLink } from "react-router-dom";
import * as RiIcons from "react-icons/ri";

// ***** STYLE *****
import "./Navigation.styles.scss";

// ***** CONTEXT *****
import { GlobalsContext } from "../../contexts/globals.context";
import { BridgesContext } from "../../contexts/Bridges.context";
import { useGetSelectedBlock } from "../../contexts/SelectedBlock.context";
import { SyncListContext } from "../../contexts/SyncList.context";
import { PrimaryContext } from "../../contexts/Primary.context";
import { useGetBridges } from "../../hooks/useBridgeFetch.hook";
import { useGetSyncLists } from "../../hooks/useSyncFetch.hook";
import { usePrimaryFetch } from "../../hooks/usePrimaryFetch.hook";

const Navigation = ({ slider }) => {
  const [configArrow, setConfigArrow] = useState(true);
  const [consensusArrow, setConsensusArrow] = useState(false);
  const [bridgeArrow, setBridgeArrow] = useState({ item: -1, index: -1 });
  const [syncArrow, setSyncArrow] = useState({ item: -1, index: -1 });
  const [peersArrow, setPeersArrow] = useState({ item: -1, index: -1 });
  const { globals, selectedGlobal, setSelectedGlobal } =
    useContext(GlobalsContext);
  const [globalSelected, setGlobalSelected] = useState();
  const [bridgeSelected, setBridgeSelected] = useState();
  const [syncSelected, setSyncSelected] = useState();
  // const [peerSelected, setPeerSelected] = useState();
  const [peerHighlight, setPeerHighlight] = useState();
  const [primaryInfo, setPrimaryInfo] = useState();
  const { bridges, setBridges } = useContext(BridgesContext);
  const { getSelectedBlock } = useGetSelectedBlock();
  const { syncLists, setSyncLists, peersLists, setPeersLists } =
    useContext(SyncListContext);
  const { setPrimaryList } = useContext(PrimaryContext);
  // const { fetchPeers } = useFetchPeerList();
  // const { fetchPrimary } = useFetchPrimary();

  // This was put in to fix and test peerselected not used linting error, and peer selected and setPeerSelected
  // console.log("peer selected test Navigation pain", peerSelected);

  // ***** React Query Fetch Functions *****
  const bridgeSuccess = (returnedBridges) => {
    setBridges([
      ...bridges,
      {
        isLoading: false,
        isError: null,
        isClicked: false,
        global: selectedGlobal,
        bridgeData: returnedBridges.reverse(),
      },
    ]);
  };

  const syncSuccess = (returnedSync) => {
    console.log(returnedSync); // {Error: PNF}
    console.log(globalSelected);
    console.log(selectedGlobal);
    if (
      returnedSync !== "{Error: PNF}" &&
      returnedSync !== undefined &&
      returnedSync !== null
    ) {
      setSyncLists([
        ...syncLists,
        {
          isLoading: false,
          isError: false,
          isClicked: false,
          bridge: bridgeSelected,
          global: selectedGlobal,
          syncData: returnedSync,
        },
      ]);

      returnedSync.map((item) =>
        setPeersLists([
          ...peersLists,
          {
            isLoading: false,
            isError: false,
            isClicked: false,
            bridge: bridgeSelected,
            global: selectedGlobal,
            syncNumber: item.SyncListNumber,
            peerData: item.Peer,
          },
        ])
      );
    }
  };

  const primarySuccsess = (returnedPrimary) => {
    setPrimaryList([
      {
        isLoading: false,
        isError: false,
        isClicked: false,
        bridge: primaryInfo[0],
        peerNumber: primaryInfo[1],
        primaryData: returnedPrimary,
      },
    ]);
  };

  const { refetch: fetchBridges } = useGetBridges(
    globalSelected,
    bridgeSuccess
  );
  const { refetch: fetchSyncList } = useGetSyncLists(
    bridgeSelected,
    bridges,
    syncSuccess
  );
  const { refetch: refetchPrimary } = usePrimaryFetch(
    primaryInfo,
    bridges,
    primarySuccsess
  );

  // ***** GLOBAL SELECTED FUNCTIONS *****
  const handleSelected = async (evt) => {
    await setSelectedGlobal(evt);
    // if (bridges.find((item) => item.global === evt)) {
    // if (bridgeArrow.index !== -1) {
    //   return;
    // }
    await fetchBridges();
    // getBridges(evt);
    if (slider.slideMenu === true) {
      slider.setSlideState("partial");
    }
  };

  // ***** BRIDGE ARROW FUNCTIONS *****
  const handleBridgeArrow = async (evt) => {
    const globalNum = await evt;
    let updatedBridges = bridges.map((item) => {
      if (item.global === globalNum && !item.isClicked) {
        return { ...item, isClicked: true };
      } else {
        return { ...item, isClicked: false };
      }
    });
    return setBridges(updatedBridges);
  };

  const bridgeArrowSelected = (evt) => {
    bridges.map((item, index) => {
      if (item.global === evt) {
        setBridgeArrow({ item: evt, index: index });
      }
      return bridgeArrow;
    });
  };

  // ***** BRIDGE ITEM SELECTED FUNCTIONS *****
  const handleBridgeSelected = async (evt) => {
    await setBridgeSelected(evt);
    if (syncLists.find((item) => item.bridge === parseInt(evt))) {
      return;
    }

    // fetchSync(evt);
    fetchSyncList();
    if (slider.slideMenu === true) {
      slider.setSlideState("partial");
    }
  };

  // ***** SYNC ARROW FUNCTIONS *****
  const handleSyncArrow = (evt) => {
    let updatedSyncLists = syncLists.map((item) => {
      // console.log(selectedGlobal);
      if (item.bridge === evt && !item.isClicked) {
        return { ...item, isClicked: true };
      } else {
        return { ...item, isClicked: false };
      }
    });
    return setSyncLists(updatedSyncLists);
  };

  const syncArrowSelected = (evt) => {
    syncLists.map((item, index) => {
      if (item.bridge === evt) {
        setSyncArrow({ item: evt, index: index });
      }
      return syncArrow;
    });
  };

  // ***** SYNC SELECTED FUNCTIONS *****
  const handleSyncSelected = async (evt) => {
    await setSyncSelected(evt);
    // fetchPeers(evt);
    if (slider.slideMenu === true) {
      slider.setSlideState("partial");
    } else if (slider.slideMenu === false) {
      slider.setSlideMenu(true);
      slider.setSlideState("partial");
      //slider.setSlideData({ name: "peer", blockNumber: evt });
    }
  };

  // ***** PEER ARROW FUNCTIONS *****
  const handlePeerArrow = (evt) => {
    // console.log("Peer arrow clicked", selectedGlobal);
    let updatedPeersLists = peersLists.map((item) => {
      if (item.syncNumber === parseInt(evt) && !item.isClicked) {
        // console.log("handle peer arrow", typeof item.syncNumber, typeof evt);
        return { ...item, isClicked: true };
      } else {
        return { ...item, isClicked: false };
      }
    });
    return setPeersLists(updatedPeersLists);
  };

  const peerArrowSelected = (evt) => {
    peersLists.map((item, index) => {
      if (item.syncNumber === parseInt(evt)) {
        setPeersArrow({ item: evt, index: index });
      }
      return peersArrow;
    });
  };

  // ***** PEER SELECTED FUNCTION *****

  const handlePeerSelected = async (evt) => {
    const bridgePeer = await evt[1].split(",");
    // console.log("Bridge peer is set", bridgePeer);
    await setPrimaryInfo(bridgePeer);

    refetchPrimary();
    // setPeerSelected({ bridge: bridgePeer[1] });
    // fetchPrimary(bridgePeer[0], bridgePeer[1]);
    setPeerHighlight(bridgePeer[1]);
    // slider.setSlideMenu(true);
    // slider.setSlideState("show");
    // slider.setSlideData({ name: "peer", blockNumber: evt });
  };

  return (
    <div>
      <ul>
        <li className='nav-link'>
          {/* *******************************************
           ***** CONFIGURAGATION LABEL AND ARROWS *****
           ******************************************* */}
          Configuration{" "}
          {!configArrow ? (
            <RiIcons.RiArrowRightSFill
              className='nav-arrow'
              onClick={() => {
                setConfigArrow(!configArrow);
              }}
            />
          ) : (
            <RiIcons.RiArrowDownSFill
              className='nav-arrow'
              onClick={() => {
                setConfigArrow(!configArrow);
              }}
            />
          )}
        </li>
        <li>
          {/* *****************************
           ***** GLOBAL PARAMETERS *****
           ***************************** */}
          {configArrow ? (
            <NavLink
              className={`nav-link sub-nav ${
                parseInt(selectedGlobal) === 0 ? "selected" : null
              }`}
              id={0}
              key={selectedGlobal}
              to='/'
              exact='true'
              onClick={(evt) => {
                setSelectedGlobal(evt.currentTarget.id);
                setGlobalSelected(evt.currentTarget.id);
                // setSelectedBlock(evt.currentTarget.id);
                handleBridgeArrow(evt.currentTarget.id);
                setBridgeArrow({ item: -1, index: -1 });
                setBridgeSelected();
              }}
            >
              Parameters
            </NavLink>
          ) : null}
        </li>
        {/* **************************************
         ***** CONSENSUS LABEL AND ARROWS *****
         ************************************** */}
        <li className='nav-link'>
          Consensus{" "}
          {!consensusArrow && !globals.isLoading ? (
            <RiIcons.RiArrowRightSFill
              className='nav-arrow'
              onClick={() => {
                setConsensusArrow(!consensusArrow);
              }}
            />
          ) : consensusArrow && !globals.isLoading ? (
            <RiIcons.RiArrowDownSFill
              className='nav-arrow'
              onClick={() => setConsensusArrow(!consensusArrow)}
            />
          ) : null}
        </li>
        {/* **************************
         ***** DISPLAY GLOBAL *****
         ************************** */}
        {globals.isLoading !== true
          ? consensusArrow
            ? globals.globalData.map((global) => {
                return (
                  <div>
                    {/* **********************************
                     ***** DISPLAY BRIDGE ITEM UP *****
                     ********************************** */}
                    <li>
                      {parseInt(bridgeArrow.item) === global.BlockNumber
                        ? bridges[bridgeArrow.index].bridgeData.map(
                            (bridge) => {
                              return (
                                <div>
                                  {/* ********************************
                                   ***** DISPLAY SYNC ITEM UP *****
                                   ******************************** */}
                                  <li className='nav-link'>
                                    {parseInt(syncArrow.item) ===
                                    bridge.BlockNumber
                                      ? syncLists[syncArrow.index].syncData.map(
                                          (sync) => {
                                            return (
                                              <div>
                                                <div className='nav-link'>
                                                  <NavLink
                                                    className={`nav-link sync-sub ${
                                                      sync.SyncListNumber ===
                                                      parseInt(syncSelected)
                                                        ? "selected"
                                                        : null
                                                    }`}
                                                    key={sync.SyncListNumber}
                                                    id={sync.SyncListNumber}
                                                    to='/syncListView'
                                                    exact='true'
                                                    onClick={(evt) => {
                                                      handleSyncSelected(
                                                        evt.currentTarget.id
                                                      );
                                                      getSelectedBlock(
                                                        evt.currentTarget.id
                                                      );
                                                      setBridgeSelected();
                                                      setGlobalSelected();
                                                      // setPeerSelected();
                                                      setPeerHighlight();
                                                    }}
                                                  >
                                                    {`Sync: ${sync.SyncListNumber}`}
                                                  </NavLink>
                                                  {/* ********************************
                                                   ***** PEERLIST ITEM ARROWS *****
                                                   ******************************** */}
                                                  {peersLists.some(
                                                    (item) =>
                                                      !item.isClicked &&
                                                      item.syncNumber ===
                                                        sync.SyncListNumber &&
                                                      item.peerData.length !==
                                                        0 &&
                                                      parseInt(
                                                        selectedGlobal
                                                      ) !== 32
                                                  ) ? (
                                                    <RiIcons.RiArrowRightSFill
                                                      id={sync.SyncListNumber}
                                                      className='nav-arrow'
                                                      onClick={(evt) => {
                                                        handlePeerArrow(
                                                          evt.currentTarget.id
                                                        );
                                                        peerArrowSelected(
                                                          evt.currentTarget.id
                                                        );
                                                      }}
                                                    />
                                                  ) : peersLists.some(
                                                      (item) =>
                                                        item.isClicked &&
                                                        item.syncNumber ===
                                                          sync.SyncListNumber &&
                                                        item.peerData.length !==
                                                          0
                                                    ) ? (
                                                    <RiIcons.RiArrowDownSFill
                                                      className='nav-arrow'
                                                      id={sync.SyncListNumber}
                                                      onClick={(evt) => {
                                                        handlePeerArrow(
                                                          evt.currentTarget.id
                                                        );
                                                        setPeersArrow({
                                                          item: -1,
                                                          index: -1,
                                                        });
                                                        // setSyncSelected();
                                                      }}
                                                    />
                                                  ) : null}
                                                </div>
                                                <li>
                                                  {/* **************************
                                                   ***** PEER LIST ITEM *****
                                                   ************************** */}
                                                  {parseInt(peersArrow.item) ===
                                                    parseInt(
                                                      sync.SyncListNumber
                                                    ) &&
                                                  parseInt(selectedGlobal) !==
                                                    32
                                                    ? peersLists[
                                                        syncArrow.index
                                                      ].peerData.map((peer) => {
                                                        return (
                                                          <div className='nav-Link'>
                                                            <NavLink
                                                              className={`nav-link peer-sub ${
                                                                parseInt(
                                                                  peer.SIDNumber
                                                                ) ===
                                                                parseInt(
                                                                  peerHighlight
                                                                )
                                                                  ? "selected"
                                                                  : null
                                                              }`}
                                                              key={
                                                                peer.SIDNumber
                                                              }
                                                              id={[
                                                                peersLists[
                                                                  syncArrow
                                                                    .index
                                                                ].bridge,
                                                                peer.SIDNumber,
                                                              ]}
                                                              to='/peerLedgerDashboard'
                                                              onClick={(
                                                                evt
                                                              ) => {
                                                                handlePeerSelected(
                                                                  [
                                                                    evt
                                                                      .currentTarget
                                                                      .bridge,
                                                                    evt
                                                                      .currentTarget
                                                                      .id,
                                                                  ]
                                                                );
                                                                setSyncSelected();
                                                                setBridgeSelected();
                                                                setGlobalSelected();
                                                              }}
                                                            >
                                                              {`Peer: ${peer.SIDNumber}`}
                                                            </NavLink>
                                                          </div>
                                                        );
                                                      })
                                                    : null}
                                                </li>
                                              </div>
                                            );
                                          }
                                        )
                                      : null}
                                  </li>
                                  <div className='nav-link'>
                                    <NavLink
                                      className={`nav-link sub-nav bridge-sub ${
                                        parseInt(bridge.BlockNumber) ===
                                        parseInt(bridgeSelected)
                                          ? "selected"
                                          : null
                                      }`}
                                      key={bridge.BlockNumber}
                                      id={bridge.BlockNumber}
                                      to='/BridgeConsensusView'
                                      exact='true'
                                      onClick={(evt) => {
                                        setBridgeSelected(evt.currentTarget.id);
                                        getSelectedBlock(evt.currentTarget.id);
                                        // setSelectedGlobal();
                                        setGlobalSelected(evt.currentTarget.id);
                                        setSyncSelected();

                                        // if (bridgeSelected === evt.target.id) {
                                        //       handleSyncArrow();
                                        //       setSyncArrow({ item: -1, index: -1 });
                                        //     }
                                        handleSyncArrow();
                                        setSyncArrow({ item: -1, index: -1 });

                                        // setPeerSelected();
                                        setPeerHighlight();
                                        handleBridgeSelected(
                                          evt.currentTarget.id
                                        );
                                      }}
                                    >{`Bridge ${bridge.BlockNumber} (${new Date(
                                      bridge.BlockCreateTime * 1000
                                    ).toLocaleTimeString()})`}</NavLink>
                                    {/* ***************************
                                     ***** SYNC LIST ARROW *****
                                     *************************** */}
                                    {syncLists.some(
                                      (item) =>
                                        !item.isClicked &&
                                        parseInt(item.bridge) ===
                                          parseInt(bridge.BlockNumber) &&
                                        item.syncData.length !== 0
                                    ) ? (
                                      <RiIcons.RiArrowRightSFill
                                        id={bridge.BlockNumber}
                                        className='nav-arrow'
                                        onClick={(evt) => {
                                          // setBridgeSelected(evt.currentTarget.id);
                                          handleSyncArrow(evt.currentTarget.id);
                                          setPeersArrow({
                                            item: -1,
                                            index: -1,
                                          });
                                          syncArrowSelected(
                                            evt.currentTarget.id
                                          );
                                          //setSelectedGlobal(evt.currentTarget.id);
                                        }}
                                      />
                                    ) : syncLists.some(
                                        (item) =>
                                          item.isClicked &&
                                          parseInt(item.bridge) ===
                                            parseInt(bridge.BlockNumber) &&
                                          item.syncData.length !== 0
                                      ) ? (
                                      <RiIcons.RiArrowUpSFill
                                        className='nav-arrow'
                                        id={bridge.BlockNumber}
                                        onClick={(evt) => {
                                          handleSyncArrow(evt.currentTarget.id);
                                          handlePeerArrow(evt.currentTarget.id);
                                          setSyncArrow({ item: -1, index: -1 });
                                          setPeersArrow({
                                            item: -1,
                                            index: -1,
                                          });
                                          setSyncSelected();
                                        }}
                                      />
                                    ) : null}
                                  </div>
                                </div>
                              );
                            }
                          )
                        : null}
                    </li>
                    {/* ***** GLOBAL LIST ITEM ***** */}
                    <li className='nav-link'>
                      <NavLink
                        className={`nav-link sub-nav ${
                          parseInt(global.BlockNumber) ===
                          parseInt(globalSelected)
                            ? "selected"
                            : null
                        }`}
                        key={global.BlockNumber}
                        id={global.BlockNumber}
                        to='/globalConsensusView_1'
                        exact='true'
                        onClick={(evt) => {
                          handleSelected(evt.currentTarget.id); //makes arrow
                          handleBridgeArrow(evt.currentTarget.id);
                          setGlobalSelected(evt.currentTarget.id); //sets highlighting
                          if (selectedGlobal === evt.target.id) {
                            handleBridgeArrow();
                            setBridgeArrow({ item: -1, index: -1 });
                          }
                          setBridgeArrow({ item: -1, index: -1 });
                          getSelectedBlock(evt.currentTarget.id);
                          setBridgeSelected();
                          setSyncSelected();
                          // setPeerSelected();
                          setPeerHighlight();
                        }}
                      >
                        {`Global ${global.BlockNumber} (${new Date(
                          global.BlockCreateTime * 1000
                        ).toLocaleDateString()})`}
                      </NavLink>
                      {/* *************************
                       ***** BRIDGES ARROW *****
                       ************************* */}
                      {bridges.some(
                        (item) =>
                          !item.isClicked &&
                          parseInt(item.global) ===
                            parseInt(global.BlockNumber) &&
                          item.bridgeData.length !== 0
                      ) ? (
                        <RiIcons.RiArrowRightSFill
                          id={global.BlockNumber}
                          className='nav-arrow'
                          onClick={(evt) => {
                            handleBridgeArrow(evt.currentTarget.id);
                            handleSyncArrow(evt.currentTarget.id);
                            handlePeerArrow(evt.currentTarget.id);
                            bridgeArrowSelected(evt.currentTarget.id);
                            setSelectedGlobal(evt.currentTarget.id);
                            setGlobalSelected(evt.currentTarget.id);
                            setPeersArrow({ item: -1, index: -1 });
                            setSyncArrow({ item: -1, index: -1 });
                            setBridgeSelected();
                          }}
                        />
                      ) : bridges.some(
                          (item) =>
                            item.isClicked &&
                            parseInt(item.global) ===
                              parseInt(global.BlockNumber) &&
                            item.bridgeData.length !== 0
                        ) ? (
                        <RiIcons.RiArrowUpSFill
                          className='nav-arrow'
                          id={global.BlockNumber}
                          onClick={(evt) => {
                            handleBridgeArrow(evt.currentTarget.id);
                            handleSyncArrow(evt.currentTarget.id);
                            handlePeerArrow(evt.currentTarget.id);
                            setPeersArrow({ item: -1, index: -1 });
                            setSyncArrow({ item: -1, index: -1 });
                            setSelectedGlobal(evt.currentTarget.id);
                            setBridgeArrow({ item: -1, index: -1 });
                          }}
                        />
                      ) : null}
                    </li>
                  </div>
                );
              })
            : null
          : null}
        {/* ********************************************
         ***** SUBMIT TRANSACTION CONTRACT LINK *****
         ******************************************** */}
        <li>
          <NavLink
            className={`nav-link ${
              parseInt(selectedGlobal) === -5 ? "selected" : null
            }`}
            // onClick={() => updateTitle("Submit Transaction Contract")}
            id={-5}
            to='/submitTransactionContract'
            exact='true'
            onClick={(evt) => {
              setSelectedGlobal(evt.currentTarget.id);
              handleBridgeArrow(evt.currentTarget.id);
              setBridgeArrow({ item: -1, index: -1 });
              setBridgeSelected();
              setGlobalSelected();
              setSyncSelected();
              getSelectedBlock(evt.currentTarget.id);
              // setPeerSelected();
            }}
          >
            Submit Contract
          </NavLink>
        </li>
      </ul>
    </div>
  );
};
export default Navigation;
