import { useContext, useState } from "react";

import "./PeerLedgerBCView.styles.scss";

import { SelectedBlockContext } from "../../contexts/SelectedBlock.context";
import { GlobalsContext } from "../../contexts/globals.context";
import { BridgesContext } from "../../contexts/Bridges.context";
import { PAGE_INFO } from "../../data/pageInfo.data";
// import SmallCard from "../../components/smallCard/SmallCard.component";

const PeerLedgerBCView = () => {
  const { selectedBlock } = useContext(SelectedBlockContext);
  const { globalDeserialized } = useContext(GlobalsContext);
  const { bridgeDeserialized } = useContext(BridgesContext);
  const [blockDeserialized, setBlockDeserialized] = useState([]);

  // const fetchDeserialized = async (blocktype, blockNumber) => {
  //   console.log("!!!!the selected block is", selectedBlock);
  //   console.log("the block type and number  is ", blocktype, blockNumber);
  //   await fetch(`${PAGE_INFO.dataPath}/global/${selectedGlobal}`);
  //   let getDeserialized = await fetch(`${PAGE_INFO.dataPath}/${blocktype}/${blockNumber}`);
  //   getDeserialized = await fetch(`${PAGE_INFO.dataPath}/${blocktype}/deserialized`);
  //   const deserializedBlock = await getDeserialized.json();
  //   console.log("!!!!the deserialized block is  ", deserializedBlock);
  //   setBlockDeserialized(deserializedBlock);
  //   console.log("!!!! finished fetchdeserialize");
  // };

  const handleDeserialize = (block) => {
    if (block.BlockType === 9) {
      setBlockDeserialized(globalDeserialized);
    } else if (block.BlockType === 7 || block.BlockType === 6 || block.BlockType === 5) {
      setBlockDeserialized(bridgeDeserialized);
    }
  };

  return (
    <div className='content-view'>
      <div className='card-row'>
        <div className='card'>
          <h3>{PAGE_INFO.peerLedgerBCView.BlockType}</h3>
          <h3>{selectedBlock.BlockType}</h3>
        </div>
        <div className='card '>
          <h3>{PAGE_INFO.peerLedgerBCView.BlockTime}</h3>
          <h3>{new Date(selectedBlock.BlockCreateTime * 1000).toLocaleTimeString()}</h3>
        </div>
        <div className='card'>
          <h3>{PAGE_INFO.peerLedgerBCView.BlockNumber}</h3>
          <h3>{selectedBlock.BlockNumber}</h3>
        </div>
      </div>
      <div className='card-row'>
        <div className='card card-45'>
          <h3>{PAGE_INFO.peerLedgerBCView.PreviousHash}</h3>
          <h4>{selectedBlock.HashPrevious !== undefined ? atob(selectedBlock.HashPrevious) : "No Data"}</h4>
        </div>
        <div className='card card-45'>
          <h3>{PAGE_INFO.peerLedgerBCView.BlockHash}</h3>
          <h4>{selectedBlock.Hash !== undefined ? atob(selectedBlock.Hash) : "No Data"}</h4>
        </div>
      </div>
      <div className='text-row'>
        <div className='text-left'>
          <div className='text-label'>
            <h2>Serialized Data</h2>
          </div>
          <div className='text-area'>
            <p className='text-data text-wrap'>
              {selectedBlock.BlockContent !== undefined ? selectedBlock.BlockContent : "No Data"}
            </p>
          </div>
        </div>
        <div className='text-middle'>
          <button className='component-button' onClick={() => handleDeserialize(selectedBlock)}>
            Deserialize
          </button>
          <button className='component-button'>Verify</button>
          <span>
            <button className='component-button search'>&#x3c;</button>
            <button className='component-button search'>&#x3e;</button>
          </span>
        </div>
        <div className='text-right'>
          <div className='text-label'>
            <h2>Deserialized Data</h2>
          </div>
          <div className='text-area'>
            {selectedBlock.BlockNumber === blockDeserialized.BlockNumber ? (
              <p>
                {blockDeserialized !== undefined
                  ? JSON.stringify(blockDeserialized)
                  : console.log("!!!!the blockDeserialized variable is undefined")}
              </p>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PeerLedgerBCView;
