import { useContext, useEffect } from "react";
// import { NavLink } from "react-router-dom";

// *****************************
// ***** IMPORT COMPONENTS *****
// *****************************
import PeerLedgerBar from "../../components/charts/peerLedger/PeerLedgerBar";

// *********************************
// ***** IMPORT CONTEXT / DATA *****
// *********************************
import { ContentContext } from "../../contexts/Content.context.js";
import { PrimaryContext } from "../../contexts/Primary.context";
import { BridgesContext } from "../../contexts/Bridges.context";
import { SyncListContext } from "../../contexts/SyncList.context";
import { PAGE_INFO } from "../../data/pageInfo.data";
import { useGetContent } from "../../hooks/useContentFetch.hook";
// import SmallCard from "../smallCard/SmallCard.component";
// import PLDB from "../../data/peerLedgerDB.data"; //TEMPORARY
import { toggleMenu } from "../../workers/ToggleMenu.worker";

// *************************
// ***** IMPORT STYLES *****
// *************************
import "./PeerLedgerDBView.styles.scss";

const PeerLedgerDBView = ({ slider }) => {
  const { primaryList } = useContext(PrimaryContext);
  const { contentList, setContentList } = useContext(ContentContext);
  const { peersLists } = useContext(SyncListContext);
  const { bridges } = useContext(BridgesContext);
  // let currentSyncList = [];

  const contentSuccess = (returnedContent) => {
    setContentList([
      {
        isLoading: false,
        isError: false,
        isClicked: false,
        bridge: primaryList[0].bridge,
        peer: primaryList[0].peerNumber,
        contentData: returnedContent,
      },
    ]);
  };

  const { refetch: refetchContent } = useGetContent(
    primaryList[0].bridge,
    primaryList[0].peerNumber,
    bridges,
    contentSuccess
  );

  // console.log("primaryList", primaryList);
  // console.log("contentList", contentList);
  // console.log("syncLists", syncLists[syncLists.length - 1]);
  // console.log("peers Lists", peersLists[peersLists.length - 1]);
  // console.log("bridges", bridges);
  // console.log("currentSync List", currentSyncList);

  useEffect(() => {
    // fetchContent(primaryList[0].bridge, primaryList[0].peerNumber);
    refetchContent();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [primaryList[0].peerNumber]);
  // currentSyncList = syncLists[syncLists.length - 1].syncData[0].Peer;
  // console.log(currentSyncList);

  useEffect(() => {
    slider.setSlideState("hide");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // const handlePrimary = (number) => {
  //   console.log('handleprimary hit');
  //   slider.setSlideData({ name: "primary", blockNumber: number });
  //   slider.setSlideMenu(true);
  //   slider.setSlideState("show");
  // };
  return (
    <div className='ledger-view'>
      <div className='card-row'>
        <div className='card card-15'>
          <h3>{PAGE_INFO.peerLedgerDBView.PhyliBalance}</h3>
          <h2>18000</h2>
        </div>
        <div className='card card-15'>
          <h3>{PAGE_INFO.peerLedgerDBView.PhyliHold}</h3>
          <h2>475</h2>
        </div>
        <div className='card card-15'>
          <h3>{PAGE_INFO.peerLedgerDBView.StampBalance}</h3>
          <h2>27</h2>
        </div>
        <div className='card card-15'>
          <h3>{PAGE_INFO.peerLedgerDBView.StampBalance}</h3>
          <h2>Utility</h2>
        </div>
        <div className='card card-15'>
          <h3>{PAGE_INFO.peerLedgerDBView.TransctionContractNum}</h3>
          <h2>2251</h2>
        </div>
        <div className='card card-15'>
          <h3>{PAGE_INFO.peerLedgerDBView.SyncListNumber}</h3>
          <h2>2319</h2>
        </div>
      </div>
      <div className='card-row'>
        <div className='card card-70'>
          <h2>Viewing Ledger - {primaryList[0].peerNumber}</h2>
          {peersLists[peersLists.length - 1].peerData !== [] ? (
            peersLists[peersLists.length - 1].peerData.map((peer) => (
              <button
                className={`ledger-button ${
                  parseInt(peer.SIDNumber) ===
                  parseInt(primaryList[0].peerNumber ? "selected" : null)
                }`}
              >
                {peer.SIDNumber}
              </button>
            ))
          ) : (
            <div> No Data </div>
          )}
        </div>
      </div>
      <div className='ledger-chart-row'>
        <div className='ledger-chart-left'>
          <PeerLedgerBar />
        </div>
        <div className='ledger-chart-right'>
          <div className='primary-consensus'>
            <table className='hide-scrollbar'>
              <caption>Primary List</caption>
              <thead>
                <tr>
                  <th>Block Number</th>
                  <th>Date</th>
                  <th>Time</th>
                </tr>
              </thead>
              <tbody>
                {primaryList[0].primaryData !== [] &&
                primaryList[0].primaryData !== "{Error: RNF}" ? (
                  primaryList.map((primary) =>
                    primary.primaryData.length > 0 ? (
                      primary.primaryData.map((item) => (
                        <tr>
                          <td
                            className='content-id content-click'
                            onClick={() => toggleMenu({ item }, slider)}
                          >
                            {item.BlockNumber}
                          </td>
                          <td>
                            {new Date(
                              item.BlockCreateTime * 1000
                            ).toLocaleDateString()}
                          </td>
                          <td>
                            {new Date(
                              item.BlockCreateTime * 1000
                            ).toLocaleTimeString()}
                          </td>
                        </tr>
                      ))
                    ) : (
                      <td>No Primary</td>
                    )
                  )
                ) : (
                  <div>No Data</div>
                )}
              </tbody>
            </table>
          </div>
          <div className='primary-consensus'>
            <table className='hide-scrollbar'>
              <caption>Content List</caption>
              <thead>
                <tr>
                  <th>Block ID</th>
                  <th>Time Stamp </th>
                </tr>
              </thead>
              <tbody>
                {contentList[0].contentData.length > 0 &&
                contentList[0].contentData !== "{Error: RNF}" ? (
                  contentList[0].contentData.map((item) => (
                    <tr>
                      <td
                        className='content-id content-click'
                        onClick={() => toggleMenu({ item }, slider)}
                      >
                        {item.BlockNumber}
                      </td>
                      <td>
                        {new Date(
                          item.BlockCreateTime * 1000
                        ).toLocaleTimeString()}
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>No Content Found</tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
  // return (

  //
  //                         {item.BlockNumber}{" "}
  //                       </td>
  //                       <td>
  //                         {new Date(
  //                           item.BlockCreateTime * 1000
  //                         ).toLocaleDateString()}
  //                       </td>
  //                       <td>
  //                         {new Date(
  //                           item.BlockCreateTime * 1000
  //                         ).toLocaleTimeString()}
  //                       </td>
  //                     </tr>
  //                   ))
  //                 ) : (
  //                   <tr>
  //                     <td>NO PRIMARY</td>
  //                   </tr>
  //                 )
  //               )}
  //             </tbody>
  //           </table>
  //         </div>
  //         <div className='primary-consensus'>
  //           <table className='hide-scrollbar'>
  //             <caption>Content List</caption>
  //             <thead>
  //               <tr>
  //                 <th>Block ID</th>
  //                 <th>Time Stamp</th>
  //               </tr>
  //             </thead>
  //             <tbody>
  //               {contentList[0].contentData != null ? (
  //                 contentList[0].contentData.map((item) => {
  //                   return (
  //                     <tr>
  //                       <td
  //                         className='content-id content-click'
  //                         onClick={() => [
  //                           slider.setSlideData2(item),
  //                           toggleMenu({ item }, slider),
  //                           console.log(item),
  //                         ]}
  //                       >item => (
  //                         {item.BlockNumber}{" "}
  //                       </td>

  //                       <td>
  //                         {new Date(
  //                           item.BlockCreateTime * 1000
  //                         ).toLocaleDateString()}
  //                       </td>
  //                     </tr>
  //                   );
  //                 })
  //               ) : (
  //                 <tr>NO CONTENT FOUND</tr>
  //               )}
  //             </tbody>
  //           </table>
  //         </div>
  //       </div>
  //     </div>
  //   </div>
  // );
};

export default PeerLedgerDBView;
