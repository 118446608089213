import { useQuery } from "react-query";
import { PAGE_INFO } from "../data/pageInfo.data";

let newIndex = -1;
let globalNum = 0;

const fetchSyncData = async (blockNumber, bridges) => {
  bridges.map((bridge, index) => {
    bridge.bridgeData.map((item) => {
      if (item.BlockNumber === parseInt(blockNumber)) {
        newIndex = index;
        globalNum = bridge.global;
      }
      return item;
    });
    return newIndex;
  });
  await fetch(`${PAGE_INFO.dataPath}/global/${globalNum}`);
  await fetch(`${PAGE_INFO.dataPath}/bridge/${blockNumber}`);
  const syncData = await fetch(`${PAGE_INFO.dataPath}/psl`);
  return syncData.json();
};

export const useGetSyncLists = (blockNumber, bridges, syncSuccess) => {
  return useQuery(["get-SyncList", blockNumber, bridges], () => fetchSyncData(blockNumber, bridges), {
    enabled: false,
    onSuccess: syncSuccess,
  });
};

// const fetchSyncData = async (globalNumber) => {
//   //   await fetch(`${PAGE_INFO.dataPath}/global/${globalNumber}`);
//   //   const resBridgeData = await fetch(`${PAGE_INFO.bridgePageUp}`);
//   //   return resBridgeData.json();
// };

// export const useGetSyncLists = (globalNumber) => {
//   //   return useQuery(["get-bridges", globalNumber], () => fetchBridgeData(globalNumber), {
//   //     enabled: false,
//   //     onSuccess: bridgeSuccess,
//   //   });
// };
