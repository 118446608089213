// IN PROGRESS _VLAD
// import React, { useContext, useMemo} from 'react'
// import { SelectedBlockContext } from '../../contexts/SelectedBlock.context';
// import { useFetchSyncList } from '../../contexts/SyncList.context';
import './peerButton.styles.scss';
const PeerButton = (props) => {

    // const selectedBlock = useContext(SelectedBlockContext);
  
    // const { syncLists, fetchSync} = useFetchSyncList();
   
  
  //  const proxy_rerender_defeat = useMemo(() => fetchSync(selectedBlock),    // eslint-disable-next-line react-hooks/exhaustive-deps
  //   [selectedBlock]);

//     const thisglobal = syncLists[1].global;
//     const thisbridge = syncLists[1].bridge;
//     const thissync = syncLists[1].syncData[0].SyncListNumber;
//     const thispeerlist = syncLists[1].syncData[0].Peer;
// const debugginginfo = (peer) =>{
//   console.log(proxy_rerender_defeat);
//   console.log('debugging information - The Global, Bridge, Sync, and peer lists are: ',thisglobal,thisbridge,thissync,thispeerlist);
//   console.log('peer',peer.SIDNumber,' info: ', peer)
// }



//     return (
//     <div>
//     {/* <buttton styles={{transform:'scale(5)'}} onClick={renderpeers()}>peerButton</buttton> */}
    
//     {thispeerlist !== undefined && thispeerlist !== null ? thispeerlist.map((peer) => {
//       return(
//         <button className='functional-peer-button' onClick={ () =>{ debugginginfo(peer)}} >{peer.SIDNumber}</button>
//       )
//     }

//    ) :<h2>Data Failed </h2>}
    
//     </div>
//   )
// }




const array = [2,3,4];

return(
  <div className='peer-button-container'>
    {array.map((peer) => {

      return <button  className='peer-button' onClick={()=>{console.log(peer)}}>{peer}</button>
    })
    }
  </div>
)
  }
export default PeerButton