import { useState } from "react";
import { useLoadScript } from "@react-google-maps/api";

// *****************************
// ***** IMPORT COMPONENTS *****
// *****************************
import DisplayMap from "../../components/map/map.component";
import Button from "../../components/button/Button.component";
import { useFetchMapPrimary } from "../../hooks/useFetchMapPrimary.hook";
import { useFetchMapContent } from "../../hooks/useFetchMapContent.hook";

// *************************
// ***** IMPORT STYLES *****
// *************************
import "./SyncListView_1.scss";

const SyncList_1 = ({ slider }) => {
  const [productionBox, setProductionBox] = useState(true);
  const [substationBox, setSubstationBox] = useState(true);
  const [metersBox, setMetersBox] = useState(true);
  const [transactionRange, setTransactionRange] = useState("");
  // const [tempPrimarys, setTempPrimarys] = useState([]);
  const [tempContent, setTempContent] = useState([
    { peer: 0, contentData: [] },
  ]);
  const checkBoxValues = {
    productionBox: productionBox,
    setProductionBox: setProductionBox,
    substationBox: substationBox,
    setSubstationBox: setSubstationBox,
    metersBox: metersBox,
    setMetersBox: setMetersBox,
    transactionRange: transactionRange,
    setTransactionRange: setTransactionRange,
  };

  // const primarySuccess = async (primaryReturn) => {
  //   console.log(primaryReturn);
  //   await setTempPrimarys(primaryReturn);
  // };

  const contentSuccess = async (contentReturn) => {
    await setTempContent({ peer: 2, tipData: contentReturn });
  };

  // useFetchMapPrimary(primarySuccess);
  useFetchMapContent(contentSuccess);

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_MAP_DISPLAY,
  });

  if (!isLoaded) return <div>Loading...</div>;
  return (
    <div className='synclist-view'>
      <div className='sync-top-bar'>
        <Button
          text='Type'
          title='This button allows you to select the type of facility you are viewing'
          checkBoxes={checkBoxValues}
          slider={slider}
        />
        <Button
          text='Transactions'
          checkBoxes={checkBoxValues}
          title='This button displays a list to sort substations by the number of transactions in the last 8 hours'
          slider={slider}
        />
      </div>
      <div className='sync-map-display'>
        <DisplayMap
          checkBoxes={checkBoxValues}
          slider={slider}
          meters={tempContent}
        />
      </div>
    </div>
  );
};

export default SyncList_1;
