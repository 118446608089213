import { useContext } from "react";
import { Pie } from "react-chartjs-2";

import { SyncListContext } from "../../../contexts/SyncList.context";

function SyncListHoldChart() {
  const { syncLists } = useContext(SyncListContext);
  const peersList = syncLists[1].syncData[0].Peer;

  const data = {
    labels: peersList.map((item) => item.SIDNumber),
    datasets: [
      {
        label: "Bridges Per Global",
        data: peersList.map((item) => item.PhyliHold),
        backgroundColor: [
          "rgba(45, 206, 86, 1)",
          "rgba(45, 255, 008, 1)",
          "rgba(30, 206, 186, 1)",
          "rgba(140, 255, 86, 1)",
          "rgba(245, 206, 186, 0.5)",
          "rgba(45, 106, 246, 0.5)",
          "rgba(145, 106, 86, 0.5)",
          "rgba(45, 06, 186, 0.5)",
          "rgba(145, 57, 25, 0.5)",
          "rgba(145, 06, 186, 0.5)",
          "rgba(05, 106, 186, 0.5)",
        ],
      },
    ],
  };

  const options = {
    title: {
      display: true,
      text: "Holds",
      fontSize: 15,
      fontColor: "black",
    },
    legend: {
      display: false,
    },
    responsive: true,
  };

  return (
    <div className='chart-size'>
      <Pie data={data} options={options} height='250' />
    </div>
  );
}

export default SyncListHoldChart;
