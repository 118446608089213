import { useState, useEffect, useContext } from "react";

// *****************************
// ***** IMPORT COMPONENTS *****
// *****************************
import SmallCard from "../../components/smallCard/SmallCard.component";
import ChartRow from "../../components/chartRow/ChartRow.component";
import GlobalLineChart from "../../components/charts/globalConsensus/GlobalLineChart";
import GlobalPieChart from "../../components/charts/globalConsensus/GlobalPieChart";

// *********************************
// ***** IMPORT DATA / CONTEXT *****
// *********************************
import { PAGE_INFO } from "../../data/pageInfo.data";
import GLOBAL_STATS from "../../data/globalStats.data";
import { SelectedBlockContext } from "../../contexts/SelectedBlock.context";
import { GlobalsContext } from "../../contexts/globals.context";

// ************************
// ***** IMPORT HOOKS *****
// ************************
import { useFetchGlobalDeserialized } from "../../hooks/useFetchGlobalDeserialized.hook";

// *************************
// ***** IMPORT STYLES *****
// *************************
import "./GlobalConsensusView_1.styles.scss";

const GlobalConsensus_1 = ({ data, globalData }) => {
  const blockSelected = useContext(SelectedBlockContext);
  const { globals, setGlobalDeserialized } = useContext(GlobalsContext);

  // This is temporary and will go away when live global data is available.
  const [globalIndex, setGlobalIndex] = useState(-2);

  const globalDeserializedSuccess = async (returnedGlobalDeserialized) => {
    await setGlobalDeserialized(returnedGlobalDeserialized);
  };

  const { refetch: refetchGlobal } = useFetchGlobalDeserialized(blockSelected, globalDeserializedSuccess);

  useEffect(() => {
    GLOBAL_STATS.map((item, index) => {
      if (item.BlockNumber === blockSelected.selectedBlock.BlockNumber) {
        setGlobalIndex(index);
      }
      return index;
    });

    if (blockSelected.selectedBlock.BlockType === 9) {
      refetchGlobal();
    }
  }, [blockSelected, refetchGlobal]);

  if (globals.isLoading) {
    return (
      <div>
        <h1>Globals Loading...</h1>
      </div>
    );
  } else if (globals.globalData === []) {
    return (
      <div>
        <h1>Globals failed to load please refresh...</h1>
      </div>
    );
  } else {
    return (
      <div className='global-consensus-view'>
        <div className='card-row'>
          <SmallCard
            className='card'
            title={PAGE_INFO.globalConsensusView.GlobalPhyliBalance}
            content={globalIndex !== -2 ? GLOBAL_STATS[globalIndex].GlobalPhyliBal : "No Data"}
          />
          <SmallCard
            className='card'
            title={PAGE_INFO.globalConsensusView.GlobalPhyliHolds}
            content={globalIndex !== -2 ? GLOBAL_STATS[globalIndex].GlobalPhyliHold : "No Data"}
          />
          <SmallCard
            className='card'
            title={PAGE_INFO.globalConsensusView.TotalBlocksCreated}
            content={globalIndex !== -2 ? GLOBAL_STATS[globalIndex].BlocksAdded : "No Data"}
          />
          <SmallCard
            className='card'
            title={PAGE_INFO.globalConsensusView.PeersUnderConsensus}
            content={globalIndex !== -2 ? GLOBAL_STATS[globalIndex].PeersUnderConsensus : "No Data"}
          />
        </div>
        <ChartRow
          chartLine={<GlobalLineChart globalData={data.globalsData} globalsData={globalData} />}
          chartPie={
            <GlobalPieChart
              phyliBal={globalIndex !== -2 ? GLOBAL_STATS[globalIndex].GlobalPhyliBal : "No Data"}
              phyliHold={globalIndex !== -2 ? GLOBAL_STATS[globalIndex].GlobalPhyliHold : "No Data"}
            />
          }
        />
      </div>
    );
  }
};

export default GlobalConsensus_1;
