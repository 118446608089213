import React from "react";
import { Chart } from "react-chartjs-2";

function GlobalPieChart({ phyliBal, phyliHold }) {
  const data = {
    labels: ["Global Phyli Balance", " Global Phyli Holds"],
    datasets: [
      {
        label: "Globals",
        data: [phyliBal, phyliHold],
        backgroundColor: [
          "rgba(45, 206, 86, 0.5)",
          "rgba(45, 255, 8, 0.5)",
          "rgba(30, 206, 186, 0.5)",
          "rgba(140, 255, 86, 0.5)",
          "rgba(245, 206, 186, 0.5)",
          "rgba(45, 106, 246, 0.5)",
          "rgba(145, 106, 86, 0.5)",
          "rgba(45, 06, 186, 0.5)",
          "rgba(145, 57, 25, 0.5)",
          "rgba(145, 06, 186, 0.5)",
          "rgba(05, 106, 186, 0.5)",
        ],
      },
    ],
  };

  const options = {
    // title: {
    //     display: true,
    //     text: 'Bridge Counts'
    // },
    responsive: true,
  };

  return (
    <div className='pie-size-consensus'>
      <Chart type='pie' data={data} options={options} height='150' />
    </div>
  );
}

export default GlobalPieChart;
