import React from 'react';

import './ChartRow.styles.scss';

const ChartRow = (props) => {
    return (
        <div className="chart-row">
            {props.chartLine}
            {props.chartPie}
            {/* <div className="chart chart-left">
                {props.chartLine}
            </div>
            <div className="chart chart-right">
                
                    {props.chartPie}
                         
            </div> */}
        </div>
    )
}

export default ChartRow;
