import { createContext, useState } from "react";

export const SyncListContext = createContext();

const SyncListContextProvider = (props) => {
  const [syncLists, setSyncLists] = useState([
    {
      isLoading: true,
      isError: false,
      isClicked: false,
      bridge: 0,
      global: 0,
      syncData: [],
    },
  ]);

  const [peersLists, setPeersLists] = useState([
    {
      isLoading: true,
      isError: false,
      isClicked: false,
      syncNumber: 0,
      bridge: 0,
      peerData: [],
    },
  ]);

  return (
    <SyncListContext.Provider
      value={{ syncLists, setSyncLists, peersLists, setPeersLists }}
    >
      {props.children}
    </SyncListContext.Provider>
  );
};

export default SyncListContextProvider;
// export const useFetchSyncList = () => {
//   const { syncLists, setSyncLists } = useContext(SyncListContext);
//   const { peersLists, setPeersLists } = useContext(SyncListContext);
//   const { bridges } = useContext(BridgesContext);
//   let newIndex = -1;
//   let globalNum = 0;

//   const fetchSync = async (blockNumber) => {
//     bridges.map((bridge, index) => {
//       bridge.bridgeData.map((item) => {
//         if (item.BlockNumber === parseInt(blockNumber)) {
//           newIndex = index;
//           globalNum = bridge.global;
//         }
//         return item;
//       });
//       return index;
//     });
//     try {
//       const setGlobal = await fetch(`${PAGE_INFO.dataPath}/global/${globalNum}`, {
//         method: "GET",
//         mode: "cors",
//         credentials: "same-origin",
//       });
//       const setBridge = await fetch(`${PAGE_INFO.dataPath}/bridge/${blockNumber}`, {
//         method: "GET",
//         mode: "cors",
//         credentials: "same-origin",
//       });
//       const resSync = await fetch(`${PAGE_INFO.dataPath}/psl/`, {
//         method: "GET",
//         mode: "cors",
//         credentials: "same-origin",
//       });
//       console.log("find solution", setGlobal, setBridge, newIndex);
//       const syncData = await resSync.json();
//       if (syncLists.isLoading) {
//         console.log("Loading sync list");
//       }
//       if (String(syncData) !== "{Error: PNF}") {
//         setSyncLists([
//           ...syncLists,
//           {
//             isLoading: false,
//             isError: false,
//             isClicked: false,
//             bridge: blockNumber,
//             global: globalNum,
//             syncData: syncData,
//           },
//         ]);

//         syncData.map((item) =>
//           setPeersLists([
//             ...peersLists,
//             {
//               isLoading: false,
//               isError: false,
//               isClicked: false,
//               bridge: blockNumber,
//               global: globalNum,
//               syncNumber: item.SyncListNumber,
//               peerData: item.Peer,
//             },
//           ])
//         );
//       } else {
//         setSyncLists([
//           ...syncLists,
//           {
//             isLoading: false,
//             isError: false,
//             isClicked: false,
//             bridge: 0,
//             syncData: [],
//           },
//         ]);

//         setPeersLists([
//           ...peersLists,
//           {
//             isLoading: false,
//             isError: false,
//             isClicked: false,
//             bridge: 0,
//             syncNumber: 0,
//             peerData: [],
//           },
//         ]);
//       }
//     } catch (error) {
//       console.log(error);
//       setSyncLists([
//         {
//           isLoading: false,
//           isError: error,
//           isClicked: false,
//           bridge: 0,
//           syncData: [],
//         },
//       ]);

//       setPeersLists([
//         ...peersLists,
//         {
//           isLoading: false,
//           isError: false,
//           isClicked: false,
//           bridge: 0,
//           syncNumber: 0,
//           peerData: [],
//         },
//       ]);
//     }
//   };
//   // console.log(syncLists);
//   return {
//     syncLists: syncLists,
//     fetchSync: fetchSync,
//     setSyncLists: setSyncLists,
//     peersLists: peersLists,
//     setPeersLists: setPeersLists,
//   };
// };
