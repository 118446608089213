const CONSENSUS_DATA = {
  globalBalance: 12415,
  totalHolds: 526,
  globalBlocksCreated: 155,
  peersUnderConsensus: 12,
  globalsData: {
    date: [
      "02/21/2021",
      "02/22/2021",
      "02/23/2021",
      "02/24/2021",
      "02/25/2021",
      "02/26/2021",
      "02/27/2021",
    ],
    totals: {
      balance: [
        12222, 12415, 11722, 13564, 12777, 11567, 13987, 18275, 15483, 21654,
      ],
      holds: [1455, 2567, 1759, 2448, 1525, 1654, 3335, 5276, 3875, 8756, 7753],
    },
    pieChart: {
      credits: 54679,
      holds: 567,
    },
  },
};
export default CONSENSUS_DATA;
