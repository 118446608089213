import { createContext, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";

import { PAGE_INFO } from "../data/pageInfo.data";

export const LoginContext = createContext();

export const LoginProvider = ({ children }) => {
  const [user, setUser] = useState();
  const navigate = useNavigate();

  const login = async (user) => {
    await setUser(user);
    navigate("/", { replace: true });
    await fetch(`${PAGE_INFO.dataPath}/dataLog`, {
      mode: "no-cors",
      method: "POST",
      // headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ Email: user }),
    });
  };

  const logout = () => {
    setUser();
  };

  return (
    <LoginContext.Provider value={{ user, login, logout }}>
      {children}
    </LoginContext.Provider>
  );
};

export const useLogin = () => {
  return useContext(LoginContext);
};
