const TREEDATA = [
    {
        globalBlock: 11,
        bridges: [
            {
                bridge: 11, 
                syncs: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11]
            },
            {
                bridge: 12, 
                syncs: [12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22]
            },

        ]
    },
    {
        globalBlock: 12,
        bridges: [
            {
                bridge: 18,
                syncs: [1, 2, 3, 4, 5, 6, 7, 8, 9]
            }
        ]
    }
    // {
    //     globalBlock: 11,
    //     bridges: [11, 12, 14, 15, 16, 17], 
    //     syncs: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11]
    // },
    // {
    //     globalBlock: 12,
    //     bridges: [18, 19, 20, 21],
    //     syncs: [12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22]
    // }, 
    // {
    //     globalBlock: 13,
    //     bridges: [22, 23, 24, 25, 26, 27, 28, 29],
    //     syncs: [23, 24, 25, 26, 27, 28, 29, 30, 31, 32]
    // },
    // {
    //     globalBlock: 14, 
    //     bridges: [30, 31, 32, 33, 34, 35, 36],
    //     syncs: [33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43,]
    // }
]

export default TREEDATA;