import { useEffect, useState, useContext } from "react";
import {
  GoogleMap,
  Polyline,
  Marker,
  useLoadScript,
  InfoWindow,
} from "@react-google-maps/api";
import { PAGE_INFO } from "../../data/pageInfo.data";
import { AREA1, AREA2 } from "../../data/unitedPowerArea.data";
import { SUBSTATIONS } from "../../data/substations";
import { PRODUCTION, METERS } from "../../data/production";
// import { useLocation } from "react-router-dom";

import { GlobalsContext } from "../../contexts/globals.context";

import "./map.styles.scss";

// import SliderContainer from "../slider/Slider.component";

function DisplayMap({ checkBoxes, slider, meters }) {
  const center = { lat: 40.071, lng: -105.0199 };
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_MAP_DISPLAY,
  });
  const [subs, setSubs] = useState([]);
  const [areas, setAreas] = useState([]);
  const [localMeters, setLocalMeters] = useState([]);
  const [production, setProduction] = useState([]);
  const [selected, setSelected] = useState();
  const [meterData, setMeterData] = useState([]);
  const { selectedGlobal } = useContext(GlobalsContext);
  const [mapClicked, setMapClicked] = useState(false);
  // const location = useLocation();
  // console.log(location);
  // console.log(selectedGlobal);

  const addMeters = () => {
    setMapClicked(true);
    setLocalMeters([]);
    METERS.forEach((meter, index) => {
      setTimeout(() => {
        setLocalMeters((localMeters) => [...localMeters, meter]);
        // console.log(meter);
      }, index * 30000);
    });
  };

  const addSubstations = () => {
    setSubs([]);
    SUBSTATIONS.forEach((sub, index) => {
      setTimeout(() => {
        setSubs((subs) => [...subs, sub]);
      }, index * 8000);
    });
  };

  const addProduction = () => {
    setProduction([]);
    PRODUCTION.forEach((gen, index) => {
      setTimeout(() => {
        setProduction((production) => [...production, gen]);
      }, index * 15000);
    });
  };

  // console.log(meters);
  const deserializedData = () => {
    meters.tipData.forEach((item, index) => {
      setTimeout(async () => {
        await fetch(`${PAGE_INFO.dataPath}/global/last`);
        await fetch(`${PAGE_INFO.dataPath}/bridge/last`);
        await fetch(`${PAGE_INFO.dataPath}/peers/${item.PeerSID}`);
        await fetch(`${PAGE_INFO.dataPath}/tempContent/${item.BlockNumber}`);
        const deserialized = await fetch(
          `${PAGE_INFO.dataPath}/tempContent/deserialized`
        );
        const block = await deserialized.json();
        await setMeterData((meterData) => [...meterData, block]);
        // console.log(block, item, index);
      }, index * 3000);
    });
  };

  // const mapRef = React.useRef();
  // const onMapLoad = React.useCallback((map) => {
  //   mapRef.current = map;
  // }, []);
  // const deserializeMeters = () => {
  //   setMeterData([]);
  //   meters.map(async (meter) => {
  //     const myMeter = [];
  //     await fetch(`${PAGE_INFO.dataPath}/peers/${meter.peer}`);
  //     meter.contentData.map(async (content) => {
  //       await content.map(async (item) => {
  //         await fetch(`${PAGE_INFO.dataPath}/tempContent/${item.BlockNumber}`);
  //         const resDeserialized = await fetch(`${PAGE_INFO.dataPath}/tempContent/deserialized`);
  //         const deserialized = await resDeserialized.json();
  //         await myMeter.push(deserialized);
  //       });
  //     });
  //     setMeterData((meterData) => [...meterData, { peer: meter.peer, content: myMeter }]);
  //   });
  // };

  // useEffect(() => {
  //   deserializeMeters();
  // }, []);
  // console.log(Number(atob(localMeters[0].tipData[0].Payload.ExecutionOutput).split(/[:!]/)[9]));

  const loadedSubs = () => {
    let loadedSubs = [];
    if (parseInt(selectedGlobal) !== 32) {
      if (checkBoxes.transactionRange === "all") {
        loadedSubs = SUBSTATIONS;
        setSubs(loadedSubs);
      } else if (checkBoxes.transactionRange === "range1") {
        SUBSTATIONS.map((item) => {
          if (item.Transactions > 0 && item.Transactions <= 10) {
            loadedSubs.push(item);
          }
          return loadedSubs;
        });
        setSubs(loadedSubs);
      } else if (checkBoxes.transactionRange === "range2") {
        SUBSTATIONS.map((item) => {
          if (item.Transactions > 10 && item.Transactions <= 20) {
            loadedSubs.push(item);
          }
          return loadedSubs;
        });
        setSubs(loadedSubs);
      } else if (checkBoxes.transactionRange === "range3") {
        SUBSTATIONS.map((item) => {
          if (item.Transactions > 20 && item.Transactions <= 30) {
            loadedSubs.push(item);
          }
          return loadedSubs;
        });
        setSubs(loadedSubs);
      } else if (checkBoxes.transactionRange === "range4") {
        SUBSTATIONS.map((item) => {
          if (item.Transactions > 30 && item.Transactions <= 40) {
            loadedSubs.push(item);
          }
          return loadedSubs;
        });
        setSubs(loadedSubs);
      } else if (checkBoxes.transactionRange === "range5") {
        SUBSTATIONS.map((item) => {
          if (item.Transactions > 40) {
            loadedSubs.push(item);
          }
          return loadedSubs;
        });
        setSubs(loadedSubs);
      }
    }
  };

  const onMapClick = async () => {
    // console.log("click");
    if (parseInt(selectedGlobal) === 32 && !mapClicked) {
      addSubstations();
      addProduction();
      addMeters();
    } else if (parseInt(selectedGlobal) !== 32) {
      setProduction(PRODUCTION);
      setLocalMeters(METERS);
      setSubs(SUBSTATIONS);

      checkBoxes.setTransactionRange("all");
    }
    deserializedData();
    setAreas([AREA1, AREA2]);
    // setProduction(PRODUCTION);
    // setLocalMeters(METERS);

    slider.setSlideMenu(false);
  };

  useEffect(() => {
    loadedSubs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkBoxes.transactionRange]);

  if (loadError) return "Error loading maps";
  if (!isLoaded) return <div>Loading...</div>;
  // console.log(subs);
  return (
    <div>
      <GoogleMap
        zoom={10}
        center={center}
        mapContainerClassName='map-container'
        onClick={onMapClick}
      >
        {areas.map((area) => (
          <Polyline
            path={area}
            options={{
              geodesic: true,
              strokeColor: "red",
              strokeOpacity: 1.0,
              strokeWeight: 2,
            }}
          />
        ))}
        {checkBoxes.metersBox
          ? localMeters.map((meter) => (
              <Marker
                key={meter.FacilityID}
                position={{
                  lat: meter.Latitude,
                  lng: meter.Longitude,
                }}
                onClick={() => {
                  setSelected(meter);
                  deserializedData();
                }}
                icon={{
                  url: "http://maps.google.com/mapfiles/ms/icons/red-dot.png",
                }}
              />
            ))
          : null}
        {checkBoxes.substationBox
          ? subs.map((sub) => (
              <Marker
                key={sub.StationID}
                position={{ lat: sub.Latitude, lng: sub.Longitude }}
                onClick={() => {
                  setSelected(sub);
                }}
                icon={{
                  url: "http://maps.google.com/mapfiles/ms/icons/blue-dot.png",
                }}
              />
            ))
          : null}
        {checkBoxes.productionBox
          ? production.map((producer) => (
              <Marker
                key={producer.StationID}
                position={{ lat: producer.Latitude, lng: producer.Longitude }}
                onClick={() => {
                  setSelected(producer);
                }}
                icon={{
                  url: "http://maps.google.com/mapfiles/ms/icons/green-dot.png",
                }}
              />
            ))
          : null}
        {selected ? (
          selected.FacilityType === "GEN" ? (
            <InfoWindow
              id={selected.FacilityID}
              position={{ lat: selected.Latitude, lng: selected.Longitude }}
              onCloseClick={() => {
                setSelected(null);
              }}
            >
              <div>
                <h3>Facility ID: {selected.FacilityID}</h3>
                <h3>Facility Name: {selected.FacilityName}</h3>
                <h3>Latitude: {selected.Latitude}</h3>
                <h3>Longitude: {selected.Longitude}</h3>
                <h3>Capacity: {selected.Capacity}</h3>
              </div>
            </InfoWindow>
          ) : selected.FacilityType === "SUB" ? (
            <InfoWindow
              id={selected.FacilityID}
              position={{ lat: selected.Latitude, lng: selected.Longitude }}
              onCloseClick={() => {
                setSelected(null);
              }}
            >
              <div>
                <h3>Facility ID: {selected.FacilityID}</h3>
                <h3>Facility Name: {selected.FacilityName}</h3>
                <h3>Latitude: {selected.Latitude}</h3>
                <h3>Longitude: {selected.Longitude}</h3>
                <h3>Transactions: {selected.Transactions}</h3>
              </div>
            </InfoWindow>
          ) : selected.FacilityType === "MET" ? (
            <InfoWindow
              id={selected.FacilityID}
              position={{ lat: selected.Latitude, lng: selected.Longitude }}
              onCloseClick={() => {
                setSelected(null);
                // deserializedData();
              }}
            >
              {parseInt(selectedGlobal) === 32 ? (
                <div>
                  <h3>MeterID: {selected.FacilityID}</h3>
                  <h3>
                    BlockNumber: {meterData[meterData.length - 1].BlockNumber}
                  </h3>
                  <h3>
                    BlockTime:{" "}
                    {meterData[meterData.length - 1].Payload.BlockCreateTime}
                  </h3>
                  <h3>Latitude: {selected.Latitude}</h3>
                  <h3>Longitude: {selected.Longitude}</h3>
                  <h3>Transactions: {meterData.length}</h3>
                </div>
              ) : (
                <div>
                  <h3>MeterID: {selected.FacilityID}</h3>
                  <h3>BlockNumber: {meterData[0].BlockNumber}</h3>
                  <h3>BlockTime: {meterData[0].Payload.BlockCreateTime}</h3>
                  <h3>Latitude: {selected.Latitude}</h3>
                  <h3>Longitude: {selected.Longitude}</h3>
                  <h3>Transactions: {meters.tipData.length * 5}</h3>
                </div>
              )}
            </InfoWindow>
          ) : null
        ) : null}
      </GoogleMap>
    </div>
  );
}

export default DisplayMap;
