import { Navigate } from "react-router-dom";
import { useLogin } from "../contexts/Login.contexts";

export const RequireAuth = ({ children }) => {
  const auth = useLogin();

  if (auth.user === undefined) {
    return <Navigate to='/login' />;
  }
  return children;
};
