import { useQuery } from "react-query";
import { PAGE_INFO } from "../data/pageInfo.data";

const fetchGlobalDeserializedData = async (blockSelected) => {
  await fetch(`${PAGE_INFO.dataPath}/global/${blockSelected.selectedBlock.BlockNumber}`);
  const globalDeserialized = await fetch(`${PAGE_INFO.dataPath}/global/deserialized`);
  return globalDeserialized.json();
};

export const useFetchGlobalDeserialized = (blockSelected, globalDeserializedSuccess) => {
  return useQuery(["global-deserialized", blockSelected], () => fetchGlobalDeserializedData(blockSelected), {
    enabled: false,
    onSuccess: globalDeserializedSuccess,
  });
};
