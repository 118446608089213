import { createContext, useState } from "react";

// import { GlobalsContext } from "./Globals.context";

// import { PAGE_INFO } from "../data/pageInfo.data";
// import { set } from "js-cookie";

export const BridgesContext = createContext();

const BridgesContextProvider = (props) => {
  const [bridgeDeserialized, setBridgeDeserialized] = useState([]);
  const [bridges, setBridges] = useState([
    {
      isLoading: true,
      isError: null,
      isClicked: false,
      global: 0,
      bridgeData: [],
    },
  ]);

  return (
    <BridgesContext.Provider value={{ bridges, setBridges, bridgeDeserialized, setBridgeDeserialized }}>
      {props.children}
    </BridgesContext.Provider>
  );
};

export default BridgesContextProvider;
// export const useFetchBridges = () => {
//   const { bridges, setBridges } = useContext(BridgesContext);
//   const { globals } = useContext(GlobalsContext);
//   // const { glice, setGlice } = useContext(GliceContext);

//   const fetchBridgesData = async (blockNumber) => {
//     const currentLast = globals.globalData[0].BlockNumber;
//     await fetch(`${PAGE_INFO.dataPath}/global/${blockNumber}`, {
//       method: "GET",
//       mode: "cors",
//       credentials: "same-origin",
//     });
//     const resBridgesData = await fetch(`${PAGE_INFO.bridgePageUp}`, {
//       method: "GET",
//       mode: "cors",
//       credentials: "same-origin",
//     });
//     const bridgesData = await resBridgesData.json();
//     try {
//       if (bridgesData !== "{Error: BNF}" && bridges.find((item) => item.global === blockNumber) === undefined) {
//         setBridges([
//           ...bridges,
//           {
//             isLoading: false,
//             isError: null,
//             isClicked: false,
//             global: blockNumber,
//             bridgeData: bridgesData.reverse(),
//           },
//         ]);
//       } else {
//         setBridges([
//           ...bridges,
//           {
//             isLoading: false,
//             isError: false,
//             isClicked: false,
//             global: blockNumber,
//             bridgeData: [],
//           },
//         ]);
//       }
//     } catch (error) {
//       console.log(error);
//       setBridges({
//         isLoading: false,
//         isError: error,
//         isClicked: false,
//         global: 0,
//         bridgeData: [],
//       });
//     }
//     const resetGlobal = await fetch(`${PAGE_INFO.dataPath}/global/${currentLast}`, {
//       method: "GET",
//       mode: "cors",
//       credentials: "same-origin",
//     });
//     console.log("find solution", resetGlobal);
//   };
//   return {
//     bridges: bridges,
//     getBridges: fetchBridgesData,
//     setBridges: setBridges,
//   };
// };
