import { useContext, useState } from "react";

// import "./PrimaryContentView.page.scss";
// import "./Slider.styles.scss";
// import "../../pages/syncListView_1/SyncListView_1.scss";

// **************************
// ***** IMPORT CONTEXT *****
// **************************
// import { useGetactivePrimary } from "../../contexts/activePrimary.context";
// import { SyncListContext } from "../../contexts/SyncList.context";

// *****************************
// ***** IMPORT COMPONENTS *****
// *****************************
// import PeerLedgerBCView from "../../pages/peerLedgerBCView/PeerLedgerBCView.component";
// import PeerLedgerDBView from "../../pages/peerLedgerDBView/PeerLedgerDBView.component";
// import SecondaryContentView from "../secondaryContentView/SecondaryContentView.page";
// import SmallCard from "../smallCard/SmallCard.component";
// import SyncListCreditsChart from "../charts/syncList/SyncListCreditsChart";
// import SyncListTrustChart from "../charts/syncList/SyncListTrustChart";
// import SyncListHoldChart from "../charts/syncList/SyncListHoldChart";
// import { toggleMenu } from "../../workers/ToggleMenu.worker";
// import { SelectedBlockContext } from "../../contexts/SelectedBlock.context";
import { PrimaryContext } from "../../contexts/Primary.context";
import { GlobalsContext } from "../../contexts/globals.context";
import { PAGE_INFO } from "../../data/pageInfo.data";
import { useGetSelectedBlock } from "../../contexts/SelectedBlock.context";
// import PeerLedgerBar from "../charts/peerLedger/PeerLedgerBar";

const PrimaryContentView = ({ slider }) => {
  // const { activePrimary } = useContext(activePrimaryContext);
  const { selectedGlobal } = useContext(GlobalsContext);
  const [blockDeserialized, setBlockDeserialized] = useState([]);
  const { primaryList } = useContext(PrimaryContext);
  // console.log(selectedGlobal);
  console.log(primaryList);
  const { selectedBlock } = useGetSelectedBlock();

  const prim = slider.slideData2;
  const activePrimary = prim.item;

  const fetchDeserialized = async (blocktype, blockNumber) => {
    let fetchBridge = selectedBlock;
    let peer = slider.slideData2.item.PeerSID;
    console.log("the block type and number  is ", blocktype, blockNumber);
    console.log(fetchBridge);

    await fetch(`${PAGE_INFO.dataPath}/global/${selectedGlobal}`);
    await fetch(`${PAGE_INFO.dataPath}/bridge/${fetchBridge.bridge}`);

    if (fetchBridge.bridge === undefined) {
      await fetch(`${PAGE_INFO.dataPath}/bridge/${fetchBridge.BlockNumber}`);
    }

    await fetch(`${PAGE_INFO.dataPath}/psl`);
    await fetch(`${PAGE_INFO.dataPath}/peers/${peer}`);

    let getDeserialized = await fetch(`${PAGE_INFO.dataPath}/${blocktype}/${blockNumber}`);
    getDeserialized = await fetch(`${PAGE_INFO.dataPath}/${blocktype}/deserialized`);

    console.log("getDeserialized : ", getDeserialized);
    const deserializedBlock = await getDeserialized.json();
    setBlockDeserialized(deserializedBlock);
  };

  const handleDeserialize = (block) => {
    if (block.BlockType === 1) {
      fetchDeserialized("content", block.BlockNumber);
    } else if (block.BlockType === 2 || block.BlockType === 3) {
      fetchDeserialized("primary", block.BlockNumber);
    }
  };

  return (
    <div className='content-view'>
      <div className='card-row'>
        <div className='card'>
          <h3>{PAGE_INFO.peerLedgerBCView.BlockType}</h3>
          <h3>{activePrimary.BlockType}</h3>
        </div>
        <div className='card '>
          <h3>{PAGE_INFO.peerLedgerBCView.BlockTime}</h3>
          <h3>{new Date(activePrimary.BlockCreateTime * 1000).toLocaleTimeString()}</h3>
        </div>
        <div className='card'>
          <h3>{PAGE_INFO.peerLedgerBCView.BlockNumber}</h3>
          <h3>{activePrimary.BlockNumber}</h3>
        </div>
      </div>
      <div className='card-row'>
        <div className='card card-45'>
          <h3>{PAGE_INFO.peerLedgerBCView.PreviousHash}</h3>
          <h4>{activePrimary.HashPrevious !== undefined ? atob(activePrimary.HashPrevious) : "No Data"}</h4>
        </div>
        <div className='card card-45'>
          <h3>{PAGE_INFO.peerLedgerBCView.BlockHash}</h3>
          <h4>{activePrimary.Hash !== undefined ? atob(activePrimary.Hash) : "No Data"}</h4>
        </div>
      </div>
      <div className='text-row'>
        <div className='text-left'>
          <div className='text-label'>
            <h2>Serialized Data</h2>
          </div>
          <div className='text-area'>
            <p className='text-data text-wrap'>
              {activePrimary.BlockContent !== undefined ? activePrimary.BlockContent : "No Data"}
            </p>
          </div>
        </div>
        <div className='text-middle'>
          <button
            className='component-button'
            onClick={() => [console.log(activePrimary), handleDeserialize(activePrimary)]}
          >
            Deserialize
          </button>
          <button
            className='component-button'
            // onClick={() => {
            //   const data = blockDeserialized.Payload;
            //   prompt(JSON.stringify(data));
            // }}
          >
            Verify
          </button>
          {/* <button className='component-button' onClick={()=>getactivePrimary(activePrimary.BlockNumber)}>tests</button> */}

          <span>
            <button className='component-button search'>&#x3c;</button>
            <button className='component-button search'>&#x3e;</button>
          </span>
        </div>
        <div className='text-right'>
          <div className='text-label'>
            <h2>Deserialized Data</h2>
          </div>
          <div className='text-area'>
            {/* {activePrimary.BlockNumber === blockDeserialized.BlockNumber  */}
            {true ? (
              <p>
                {blockDeserialized !== undefined
                  ? JSON.stringify(blockDeserialized)
                  : console.log("!!!!the blockDeserialized variable is undefined")}
              </p>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrimaryContentView;
