import * as RiIcons from "react-icons/ri";
import { useLocation } from "react-router-dom";

// *************************
// ***** IMPORT STYLES *****
// *************************
import "./Slider.styles.scss";
import "../../pages/syncListView_1/SyncListView_1.scss";

// **************************
// ***** IMPORT CONTEXT *****
// **************************
import { useGetSelectedBlock } from "../../contexts/SelectedBlock.context";
// import { SyncListContext } from "../../contexts/SyncList.context";
// import PeerButton from "../peerButton/peerButton.conponent";
// *****************************
// ***** IMPORT COMPONENTS *****
// *****************************
import PeerLedgerBCView from "../../pages/peerLedgerBCView/PeerLedgerBCView.component";
// import PeerLedgerDBView from "../../pages/peerLedgerDBView/PeerLedgerDBView.component";
import SecondaryContentView from "../../pages/secondaryContentView/SecondaryContentView.page";
// import PrimaryContentView from "../../pages/primaryContentView/PrimaryContentView.page";
import SmallCard from "../smallCard/SmallCard.component";
import SyncListCreditsChart from "../charts/syncList/SyncListCreditsChart";
import SyncListTrustChart from "../charts/syncList/SyncListTrustChart";
import SyncListHoldChart from "../charts/syncList/SyncListHoldChart";
// import { toggleMenu } from "../../workers/ToggleMenu.worker";
import { PAGE_INFO } from "../../data/pageInfo.data";
import PeerButton from "../peerButton/peerButton.conponent";
import PrimaryContentView from "../../pages/primaryContentView/PrimaryContentView.page";
import ErrorPage from "../ErrorPage/404Error.component";

const SliderContainer = ({ slider, handleMenu }) => {
  // const { syncLists } = useContext(SyncListContext);
  const { selectedBlock } = useGetSelectedBlock();
  let location = useLocation();

  if (
    selectedBlock === -1 &&
    location.pathname !== "/" &&
    location.pathname !== "/submitTransactionContract" &&
    location.pathname !== "/login"
  ) {
    return <ErrorPage />;
  }

  return (
    <div className={`slide-container ${!slider.slideMenu ? "hide" : slider.slideState}`}>
      <div className='slide-container--inner-container'>
        <div className='button-row'>
          <div className='button-row-arrow'>
            <button className='menu-item menu-item-arrow'>
              <RiIcons.RiArrowUpFill
                id='show-content'
                onClick={(evt) => {
                  location.pathname === "/syncListView"
                    ? handleMenu("show-content-sync", slider)
                    : handleMenu(evt.target.id, slider);
                }}
              />
            </button>
            <button className='menu-item menu-item-arrow'>
              <RiIcons.RiArrowDownFill
                id='slide-close'
                onClick={(evt) => {
                  handleMenu(evt.currentTarget.id, slider);
                }}
              />
            </button>
            {/* <button className={`menu-item`}>Global</button> */}
          </div>
          <button
            id='show-content'
            className={`menu-item ${
              selectedBlock.BlockType !== 9 || location.pathname === "/peerLedgerDashboard" ? "inactive" : null
            }`}
            onClick={(evt) => {
              // console.log("global slider", slider);
              if (selectedBlock.BlockType === 9) {
                handleMenu(evt.target.id, slider);
              }
            }}
          >
            Global
          </button>
          <button
            id='show-content'
            className={`menu-item ${
              (selectedBlock.BlockType !== 5 && selectedBlock.BlockType !== 6 && selectedBlock.BlockType !== 7) ||
              location.pathname === "/peerLedgerDashboard"
                ? "inactive"
                : null
            }`}
            onClick={(evt) => {
              // console.log("global slider", slider);
              if (selectedBlock.BlockType === 5 || selectedBlock.BlockType === 6 || selectedBlock.BlockType === 7) {
                handleMenu(evt.target.id, slider);
              }
            }}
          >
            Bridge
          </button>

          <button
            id='show-content-sync'
            className={`menu-item ${location.pathname !== "/syncListView" ? "inactive" : null}`}
            onClick={(evt) => {
              console.log("global slider", slider);
              if (location.pathname === "/syncListView") {
                handleMenu(evt.target.id, slider);
              }
            }}
          >
            Sync
          </button>

          {/* <button
            id='show-content-peer'
            className={`menu-item`}
            onClick={(evt) => {
              if (location.pathname == "/peerLedgerDashboard") {
                handleMenu(evt.target.id, slider);
              }
            }}
          >
            Peer
          </button> */}
        </div>

        {slider.slideState === "partial" ? (
          <div className='content-container'>
            <div className='slide-activity-container'>
              <h2 className='slide-activity-label'>Blockchain Live View {`(${new Date().toLocaleDateString()})`}</h2>
              {/* <div  className='slide-data'  style={{visibility: ( location.pathname == '/syncListView' ) ? 'visible' : 'hidden' }} > */}

              {location.pathname === "/syncListView" ? (
                <div>
                  <div className='cards-row-slider'>
                    <SmallCard
                      className='card card-45 slider-card'
                      title={PAGE_INFO.syncListView.BlocksLast24}
                      content='257'
                    />
                    <SmallCard
                      className='card card-45 slider-card'
                      title={PAGE_INFO.syncListView.GlobalHoldMargin}
                      content='0%'
                    />
                  </div>
                  <div className='cards-row-slider'>
                    <SmallCard className='card card-45 slider-card' title='Transactions' content='347' />
                    <SmallCard
                      className='card card-45 slider-card'
                      title={PAGE_INFO.syncListView.CumlativeTrust}
                      content='0 '
                    />
                  </div>
                  <div className='cards-row-slider'>
                    <SmallCard
                      className='card card-45 slider-card'
                      title={PAGE_INFO.syncListView.NumberOfLedgers}
                      content='63'
                    />
                    <SmallCard
                      className='card card-45 slider-card'
                      title={PAGE_INFO.syncListView.TrustSTDeviation}
                      content='1.87%'
                    />
                  </div>
                  <div className='cards-row-slider'>
                    <SmallCard
                      className='card card-45 slider-card'
                      title={PAGE_INFO.syncListView.LicenseType}
                      content='Utility'
                    />
                  </div>
                  <div className='charts-row-slider'>
                    <div className='slider-chart'>
                      <SyncListCreditsChart />
                    </div>
                    <div className='slider-chart'>
                      <SyncListTrustChart />
                    </div>
                    <div className='slider-chart'>
                      <SyncListHoldChart />
                    </div>
                    <h2>Block Distribution</h2>
                    <div className='dist-banner'>
                      <p>{`Sync List Leader: ${2}`}</p>
                      <p>{`Registered Name: Global Solar, Inc`}</p>
                      <p>{`License Type: 234`}</p>
                      <p>{`Date SL Created: DT Stamp`}</p>
                      <p className='p-center'>Peer Ledgers</p>

                      <div className='buttons-row'>
                        <PeerButton />
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div>None</div>
              )}
            </div>
          </div>
        ) : slider.slideData === "primary" ? (
          <PrimaryContentView slider={slider} />
        ) : slider.slideData === "show-content-sync" ? (
          <SecondaryContentView slider={slider} />
        ) : (
          <PeerLedgerBCView />
        )}
      </div>{" "}
    </div>
  );
};

export default SliderContainer;
