export const PRODUCTION = [
  {
    FacilityID: 470,
    FacilityType: "GEN",
    FacilityName: "Comanche Generating Station",
    PrimaryFuel: "Coal",
    Capacity: "1635.3MW",
    Latitude: 38.208056,
    Longitude: -104.574722,
    Technology: "Conventional Steam Coal",
    UtilityID: 15466,
    EnergyType: "COL",
  },
  {
    FacilityID: 8219,
    FacilityType: "GEN",
    FacilityName: "Ray D Nixon Power Plant",
    PrimaryFuel: "Coal",
    Capacity: "282.6MW",
    Latitude: 38.633333,
    Longitude: -104.705833,
    Technology: "Conventional Steam Coal",
    UtilityID: 3989,
    EnergyType: "COL",
  },
  {
    FacilityID: 6021,
    FacilityType: "GEN",
    FacilityName: "Craig Generating Station",
    PrimaryFuel: "Coal",
    Capacity: "1427.6MW",
    Latitude: 40.462778,
    Longitude: -107.591111,
    Technology: "Conventional Steam Coal",
    UtilityID: 30151,
    EnergyType: "COL",
  },
  {
    FacilityID: 525,
    FacilityType: "GEN",
    FacilityName: "Hayden Generating Station",
    PrimaryFuel: "Coal",
    Capacity: "465.4MW",
    Latitude: 40.485556,
    Longitude: -107.185,
    Technology: "Conventional Steam Coal",
    UtilityID: 15466,
    EnergyType: "COL",
  },
  {
    FacilityID: 6248,
    FacilityType: "GEN",
    FacilityName: "Pawnee Generating Station ",
    PrimaryFuel: "Coal",
    Capacity: "552.3MW",
    Latitude: 40.221667,
    Longitude: -103.680278,
    Technology: "Conventional Steam Coal",
    UtilityID: 15466,
    EnergyType: "COL",
  },
  {
    FacilityID: 520,
    FacilityType: "GEN",
    FacilityName: "Lower Molina Power Plant",
    PrimaryFuel: "Hydro",
    Capacity: "5.6MW",
    Latitude: 39.195556,
    Longitude: -108.048889,
    Technology: "Conventional Hydroelectric",
    UtilityID: 2518,
    EnergyType: "HYD",
  },
  {
    FacilityID: 54729,
    FacilityType: "GEN",
    FacilityName: "Taylor Draw Hydroelectric Facility",
    PrimaryFuel: "Hydro",
    Capacity: "2.3MW",
    Latitude: 40.106389,
    Longitude: -108.711111,
    Technology: "Conventional Hydroelectric",
    UtilityID: 16070,
    EnergyType: "HYD",
  },
  {
    FacilityID: 64123,
    FacilityType: "GEN",
    FacilityName: "James W Broderick Hydro Plant",
    PrimaryFuel: "Hydro",
    Capacity: "7.5MW",
    Latitude: 38.255,
    Longitude: -104.731944,
    Technology: "Conventional Hydroelectric",
    UtilityID: 63751,
    EnergyType: "HYD",
  },
  {
    FacilityID: 58622,
    FacilityType: "GEN",
    FacilityName: "Carter Hydroelectric Project",
    PrimaryFuel: "Hydro",
    Capacity: "2.6MW",
    Latitude: 40.324167,
    Longitude: -105.208889,
    Technology: "Conventional Hydroelectric",
    UtilityID: 58577,
    EnergyType: "HYD",
  },
  {
    FacilityID: 517,
    FacilityType: "GEN",
    FacilityName: "Marys Lake Power Plant",
    PrimaryFuel: "Hydro",
    Capacity: "8.1MW",
    Latitude: 40.344167,
    Longitude: -105.534444,
    Technology: "Conventional Hydroelectric",
    UtilityID: 2518,
    EnergyType: "HYD",
  },
  {
    FacilityID: 60619,
    FacilityType: "GEN",
    FacilityName: "Rush Creek Wind Project",
    PrimaryFuel: "Wind",
    Capacity: "600MW",
    Latitude: 39.172222,
    Longitude: -103.845278,
    Technology: "Onshore Wind Turbine",
    UtilityID: 15466,
    EnergyType: "WND",
  },
  {
    FacilityID: 59083,
    FacilityType: "GEN",
    FacilityName: "Limon Wind Energy Center",
    PrimaryFuel: "Wind",
    Capacity: "200.6MW",
    Latitude: 39.380833,
    Longitude: -103.573056,
    Technology: "Onshore Wind Turbine",
    UtilityID: 57503,
    EnergyType: "WND",
  },
  {
    FacilityID: 56460,
    FacilityType: "GEN",
    FacilityName: "Twin Buttes Wind Project",
    PrimaryFuel: "Wind",
    Capacity: "75MW",
    Latitude: 37.673611,
    Longitude: -102.862222,
    Technology: "Onshore Wind Turbine",
    UtilityID: 15399,
    EnergyType: "WND",
  },
  {
    FacilityID: 60143,
    FacilityType: "GEN",
    FacilityName: "Peak View Wind Farm",
    PrimaryFuel: "Wind",
    Capacity: "60.8MW",
    Latitude: 37.710556,
    Longitude: -104.505556,
    Technology: "Onshore Wind Turbine",
    UtilityID: 56146,
    EnergyType: "WND",
  },
  {
    FacilityID: 58548,
    FacilityType: "GEN",
    FacilityName: "Huerfano River Wind",
    PrimaryFuel: "Wind",
    Capacity: "8MW",
    Latitude: 37.774444,
    Longitude: -104.831944,
    Technology: "Onshore Wind Turbine",
    UtilityID: 58520,
    EnergyType: "WND",
  },
  {
    FacilityID: 54630,
    FacilityType: "GEN",
    FacilityName: "American Gypsum Cogeneration",
    PrimaryFuel: "Natural Gas",
    Capacity: "9.6MW",
    Latitude: 39.6209,
    Longitude: -106.6884,
    Technology: "Natural Gas Fired Combustion Turbine; Petroleum Liquids;",
    UtilityID: 54839,
    EnergyType: "NAG",
  },
  {
    FacilityID: 469,
    FacilityType: "GEN",
    FacilityName: "Cherokee Generating Station",
    PrimaryFuel: "Natural Gas",
    Capacity: "1006.4MW",
    Latitude: 39.807222,
    Longitude: -104.964444,
    Technology: "Natural Gas",
    UtilityID: 15466,
    EnergyType: "NAG",
  },
  {
    FacilityID: 10682,
    FacilityType: "GEN",
    FacilityName: "Brush Generating Station",
    PrimaryFuel: "Natural Gas",
    Capacity: "297.2MW",
    Latitude: 40.241389,
    Longitude: -103.631111,
    Technology: "Natural Gas",
    UtilityID: 55307,
    EnergyType: "NAG",
  },
  {
    FacilityID: 55200,
    FacilityType: "GEN",
    FacilityName: "Arapahoe Combustion Turbine",
    PrimaryFuel: "Natural Gas",
    Capacity: "194MW",
    Latitude: 39.6692,
    Longitude: -105.0018,
    Technology: "Natural Gas",
    UtilityID: 58517,
    EnergyType: "NAG",
  },
  {
    FacilityID: 10755,
    FacilityType: "GEN",
    FacilityName: "Rifle Generating Station",
    PrimaryFuel: "Natural Gas",
    Capacity: "84MW",
    Latitude: 39.517222,
    Longitude: -107.73,
    Technology: "Natural Gas",
    UtilityID: 30151,
    EnergyType: "NAG",
  },
  {
    FacilityID: 477,
    FacilityType: "GEN",
    FacilityName: "Valmont Generating Station",
    PrimaryFuel: "Natural Gas",
    Capacity: "59.3MW",
    Latitude: 40.019444,
    Longitude: -105.201944,
    Technology: "Natural Gas",
    UtilityID: 15466,
    EnergyType: "NAG",
  },
  {
    FacilityID: 57509,
    FacilityType: "GEN",
    FacilityName: "Fort Carson Army Base Solar Farm",
    PrimaryFuel: "Solar",
    Capacity: "1.5Mw",
    Latitude: 38.6875,
    Longitude: -104.781944,
    Technology: "Photovoltaic",
    UtilityID: 56830,
    EnergyType: "SLR",
  },
  {
    FacilityID: 60008,
    FacilityType: "GEN",
    FacilityName: "Hooper Solar PV Power Plant",
    PrimaryFuel: "Solar",
    Capacity: "52MW",
    Latitude: 37.692222,
    Longitude: -105.981667,
    Technology: "Photovoltaic",
    UtilityID: 59744,
    EnergyType: "SLR",
  },
  {
    FacilityID: 60247,
    FacilityType: "GEN",
    FacilityName: "Valley View Solar Farm",
    PrimaryFuel: "Solar",
    Capacity: "4MW",
    Latitude: 40.418889,
    Longitude: -104.891944,
    Technology: "Photovoltaic",
    UtilityID: 60036,
    EnergyType: "SLR",
  },
  {
    FacilityID: 57554,
    FacilityType: "GEN",
    FacilityName: "Air Force Academy Solar Farm",
    PrimaryFuel: "Solar",
    Capacity: "5.5MW",
    Latitude: 38.956667,
    Longitude: -104.806389,
    Technology: "Photovoltaic",
    UtilityID: 56892,
    EnergyType: "SLR",
  },
  {
    FacilityID: 61462,
    FacilityType: "GEN",
    FacilityName: "SR Platte Solar Farm",
    PrimaryFuel: "Solar",
    Capacity: "16MW",
    Latitude: 40.181667,
    Longitude: -104.691944,
    Technology: "Photovoltaic",
    UtilityID: 61081,
    EnergyType: "SLR",
  },
];
export const METERS = [
  {
    FacilityID: "M0001",
    FacilityType: "MET",
    Latitude: 40.0806,
    Longitude: -104.835,
  },
  {
    FacilityID: "M0002",
    FacilityType: "MET",
    Latitude: 40.0727,
    Longitude: -105.117,
  },
  {
    FacilityID: "M0003",
    FacilityType: "MET",
    Latitude: 39.8481,
    Longitude: -105.65,
  },
];
