import { useState, useContext } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import { RiArrowDownFill, RiArrowUpFill } from "react-icons/ri";

// *************************
// ***** IMPORT STYLES *****
// *************************
import "./App.scss";
import "./components/slider/Slider.styles.scss";

// *********************************
// ***** IMPORT CONTEXT / DATA *****
// **************************
import { GlobalsContext } from "./contexts/globals.context";
import { SelectedBlockContext } from "./contexts/SelectedBlock.context";
import { LoginContext } from "./contexts/Login.contexts";
// ************************
// ***** IMPORT HOOKS *****
// ************************
import { useFetchLast } from "./hooks/useFetchLast.hook";
import { useFetchGlobals } from "./hooks/useFetchGlobals.hook";
import { RequireAuth } from "./workers/RequireAuth.worker";

// ************************
// ***** IMPORT PAGES *****
// ************************
import GlobalParameterView from "./pages/globalParametersView_1/GlobalParametersView.page";
import GlobalConsensusView1 from "./pages/globalConsensusView_1/GlobalConsensusView_1.component";
import BridgeConsensusView from "./pages/bridgeConsensusView_1/BridgeConsensusView_1.component";
import PeerLedgerBCView from "./pages/peerLedgerBCView/PeerLedgerBCView.component";
import PeerLedgerDBView from "./pages/peerLedgerDBView/PeerLedgerDBView.component";
import SyncListView from "./pages/syncListView_1/SyncListView_1";
import SubmitContract from "./pages/submitContract/SubmitContract.component";
import LoginPage from "./pages/login/loginView.page";

// *****************************
// ***** IMPORT COMPONENTS *****
// *****************************
import Header from "./components/header/Header.component";
import Navigation from "./components/sideNav/Navigation.component";
import Footer from "./components/footer/Footer.component";
import Slider from "./components/slider/Slider.component";
import ErrorPage from "./components/ErrorPage/404Error.component";
import { toggleMenu } from "./workers/ToggleMenu.worker";
// ****************************
// ***** IMPORT TEMP DATA *****
// ****************************
import PARAMETERS from "./data/parameters.data";
import CONSENSUS_DATA from "./data/consensus.data";
import TREEDATA from "./data/treedata.data";
import Cookies from "js-cookie";

function App() {
  // ***** Temp Data Holders ****
  const [parameters] = useState(PARAMETERS);
  const [consensusData] = useState(CONSENSUS_DATA);
  const [treeData] = useState(TREEDATA);
  const { setBlockLast, globals, setGlobals } = useContext(GlobalsContext);
  const [slideData, setSlideData] = useState({ name: "", blockNumber: [] });
  const [slideMenu, setSlideMenu] = useState(false);
  const [slideState, setSlideState] = useState("");
  const [slideData2, setSlideData2] = useState({});
  const { user } = useContext(LoginContext);

  const { selectedBlock } = useContext(SelectedBlockContext);
  let location = useLocation();

  const lastSuccess = (lastData) => {
    setBlockLast(lastData);
  };

  const globalsSuccess = (globalData) => {
    setGlobals({
      isLoading: false,
      isError: false,
      globalData: globalData,
    });
  };

  const { status: lastStatus } = useFetchLast(lastSuccess);
  const { status: globalStatus } = useFetchGlobals(globalsSuccess);

  const slider = {
    slideMenu: slideMenu,
    setSlideMenu: setSlideMenu,
    slideState: slideState,
    setSlideState: setSlideState,
    slideData: slideData,
    setSlideData: setSlideData,
    slideData2: slideData2,
    setSlideData2: setSlideData2,
  };
  const jsCookie = Cookies.get("sesDashboardPhilos");
  Cookies.set("mySession", jsCookie, { path: "/" });

  const handleMenu = (evt, slider) => {
    toggleMenu(evt, slider);
  };

  // ******************************
  // ***** CHECK DATA LOADING *****
  // ******************************

  if (lastStatus === "loading") {
    return (
      <div className='app-container'>
        <div className='grid-container main-content'>
          <h1>Data is loading...</h1>
        </div>
      </div>
    );
  }

  if (globalStatus === "loading") {
    return (
      <div className='app-container'>
        <div className='grid-container main-content'>
          <h1>Data is loading...</h1>
        </div>
      </div>
    );
  }

  if (globalStatus === "error") {
    return (
      <div className='app-container'>
        <div className='grid-container main-content'>
          <h1>Data is Error...</h1>
        </div>
      </div>
    );
  }
  // if (blockLast.isLoading && globals.isLoading) {
  //   return (
  //     <div>
  //       <h1>Data is loading</h1>
  //     </div>
  //   );
  // }

  // ***********************
  // ***** DATA LOADED *****
  // ***********************
  return (
    <div className='app-container'>
      {/* ***** DISPLAY HEADER ***** */}
      <div className='grid-container header'>
        <Header slider={slider} />
      </div>
      {/* ***** NAVIGATION SIDE MENU ***** */}
      <div className='grid-container side-container hide-scrollbar'>
        <Navigation slider={slider} />
      </div>
      <div className='grid-container main-content'>
        <div className={`slider-button-container ${slideMenu ? "hidden" : null}`}>
          <div className='button-row'>
            <div className='button-row-arrow'>
              <button className='menu-item menu-item-arrow slider-button'>
                <RiArrowUpFill
                  id='show-content'
                  onClick={(evt) => {
                    // console.log("app open", slider);
                    setSlideMenu(true);
                    setSlideState("partial");
                    handleMenu(evt.target.id, slider);
                  }}
                />
              </button>
              <button className={`menu-item menu-item-arrow slider-button ${!slideMenu ? "inactive" : null} `}>
                <RiArrowDownFill className={``} />
              </button>
            </div>
            <button
              id='show-content'
              className={`menu-item slider-button ${
                selectedBlock.BlockType !== 9 || location.pathname === "/peerLedgerDashboard" ? "inactive" : null
              }`}
              onClick={(evt) => {
                if (selectedBlock.BlockType === 9) {
                  // console.log("app global", slider);
                  setSlideMenu(true);
                  setSlideState("show");
                  handleMenu(evt.target.id, slider);
                }
              }}
            >
              Global
            </button>
            <button
              id='show-content'
              className={`menu-item ${
                (selectedBlock.BlockType !== 5 && selectedBlock.BlockType !== 6 && selectedBlock.BlockType !== 7) ||
                location.pathname === "/peerLedgerDashboard"
                  ? "inactive"
                  : null
              }`}
              onClick={(evt) => {
                if (selectedBlock.BlockType === 5 || selectedBlock.BlockType === 6 || selectedBlock.BlockType === 7) {
                  // console.log("app global", slider);
                  setSlideMenu(true);
                  setSlideState("show");
                  handleMenu(evt.target.id, slider);
                }
              }}
            >
              Bridge
            </button>

            <button
              id='show-content-sync'
              className={`menu-item ${location.pathname !== "/syncListView" ? "inactive" : null}`}
              onClick={(evt) => {
                // console.log("global slider", slider);
                if (location.pathname === "/syncListView") {
                  handleMenu(evt.target.id, slider);
                }
              }}
            >
              Sync
            </button>
          </div>
        </div>
        <Slider slider={slider} handleMenu={handleMenu} />
        <Routes>
          <Route
            path='/'
            element={
              <RequireAuth>
                <GlobalParameterView data={parameters} />
              </RequireAuth>
            }
          />
          <Route
            path='/GlobalConsensusView_1'
            element={
              <RequireAuth>
                <GlobalConsensusView1 treeData={treeData} data={consensusData} globalData={globals} />
              </RequireAuth>
            }
          />
          <Route
            path='/BridgeConsensusView'
            element={
              <RequireAuth>
                <BridgeConsensusView treeData={treeData} />
              </RequireAuth>
            }
          />
          <Route
            path='/syncListView'
            element={
              <RequireAuth>
                <SyncListView slider={slider} />
              </RequireAuth>
            }
          />
          <Route
            path='/peerLedgerDashboard'
            element={
              <RequireAuth>
                <PeerLedgerDBView slider={slider} />
              </RequireAuth>
            }
          />
          <Route
            path='/peerLedgerBlockContent'
            element={
              <RequireAuth>
                <PeerLedgerBCView />
              </RequireAuth>
            }
          />
          <Route
            path='/submitTransactionContract'
            element={
              <RequireAuth>
                <SubmitContract />
              </RequireAuth>
            }
          />
          <Route path='/login' element={<LoginPage />} />
          <Route path='*' element={<ErrorPage />} />
        </Routes>
      </div>
      <div className='grid-container footer'>
        <Footer />
      </div>
    </div>
  );
}

export default App;
