import { createContext, useState } from "react";

export const ContentContext = createContext();

const ContentContextProvider = (props) => {
  const [contentList, setContentList] = useState([
    {
      isLoading: true,
      isError: false,
      isClicked: false,
      bridge: 0,
      contentData: [],
    },
  ]);

  return <ContentContext.Provider value={{ contentList, setContentList }}>{props.children}</ContentContext.Provider>;
};

export default ContentContextProvider;
