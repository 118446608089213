import { useQuery } from "react-query";
import { PAGE_INFO } from "../data/pageInfo.data";

const fetchGlobals = async () => {
  const resGlobals = await fetch(`${PAGE_INFO.dataPath}/global/pageDown`);
  return resGlobals.json();
};

export const useFetchGlobals = (globalsSuccess) => {
  return useQuery(["fetch-globals"], () => fetchGlobals(), { onSuccess: globalsSuccess });
};
