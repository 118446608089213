import { useContext } from "react";
import { NavLink, useLocation } from "react-router-dom";

import { useLogin } from "../../contexts/Login.contexts";
import { GlobalsContext } from "../../contexts/globals.context";
import logo from "./Blockframe_600_px_Trans_Back.PNG";

import "./Header.styles.scss";

const Header = ({ slider }) => {
  const { selectedGlobal } = useContext(GlobalsContext);
  const location = useLocation();
  let title = "";

  const auth = useLogin();

  if (location.pathname === "/") {
    title = "Configuration Parameters";
  } else if (location.pathname === "/globalConsensusView_1") {
    title = "Global Consensus View";
  } else if (location.pathname === "/BridgeConsensusView") {
    title = "Bridge Consensus View";
  } else if (location.pathname === "/syncListView" && slider.slideMenu === true) {
    title = "Sync List View";
  } else if (location.pathname === "/syncListView" && slider.slideMenu === false && parseInt(selectedGlobal) === 32) {
    title = "Live Map View";
  } else if (location.pathname === "/syncListView" && slider.slideMenu === false) {
    title = "Map View";
  } else if (location.pathname === "/peerLedgerDashboard") {
    title = "Peer Ledger View";
  } else if (location.pathname === "/submitTransactionContract") {
    title = "Contract Submission";
  }
  console.log(auth.user === undefined);
  return (
    <div className='header'>
      <NavLink className='logo-container' to='/'>
        <h3 className='logo'>
          Philos<sup>TM</sup> Dashboard
        </h3>
      </NavLink>
      <div className='title'>
        <h5>{title}</h5>
      </div>
      <div className='options'>
        {!auth.user && (
          <NavLink className='option' to='/login'>
            LOGIN
          </NavLink>
        )}
        {auth.user && (
          <NavLink className='option' to='/login' onClick={auth.logout}>
            LOGOUT
          </NavLink>
        )}
        <a href='https://blockframetech.com' target='_blank'>
          <img className='logo-image' src={logo} />
        </a>
      </div>
    </div>
  );
};

export default Header;
