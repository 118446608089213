import { useQuery } from "react-query";
import { PAGE_INFO } from "../data/pageInfo.data";

const fetchBlockLast = async () => {
  const resBlockLast = await fetch(`${PAGE_INFO.dataPath}/last`);
  return resBlockLast.json();
};

export const useFetchLast = (lastSuccess) => {
  return useQuery(["get-last"], () => fetchBlockLast(), {
    onSuccess: lastSuccess,
  });
};

// const fetchBridgeData = async (globalNumber) => {
//   await fetch(`${PAGE_INFO.dataPath}/global/${globalNumber}`);
//   const resBridgeData = await fetch(`${PAGE_INFO.bridgePageUp}`);
//   return resBridgeData.json();
// };

// export const useGetBridges = (globalNumber, bridgeSuccess) => {
//   return useQuery(["get-bridges", globalNumber], () => fetchBridgeData(globalNumber), {
//     enabled: false,
//     onSuccess: bridgeSuccess,
//   });
// };
