const GLOBAL_STATS = [
  {
    BlockNumber: 32,
    Division: 0,
    BlockType: 9,
    UnixTime: 1631203228,
    PreviousHash:
      "QzdEQzc3QkY1OEZFQjgyN0Q3RUJEOTg3MURGMDYyNEM3RDY5NTM1NkZCRTJFMDhFNzFCRkY4NEVEMEU0NUMyRDU0QkM3QjdFNTExNUYyQ0Q0NzFENjM5MUFENkZBQzY2OTE5QTA2NjlBMjREODgwMkM0MzhDMjZGOTkxOUZCNDk=",
    Hash: "ODIxNUJEQTc2RTYyM0U5QzhDRjA3MkExQjU0NTJGQjVGNDQ5NzRCQjQ2QkQzRjJGMDFFRDc1RDUwNUFBQUZCOTM3NTBDQUMzMDExRDUwMjdCRTgzNkM0MkJGQjVBOENERDIyQTVCRkMxMTE2NzVFQUEzRTQ0NUU2QjBGN0I1NzI=",
    BlockContent:
      "MDkwMDAwMDAwMTAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMTAwMDAwMDAwMDAwMDAwMUUwMDAwMDAwMDAwMDAwMDlDMkYzQTYxMDAwMDAwMDBDN0RDNzdCRjU4RkVCODI3RDdFQkQ5ODcxREYwNjI0QzdENjk1MzU2RkJFMkUwOEU3MUJGRjg0RUQwRTQ1QzJENTRCQzdCN0U1MTE1RjJDRDQ3MUQ2MzkxQUQ2RkFDNjY5MTlBMDY2OUEyNEQ4ODAyQzQzOEMyNkY5OTE5RkI0OTkxMDAwMDAwMDAwMDAwMDAzQkY3QUFCNzc3NTFGNjU2NEU3M0EzN0JBNTZDRUNCQ0ZFRjExODUzQkVEMzA4OEIzMkU4RjYyMEM1QjI4RkY1NDQzRDE3RTE1Q0REMkQ3RUJBQzZBQjU3QkRBMUE1NEM5RDJDREY5MUM2QUY0MjE2RDFFNzA2REI2RjBDOTQwMjg4MDAwMDAwMDAwMDAwMDA1ODAyMDAwMDAwMDAwMDAwODA3MDAwMDAwMDAwMDAwMDgwNTEwMTAwMDAwMDAwMDAwMzAwMDAwMDAwMDAwMDAwMDEwMDAwMDAwMDAwMDAwMDgwNTEwMTAwMDAwMDAwMDAwMTAwMDAwMDAwMDAwMDAwODA1MTAxMDAwMDAwMDAwMDAzMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAxMDAwMDAwMDAwMDAwMDAwMTAwMDAwMDAwMDAwMDAwMDEwMDAwMDAwMDAwMDAwMDNCNkEyN0JDQ0VCNkE0MkQ2MkEzQThEMDJBNkYwRDczNjUzMjE1NzcxREUyNDNBNjNBQzA0OEExOEI1OURBMj		k0QTFBMjc2MzI4QkNEMzBCODE0OTUwQjQ4NjgxQzA4RUNGRTIyQThDMkU5OTdBRUJFNjg0QkJDRjgyMzE1MjlCNzQxRjFDMjY2MEY0OEZBMDVBRjAxNTNCREVBQTQ4RDNBQjE5NzgwMjY5Q0E5MzcyMkZFNEVGRDMxRTA0NEE0NTAxMDAwMDAwMDAwMDAwMDBCRDFEQzc1MTQxMjhDQTVGQTMyNDM5RkQyMUM3MkFBMTg3RTM1QUUzNEYwQTRDRkU1NjMwOEM5NUU4MjZFNEUwOEZGNjZGMDZBMTNDQ0YxNjhGQjE2QkM2MTM2QjJBMzI4MkVDNEI4RjA5RkY0MzU3MjlDMDIwMDAzNUVBQjkwQw==",
    MinimumBridgeLedgers: 3,
    GlobalPhyliBal: 2530865,
    GlobalPhyliHold: 844420,
    PhyliAdded: 26678,
    PhyliHoldRelease: 17565,
    TotalStamps: 2417565,
    StampCreated: 87654,
    BlocksAdded: 45784,
    PeersUnderConsensus: 28,
    TimeAccessed: 0,
  },
  {
    BlockNumber: 31,
    Division: 0,
    BlockType: 9,
    UnixTime: 1631203228,
    PreviousHash:
      "QzdEQzc3QkY1OEZFQjgyN0Q3RUJEOTg3MURGMDYyNEM3RDY5NTM1NkZCRTJFMDhFNzFCRkY4NEVEMEU0NUMyRDU0QkM3QjdFNTExNUYyQ0Q0NzFENjM5MUFENkZBQzY2OTE5QTA2NjlBMjREODgwMkM0MzhDMjZGOTkxOUZCNDk=",
    Hash: "ODIxNUJEQTc2RTYyM0U5QzhDRjA3MkExQjU0NTJGQjVGNDQ5NzRCQjQ2QkQzRjJGMDFFRDc1RDUwNUFBQUZCOTM3NTBDQUMzMDExRDUwMjdCRTgzNkM0MkJGQjVBOENERDIyQTVCRkMxMTE2NzVFQUEzRTQ0NUU2QjBGN0I1NzI=",
    BlockContent:
      "MDkwMDAwMDAwMTAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMTAwMDAwMDAwMDAwMDAwMUUwMDAwMDAwMDAwMDAwMDlDMkYzQTYxMDAwMDAwMDBDN0RDNzdCRjU4RkVCODI3RDdFQkQ5ODcxREYwNjI0QzdENjk1MzU2RkJFMkUwOEU3MUJGRjg0RUQwRTQ1QzJENTRCQzdCN0U1MTE1RjJDRDQ3MUQ2MzkxQUQ2RkFDNjY5MTlBMDY2OUEyNEQ4ODAyQzQzOEMyNkY5OTE5RkI0OTkxMDAwMDAwMDAwMDAwMDAzQkY3QUFCNzc3NTFGNjU2NEU3M0EzN0JBNTZDRUNCQ0ZFRjExODUzQkVEMzA4OEIzMkU4RjYyMEM1QjI4RkY1NDQzRDE3RTE1Q0REMkQ3RUJBQzZBQjU3QkRBMUE1NEM5RDJDREY5MUM2QUY0MjE2RDFFNzA2REI2RjBDOTQwMjg4MDAwMDAwMDAwMDAwMDA1ODAyMDAwMDAwMDAwMDAwODA3MDAwMDAwMDAwMDAwMDgwNTEwMTAwMDAwMDAwMDAwMzAwMDAwMDAwMDAwMDAwMDEwMDAwMDAwMDAwMDAwMDgwNTEwMTAwMDAwMDAwMDAwMTAwMDAwMDAwMDAwMDAwODA1MTAxMDAwMDAwMDAwMDAzMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAxMDAwMDAwMDAwMDAwMDAwMTAwMDAwMDAwMDAwMDAwMDEwMDAwMDAwMDAwMDAwMDNCNkEyN0JDQ0VCNkE0MkQ2MkEzQThEMDJBNkYwRDczNjUzMjE1NzcxREUyNDNBNjNBQzA0OEExOEI1OURBMj		k0QTFBMjc2MzI4QkNEMzBCODE0OTUwQjQ4NjgxQzA4RUNGRTIyQThDMkU5OTdBRUJFNjg0QkJDRjgyMzE1MjlCNzQxRjFDMjY2MEY0OEZBMDVBRjAxNTNCREVBQTQ4RDNBQjE5NzgwMjY5Q0E5MzcyMkZFNEVGRDMxRTA0NEE0NTAxMDAwMDAwMDAwMDAwMDBCRDFEQzc1MTQxMjhDQTVGQTMyNDM5RkQyMUM3MkFBMTg3RTM1QUUzNEYwQTRDRkU1NjMwOEM5NUU4MjZFNEUwOEZGNjZGMDZBMTNDQ0YxNjhGQjE2QkM2MTM2QjJBMzI4MkVDNEI4RjA5RkY0MzU3MjlDMDIwMDAzNUVBQjkwQw==",
    MinimumBridgeLedgers: 3,
    GlobalPhyliBal: 2521765,
    GlobalPhyliHold: 857820,
    PhyliAdded: 26878,
    PhyliHoldRelease: 17465,
    TotalStamps: 2416565,
    StampCreated: 87654,
    BlocksAdded: 55684,
    PeersUnderConsensus: 28,
    TimeAccessed: 0,
  },
  {
    BlockNumber: 30,
    Division: 0,
    BlockType: 9,
    UnixTime: 1631203228,
    PreviousHash:
      "QzdEQzc3QkY1OEZFQjgyN0Q3RUJEOTg3MURGMDYyNEM3RDY5NTM1NkZCRTJFMDhFNzFCRkY4NEVEMEU0NUMyRDU0QkM3QjdFNTExNUYyQ0Q0NzFENjM5MUFENkZBQzY2OTE5QTA2NjlBMjREODgwMkM0MzhDMjZGOTkxOUZCNDk=",
    Hash: "ODIxNUJEQTc2RTYyM0U5QzhDRjA3MkExQjU0NTJGQjVGNDQ5NzRCQjQ2QkQzRjJGMDFFRDc1RDUwNUFBQUZCOTM3NTBDQUMzMDExRDUwMjdCRTgzNkM0MkJGQjVBOENERDIyQTVCRkMxMTE2NzVFQUEzRTQ0NUU2QjBGN0I1NzI=",
    BlockContent:
      "MDkwMDAwMDAwMTAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMTAwMDAwMDAwMDAwMDAwMUUwMDAwMDAwMDAwMDAwMDlDMkYzQTYxMDAwMDAwMDBDN0RDNzdCRjU4RkVCODI3RDdFQkQ5ODcxREYwNjI0QzdENjk1MzU2RkJFMkUwOEU3MUJGRjg0RUQwRTQ1QzJENTRCQzdCN0U1MTE1RjJDRDQ3MUQ2MzkxQUQ2RkFDNjY5MTlBMDY2OUEyNEQ4ODAyQzQzOEMyNkY5OTE5RkI0OTkxMDAwMDAwMDAwMDAwMDAzQkY3QUFCNzc3NTFGNjU2NEU3M0EzN0JBNTZDRUNCQ0ZFRjExODUzQkVEMzA4OEIzMkU4RjYyMEM1QjI4RkY1NDQzRDE3RTE1Q0REMkQ3RUJBQzZBQjU3QkRBMUE1NEM5RDJDREY5MUM2QUY0MjE2RDFFNzA2REI2RjBDOTQwMjg4MDAwMDAwMDAwMDAwMDA1ODAyMDAwMDAwMDAwMDAwODA3MDAwMDAwMDAwMDAwMDgwNTEwMTAwMDAwMDAwMDAwMzAwMDAwMDAwMDAwMDAwMDEwMDAwMDAwMDAwMDAwMDgwNTEwMTAwMDAwMDAwMDAwMTAwMDAwMDAwMDAwMDAwODA1MTAxMDAwMDAwMDAwMDAzMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAxMDAwMDAwMDAwMDAwMDAwMTAwMDAwMDAwMDAwMDAwMDEwMDAwMDAwMDAwMDAwMDNCNkEyN0JDQ0VCNkE0MkQ2MkEzQThEMDJBNkYwRDczNjUzMjE1NzcxREUyNDNBNjNBQzA0OEExOEI1OURBMj		k0QTFBMjc2MzI4QkNEMzBCODE0OTUwQjQ4NjgxQzA4RUNGRTIyQThDMkU5OTdBRUJFNjg0QkJDRjgyMzE1MjlCNzQxRjFDMjY2MEY0OEZBMDVBRjAxNTNCREVBQTQ4RDNBQjE5NzgwMjY5Q0E5MzcyMkZFNEVGRDMxRTA0NEE0NTAxMDAwMDAwMDAwMDAwMDBCRDFEQzc1MTQxMjhDQTVGQTMyNDM5RkQyMUM3MkFBMTg3RTM1QUUzNEYwQTRDRkU1NjMwOEM5NUU4MjZFNEUwOEZGNjZGMDZBMTNDQ0YxNjhGQjE2QkM2MTM2QjJBMzI4MkVDNEI4RjA5RkY0MzU3MjlDMDIwMDAzNUVBQjkwQw==",
    MinimumBridgeLedgers: 3,
    GlobalPhyliBal: 2520865,
    GlobalPhyliHold: 854420,
    PhyliAdded: 26578,
    PhyliHoldRelease: 17865,
    TotalStamps: 2416565,
    StampCreated: 87654,
    BlocksAdded: 65784,
    PeersUnderConsensus: 28,
    TimeAccessed: 0,
  },
  {
    BlockNumber: 29,
    Division: 0,
    BlockType: 9,
    UnixTime: 1631116827,
    PreviousHash:
      "NjU4RDMzQUZFMkVEQTcyODNDODg2RjE3OTU3MDhDQTVBMzJGQTY0QzVFMkQ5NDhFQjFDNDYzRDAyRjMxODVBRDczRTUzQ0Y2QzE4MkRCRTZBMzczRUFFOUU5MzcyNTlGQTRFMjYwMTMyOTk5OEFBQjU4QkVERTNBMURCM0I2RDc=",
    Hash: "QzdEQzc3QkY1OEZFQjgyN0Q3RUJEOTg3MURGMDYyNEM3RDY5NTM1NkZCRTJFMDhFNzFCRkY4NEVEMEU0NUMyRDU0QkM3QjdFNTExNUYyQ0Q0NzFENjM5MUFENkZBQzY2OTE5QTA2NjlBMjREODgwMkM0MzhDMjZGOTkxOUZCNDk=",
    BlockContent:
      "MDkwMDAwMDAwMTAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMTAwMDAwMDAwMDAwMDAwMUQwMDAwMDAwMDAwMDAwMDFCREUzODYxMDAwMDAwMDA2NThEMzNBRkUyRURBNzI4M0M4ODZGMTc5NTcwOENBNUEzMkZBNjRDNUUyRDk0OEVCMUM0NjNEMDJGMzE4NUFENzNFNTNDRjZDMTgyREJFNkEzNzNFQUU5RTkzNzI1OUZBNEUyNjAxMzI5OTk4QUFCNThCRURFM0ExREIzQjZENzhDMDAwMDAwMDAwMDAwMDAwOEM4RTBCOUVDMTFBQzQ4NUVCOTBDMTA3NkEyQTJGRjM4MUU0REJGODJBQjYxMDFEQkIwRjFCRTEyNkE4MDM0REY0RUFBOTA1NDYzRjVCMTQwMTgyNTI3NjZCMzgyNEI1MDE2RjNCNzM1MERDQzE3OTI4NTM3NUYyQzY2NDQ3Rjg4MDAwMDAwMDAwMDAwMDA1ODAyMDAwMDAwMDAwMDAwODA3MDAwMDAwMDAwMDAwMDgwNTEwMTAwMDAwMDAwMDAwMzAwMDAwMDAwMDAwMDAwMDEwMDAwMDAwMDAwMDAwMDgwNTEwMTAwMDAwMDAwMDAwMTAwMDAwMDAwMDAwMDAwODA1MTAxMDAwMDAwMDAwMDAzMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAxMDAwMDAwMDAwMDAwMDAwMTAwMDAwMDAwMDAwMDAwMDEwMDAwMDAwMDAwMDAwMDNCNkEyN0JDQ0VCNkE0MkQ2MkEzQThEMDJBNkYwRDczNjUzMjE1NzcxREUyNDNBNjNBQzA0OEExOEI1OUR		BMjk3MzFDOUE4RjRFRTgzNDk4MEFGMDdGQzdERDRCQTE3NEM4NzQ4MkRCMjcyNTg3Njk3M0M3MzVCQkVDNzY2MUE2NDU1QjQyMURFQjQwQjhBOUJDRDBBOTA5Mzc5RjJBMkFCNTUwNzM5RUE3Q0UxQjczNjhDQUE2NTk3RDlGNTQ3MTAxMDAwMDAwMDAwMDAwMDAzNjMzM0U0ODQ0OUNGNzY1MEFDQ0I5REYzODEzODBDN0JBODZBOERCMTc0OUI5NTUzNUFBOTRCNDAyMDQ2MzBFMEUyNzJDM0FEQTNGMTMyMUMyNzg5QkNDMDhDOUJBOUFERTE1QzRGQ0ZDQzU5QTI1OTc0MUExOTExM0M4NDIwNg==",
    MinimumBridgeLedgers: 3,
    GlobalPhyliBal: 2785455,
    GlobalPhyliHold: 874830,
    PhyliAdded: 28478,
    PhyliHoldRelease: 10395,
    TotalStamps: 2498765,
    StampCreated: 88374,
    BlocksAdded: 67364,
    PeersUnderConsensus: 26,
    TimeAccessed: 0,
  },
  {
    BlockNumber: 28,
    Division: 0,
    BlockType: 9,
    UnixTime: 1631030426,
    PreviousHash:
      "NEQ0Rjg1RTdFMjQ0QkZEMERDNzE3QTYyMEU5RTY0N0ZFOTBCNDY3NTYyQzI4QTBDOTQzREUyODdEQjlGN0JBQTYzNTJGQUZCRDRBNkY3QzAzMEFDNjAzQ0RCNUVBRUM4OTEwNUU4MERFNjI1NTQ1Nzc3QzlGOUE4RUNCRTFCOTI=",
    Hash: "NjU4RDMzQUZFMkVEQTcyODNDODg2RjE3OTU3MDhDQTVBMzJGQTY0QzVFMkQ5NDhFQjFDNDYzRDAyRjMxODVBRDczRTUzQ0Y2QzE4MkRCRTZBMzczRUFFOUU5MzcyNTlGQTRFMjYwMTMyOTk5OEFBQjU4QkVERTNBMURCM0I2RDc=",
    BlockContent:
      "MDkwMDAwMDAwMTAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMTAwMDAwMDAwMDAwMDAwMUMwMDAwMDAwMDAwMDAwMDlBOEMzNzYxMDAwMDAwMDA0RDRGODVFN0UyNDRCRkQwREM3MTdBNjIwRTlFNjQ3RkU5MEI0Njc1NjJDMjhBMEM5NDNERTI4N0RCOUY3QkFBNjM1MkZBRkJENEE2RjdDMDMwQUM2MDNDREI1RUFFQzg5MTA1RTgwREU2MjU1NDU3NzdDOUY5QThFQ0JFMUI5Mjg3MDAwMDAwMDAwMDAwMDAwMDc3NzY2RUM4QTdCODRERDlCMjAxMjJEOUQ2RUVERDJEQTRFRTRDQzMzMDZDNzJFRTIyQ0JBMTc4QTcxODZGNzlBNEU5MjExODcyQzc3NkVDRjJGMTRDQzRFOTI5MzFEMTA0NTlBNjk0ODk0NDVEOEZEQTczODNCN0NBMTA1MDg4MDAwMDAwMDAwMDAwMDA1ODAyMDAwMDAwMDAwMDAwODA3MDAwMDAwMDAwMDAwMDgwNTEwMTAwMDAwMDAwMDAwMzAwMDAwMDAwMDAwMDAwMDEwMDAwMDAwMDAwMDAwMDgwNTEwMTAwMDAwMDAwMDAwMTAwMDAwMDAwMDAwMDAwODA1MTAxMDAwMDAwMDAwMDAzMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAxMDAwMDAwMDAwMDAwMDAwMTAwMDAwMDAwMDAwMDAwMDEwMDAwMDAwMDAwMDAwMDNCNkEyN0JDQ0VCNkE0MkQ2MkEzQThEMDJBNkYwRDczNjUzMjE1NzcxREUyNDNBNjNBQzA0OEExOEI1OUR		BMjk4QUFCMTM0N0E0NUZBMzAzQzZEOTdGREVEM0QzQUJDN0Y3NDk4ODRGRDc4QzUyMTZENjg4NEFEMDA2RTM1MEYwRkU4OEY5QzQ4NjgyODE2NEE2RDhENDY3NUM1MjkwQkNENUQwMjlFQzE3Q0I2QTlCODE5M0IxNDcxRjhENjE2QzAxMDAwMDAwMDAwMDAwMDBGQUIxRUQyNkZFMUM3MjkyMkQwNzNFNkJBOTBBM0NBNzA0NUM4RjRBRDYzNjg5OTlGQkQ0ODJBQ0MyNzkyQ0Y4NkQ4REI3NTI0NkZBMTg3Q0JCNDU1QjNGQTBCODFDMTVDMUFBNzg2MkJDOTc2MEUwNUU2NzE3QkMwMDdBQTIwMw==",
    MinimumBridgeLedgers: 3,
    GlobalPhyliBal: 2592875,
    GlobalPhyliHold: 892820,
    PhyliAdded: 18778,
    PhyliHoldRelease: 16725,
    TotalStamps: 2416876,
    StampCreated: 87254,
    BlocksAdded: 64784,
    PeersUnderConsensus: 27,
    TimeAccessed: 0,
  },
  {
    BlockNumber: 27,
    Division: 0,
    BlockType: 9,
    UnixTime: 1630944025,
    PreviousHash:
      "QjZGMTdGNTkxMDkzQ0M3Q0JBODM1MzUzQjAzREJFQjU0QzVFODBBNEU5RDUxN0RDREQ1QzNFNERGNTM5RTA1MDlGQUU1MEQ3QUZCRURCNDhEOENERUVEM0Y4NThDNDc1N0UxNUM2MkIwRDM5MjkzMTlDOTk2NDEzNTk2OUU0RUM=",
    Hash: "NEQ0Rjg1RTdFMjQ0QkZEMERDNzE3QTYyMEU5RTY0N0ZFOTBCNDY3NTYyQzI4QTBDOTQzREUyODdEQjlGN0JBQTYzNTJGQUZCRDRBNkY3QzAzMEFDNjAzQ0RCNUVBRUM4OTEwNUU4MERFNjI1NTQ1Nzc3QzlGOUE4RUNCRTFCOTI=",
    BlockContent:
      "MDkwMDAwMDAwMTAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMTAwMDAwMDAwMDAwMDAwMUIwMDAwMDAwMDAwMDAwMDE5M0IzNjYxMDAwMDAwMDBCNkYxN0Y1OTEwOTNDQzdDQkE4MzUzNTNCMDNEQkVCNTRDNUU4MEE0RTlENTE3RENERDVDM0U0REY1MzlFMDUwOUZBRTUwRDdBRkJFREI0OEQ4Q0RFRUQzRjg1OEM0NzU3RTE1QzYyQjBEMzkyOTMxOUM5OTY0MTM1OTY5RTRFQzgyMDAwMDAwMDAwMDAwMDA1MzY0QjBCOEZFQjdGOTY5NjY5NUQ2RTAyNzIwQkE1NENGMUYyQzE3Q0JCMTNEMDUwODFCRUFCMkNDRDk1QTI3MTY1NkZCMTMwRDI1MUZFOUVBODg3MzAxRTQ2RjExNEMzMjBDMzVGMkQyMDZEMTNFMTFCMURBRkY2RTYxNDY5RTg4MDAwMDAwMDAwMDAwMDA1ODAyMDAwMDAwMDAwMDAwODA3MDAwMDAwMDAwMDAwMDgwNTEwMTAwMDAwMDAwMDAwMzAwMDAwMDAwMDAwMDAwMDEwMDAwMDAwMDAwMDAwMDgwNTEwMTAwMDAwMDAwMDAwMTAwMDAwMDAwMDAwMDAwODA1MTAxMDAwMDAwMDAwMDAzMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAxMDAwMDAwMDAwMDAwMDAwMTAwMDAwMDAwMDAwMDAwMDEwMDAwMDAwMDAwMDAwMDNCNkEyN0JDQ0VCNkE0MkQ2MkEzQThEMDJBNkYwRDczNjUzMjE1NzcxREUyNDNBNjNBQzA0OEExOEI1OUR		BMjlDRUMxNUI2NjdENzYxNzMwRDBBREU2QTJGOEM1QTIzMzU5NTVBREJGRjhBQURENUM3NDEwQ0QzOTAxOUI3NTVFRENBRkM3RTdDOEI2MkNEOUEyQzY5NUQ2MTVEQzRBNTFBNERENTI3M0JDNjIzQTI3MkZDNjFBRTREMTMyNTQ0MjAxMDAwMDAwMDAwMDAwMDBFMzQwOTRCMTUzQjZDMTlBMDdDNkUyQzBCNjAzNzlGQjdFQzU1MzVDRDExOEM0OEYzQTBCQUE0OUZCNUVFQkY3MDVFMzAyNzk3MTQ5MUQ2QjU4OTNCNkVERThDOUI4RTQ0RjEyNkYwRENEMURDOERBMEU0NEQ1ODkxOTQwMTQwMw==",
    MinimumBridgeLedgers: 3,
    GlobalPhyliBal: 2582736,
    GlobalPhyliHold: 858270,
    PhyliAdded: 19878,
    PhyliHoldRelease: 16535,
    TotalStamps: 2413875,
    StampCreated: 86354,
    BlocksAdded: 63874,
    PeersUnderConsensus: 28,
    TimeAccessed: 0,
  },
  {
    BlockNumber: 26,
    Division: 0,
    BlockType: 9,
    UnixTime: 1630857624,
    PreviousHash:
      "M0UwMjA2QjI4RTVEMjM3OUNBRDI2RTAzMjExQTRBMEE5M0YyOTMxQUI5NDIzNkNBNzFGOTQxOTlFNzEwMUVGQTFFNThENEM1OTY2RkQxM0Y2RjI1MzIwQUM1RjlDN0JFMDkwM0E5QTlGNkUzMzM2QkU2QzI4MTY2MjFFM0E3NkM=",
    Hash: "QjZGMTdGNTkxMDkzQ0M3Q0JBODM1MzUzQjAzREJFQjU0QzVFODBBNEU5RDUxN0RDREQ1QzNFNERGNTM5RTA1MDlGQUU1MEQ3QUZCRURCNDhEOENERUVEM0Y4NThDNDc1N0UxNUM2MkIwRDM5MjkzMTlDOTk2NDEzNTk2OUU0RUM=",
    BlockContent:
      "MDkwMDAwMDAwMTAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMTAwMDAwMDAwMDAwMDAwMUEwMDAwMDAwMDAwMDAwMDk4RTkzNDYxMDAwMDAwMDAzRTAyMDZCMjhFNUQyMzc5Q0FEMjZFMDMyMTFBNEEwQTkzRjI5MzFBQjk0MjM2Q0E3MUY5NDE5OUU3MTAxRUZBMUU1OEQ0QzU5NjZGRDEzRjZGMjUzMjBBQzVGOUM3QkUwOTAzQTlBOUY2RTMzMzZCRTZDMjgxNjYyMUUzQTc2QzdEMDAwMDAwMDAwMDAwMDAxMjBFQ0U1NDk0OTk0QUE1N0Y3MzI3QzdFM0NBN0VGNkJDRUM5MjhEOTJEMDgxRDJCOTFBQTQ2QTIzOUMzM0Q2NkExOTkyM0VERTBGQjM2RTA3MEQzMDEwRjU1RjRFRkIzRDY3QjdGQUY3NEM5REY3QjdCRkVBNEFEODZDODYwRDg4MDAwMDAwMDAwMDAwMDA1ODAyMDAwMDAwMDAwMDAwODA3MDAwMDAwMDAwMDAwMDgwNTEwMTAwMDAwMDAwMDAwMzAwMDAwMDAwMDAwMDAwMDEwMDAwMDAwMDAwMDAwMDgwNTEwMTAwMDAwMDAwMDAwMTAwMDAwMDAwMDAwMDAwODA1MTAxMDAwMDAwMDAwMDAzMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAxMDAwMDAwMDAwMDAwMDAwMTAwMDAwMDAwMDAwMDAwMDEwMDAwMDAwMDAwMDAwMDNCNkEyN0JDQ0VCNkE0MkQ2MkEzQThEMDJBNkYwRDczNjUzMjE1NzcxREUyNDNBNjNBQzA0OEExOEI1OUR			BMjkxNUFGMEQwNDRDRUQ1MzlCQ0I3OUVBODA3NDNFODY5RDhEMUFCOUI5MURBRDNFRDZEOTAzQzg5MUUyNkYzNjhDNjEwMjQzODAwODZDRTFGODBCRDdEMjVBQzFGNTNBNkY4QkMwMTJFQUNCOEZGMDQ2N0M5MkE3NTVFOEVFQjY1QzAxMDAwMDAwMDAwMDAwMDBGNjNGQ0FCRTQ2QjFCOENFOUMxNDQwQzZGNERCQ0RCNjEyNUE4QzE5RkQzQjRERUU4NEU2RkZEQjU1QUExRUI5MEZFNjgyMTE1QzJBMDNBNzE3OEFCNDVGNzU2RDA2OTIxMDNCNzgzQ0IxMDE3MDdGMkE3MkFEMDE4RUU4MUQwQw==",
    MinimumBridgeLedgers: 3,
    GlobalPhyliBal: 2528376,
    GlobalPhyliHold: 882920,
    PhyliAdded: 29288,
    PhyliHoldRelease: 27665,
    TotalStamps: 2438276,
    StampCreated: 78654,
    BlocksAdded: 68274,
    PeersUnderConsensus: 22,
    TimeAccessed: 0,
  },
  {
    BlockNumber: 25,
    Division: 0,
    BlockType: 9,
    UnixTime: 1630742423,
    PreviousHash:
      "OEExNjhGQTIyRUU3MkY5OUI2MTRFODIwRUQ5Njg5NDNGNjgzNjE1RjEwOTI3RTFGMTU1NDFBMDVGRUJGMThFNTA0MkM5NUQ0NTkzRTQ3OUY3QzVCNkRGNDY1N0RFMTg0NjJEQ0Q1NTI3QTM4MkY1MjNCRjUwNUYzRTRFNkE1NDQ=",
    Hash: "M0UwMjA2QjI4RTVEMjM3OUNBRDI2RTAzMjExQTRBMEE5M0YyOTMxQUI5NDIzNkNBNzFGOTQxOTlFNzEwMUVGQTFFNThENEM1OTY2RkQxM0Y2RjI1MzIwQUM1RjlDN0JFMDkwM0E5QTlGNkUzMzM2QkU2QzI4MTY2MjFFM0E3NkM=",
    BlockContent:
      "MDkwMDAwMDAwMTAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMTAwMDAwMDAwMDAwMDAwMTkwMDAwMDAwMDAwMDAwMDk3MjczMzYxMDAwMDAwMDA4QTE2OEZBMjJFRTcyRjk5QjYxNEU4MjBFRDk2ODk0M0Y2ODM2MTVGMTA5MjdFMUYxNTU0MUEwNUZFQkYxOEU1MDQyQzk1RDQ1OTNFNDc5RjdDNUI2REY0NjU3REUxODQ2MkRDRDU1MjdBMzgyRjUyM0JGNTA1RjNFNEU2QTU0NDc3MDAwMDAwMDAwMDAwMDA2N0Y1MzM2NUQyODhCNDAzNThBQkVEM0NDQjQwOEE2MjY4M0Q2MkUwMUVDNTIzQTYwRjZFNEUzOEREM0VEQTY1NUE0MkIxNjg5MUVBRTM4QkEwNDI2MjIwRjFBMDhENkYzQUVGNUE3MkJGQUU0NkU5ODlCNENCM0Y2OTQ3MjVCNzg4MDAwMDAwMDAwMDAwMDA1ODAyMDAwMDAwMDAwMDAwODA3MDAwMDAwMDAwMDAwMDgwNTEwMTAwMDAwMDAwMDAwMzAwMDAwMDAwMDAwMDAwMDEwMDAwMDAwMDAwMDAwMDgwNTEwMTAwMDAwMDAwMDAwMTAwMDAwMDAwMDAwMDAwODA1MTAxMDAwMDAwMDAwMDAzMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAxMDAwMDAwMDAwMDAwMDAwMTAwMDAwMDAwMDAwMDAwMDEwMDAwMDAwMDAwMDAwMDNCNkEyN0JDQ0VCNkE0MkQ2MkEzQThEMDJBNkYwRDczNjUzMjE1NzcxREUyNDNBNjNBQzA0OEExOEI1OUR		BMjlDQkIxRURBOTcxOUU5MEJBN0QxNzhFMzVCNEFGQjhGQkMxMTUwRTkzNjFDMzdFNzQ0MEI2OTREMjI1RTk0QTNEQzNFMjgzQjdDMUZCRTBBRjgwMzBCOEZEQ0JDNkRDQjFDNjFGQkExRUJFREZGMDY4ODNENTYzQzA1MjU1QkFBRDAxMDAwMDAwMDAwMDAwMDAzQzM3M0E0NjQ5REU5RUFGQUMyNUVCNTY0RkIyRDU2N0ZCNkY1NTU4NDQ5NEFDQzBCRThBQzJEOEU3NjJBNTQ5REQ4NEFEQzRBMDYwRTQxMzI4RjI2RkEzNzkyNjZDQjVEQjE1MkVFNEI3MzhGOUM2RDk3RTM5M0E3Mzg5MzcwQw==",
    MinimumBridgeLedgers: 3,
    GlobalPhyliBal: 2582765,
    GlobalPhyliHold: 858760,
    PhyliAdded: 25678,
    PhyliHoldRelease: 22865,
    TotalStamps: 2276765,
    StampCreated: 87267,
    BlocksAdded: 65987,
    PeersUnderConsensus: 26,
    TimeAccessed: 0,
  },
  {
    BlockNumber: 24,
    Division: 0,
    BlockType: 9,
    UnixTime: 1630656022,
    PreviousHash:
      "MjM3NkE4NzFENzVFRDY2NjJDMjhBNUU2ODg0MEEyQjIzOUMxMTY2RTBFNzA1QUMyRDFBNUJBQUQ2MEYyQjIzNTU2NjJCNjc5N0U3QTVGOUE5MkUwM0Y1MjZBMjM5RDkzNjU3MEVENzRGQkQ1Q0ExRjk0N0I1MDdDMjEzNjc5NDY=",
    Hash: "OEExNjhGQTIyRUU3MkY5OUI2MTRFODIwRUQ5Njg5NDNGNjgzNjE1RjEwOTI3RTFGMTU1NDFBMDVGRUJGMThFNTA0MkM5NUQ0NTkzRTQ3OUY3QzVCNkRGNDY1N0RFMTg0NjJEQ0Q1NTI3QTM4MkY1MjNCRjUwNUYzRTRFNkE1NDQ=",
    BlockContent:
      "MDkwMDAwMDAwMTAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMTAwMDAwMDAwMDAwMDAwMTgwMDAwMDAwMDAwMDAwMDE2RDYzMTYxMDAwMDAwMDAyMzc2QTg3MUQ3NUVENjY2MkMyOEE1RTY4ODQwQTJCMjM5QzExNjZFMEU3MDVBQzJEMUE1QkFBRDYwRjJCMjM1NTY2MkI2Nzk3RTdBNUY5QTkyRTAzRjUyNkEyMzlEOTM2NTcwRUQ3NEZCRDVDQTFGOTQ3QjUwN0MyMTM2Nzk0NjcyMDAwMDAwMDAwMDAwMDA3QzlCRkVDNUQxRjU2M0QwQkNBNkI2MDg1NzBBOEMyMDZGODA2MzhERjhDNkI2Qzk5MThBNkFBRkVFRTVEMDlENTVBNDhCQUMwNjg5MjVCNTBBRDk3QzcwMTM0RDQ1MTIyMjUzNkFCNzkzOEYyNjA1QjY1NUFBOTEwMUY0RUQ3ODg4MDAwMDAwMDAwMDAwMDA1ODAyMDAwMDAwMDAwMDAwODA3MDAwMDAwMDAwMDAwMDgwNTEwMTAwMDAwMDAwMDAwMzAwMDAwMDAwMDAwMDAwMDEwMDAwMDAwMDAwMDAwMDgwNTEwMTAwMDAwMDAwMDAwMTAwMDAwMDAwMDAwMDAwODA1MTAxMDAwMDAwMDAwMDAzMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAxMDAwMDAwMDAwMDAwMDAwMTAwMDAwMDAwMDAwMDAwMDEwMDAwMDAwMDAwMDAwMDNCNkEyN0JDQ0VCNkE0MkQ2MkEzQThEMDJBNkYwRDczNjUzMjE1NzcxREUyNDNBNjNBQzA0OEExOEI1OUR		BMjk0MEE4OERFMThCNDM5MkE1MzQzN0E1NTNBNEU3MDE2Mjc3OTNGNzdDQzZCRjQ1ODYwQjk4QkI5NTM4MjYwMDIyMDc0QkQ4NjgyMDA0QjVDQzlDNkY5QzI0REQwQ0Q5QkRFNjhCOTc4QjEwNUQ2QUU0QzUyQzExMjQ4OUQ5NzQwNjAxMDAwMDAwMDAwMDAwMDAzRjZGRkI1Nzg1MEY0Njc3M0VERTAyNDZDNDZBQzY5NDgzMDBFMEQ5MEVGOTkyOTAwNDkyNzBFMzJFRjE0MTY3MTAzQUZGODc4REZBN0ZDNzQwNjc5RjFBMTY5MUFCMzVBQjEyNzEyRDI3OTI5NTg3QUY2M0ZDMTNDMTBCNUIwMw==",
    MinimumBridgeLedgers: 3,
    GlobalPhyliBal: 2584755,
    GlobalPhyliHold: 812320,
    PhyliAdded: 26838,
    PhyliHoldRelease: 17665,
    TotalStamps: 2423465,
    StampCreated: 78954,
    BlocksAdded: 65484,
    PeersUnderConsensus: 28,
    TimeAccessed: 0,
  },
  {
    BlockNumber: 23,
    Division: 0,
    BlockType: 9,
    UnixTime: 1630569621,
    PreviousHash:
      "ODE5NTk2ODRGNkI0MEVFMkU5MkUyNkQzRDM5MTI5QUFCRkI0MUM0OTBBRTNEQTJBRjU0MTZGNzBCODFFNkJBMjM1ODI1NzkzQjUxOUY3Nzk0NDM5QUNEQTZFOEZBMTAxMURFRTU5MTM5RjVEMkE3NDYwRjk0QzBBODkxRDcyNTU=",
    Hash: "MjM3NkE4NzFENzVFRDY2NjJDMjhBNUU2ODg0MEEyQjIzOUMxMTY2RTBFNzA1QUMyRDFBNUJBQUQ2MEYyQjIzNTU2NjJCNjc5N0U3QTVGOUE5MkUwM0Y1MjZBMjM5RDkzNjU3MEVENzRGQkQ1Q0ExRjk0N0I1MDdDMjEzNjc5NDY=",
    BlockContent:
      "MDkwMDAwMDAwMTAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMTAwMDAwMDAwMDAwMDAwMTcwMDAwMDAwMDAwMDAwMDk1ODQzMDYxMDAwMDAwMDA4MTk1OTY4NEY2QjQwRUUyRTkyRTI2RDNEMzkxMjlBQUJGQjQxQzQ5MEFFM0RBMkFGNTQxNkY3MEI4MUU2QkEyMzU4MjU3OTNCNTE5Rjc3OTQ0MzlBQ0RBNkU4RkExMDExREVFNTkxMzlGNUQyQTc0NjBGOTRDMEE4OTFENzI1NTZEMDAwMDAwMDAwMDAwMDAyNzY2RDYwQTI1NEE2MjI3NURGMzk5RTg3NjEwNEU0RkUxMjc2Rjg3OTg4RTUyRTU4RDQ5RjlEM0Y1Q0JDRDVCNTI0NkNGMUNFNzM2RTI4RTE3NTk1ODJFRTk0RDVBRjAxODYzREM0Q0IzMDg5RDNFRURFMTI0QjM1QTNFQ0UwQzg4MDAwMDAwMDAwMDAwMDA1ODAyMDAwMDAwMDAwMDAwODA3MDAwMDAwMDAwMDAwMDgwNTEwMTAwMDAwMDAwMDAwMzAwMDAwMDAwMDAwMDAwMDEwMDAwMDAwMDAwMDAwMDgwNTEwMTAwMDAwMDAwMDAwMTAwMDAwMDAwMDAwMDAwODA1MTAxMDAwMDAwMDAwMDAzMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAxMDAwMDAwMDAwMDAwMDAwMTAwMDAwMDAwMDAwMDAwMDEwMDAwMDAwMDAwMDAwMDNCNkEyN0JDQ0VCNkE0MkQ2MkEzQThEMDJBNkYwRDczNjUzMjE1NzcxREUyNDNBNjNBQzA0OEExOEI1OUR		BMjk2QTg0Q0E3RjcxQkZEQ0FFQkJCOEM3QjU1Q0NFRUE1ODZEQjAyMjIxQUZBMkZFRUU3NzgwNjFBNDFBQzZGRTRFMzQ4MkU2QjdCRjFCN0Y0QUEwMjk4QUMyQjA1NTg4MkNENDI4QzVFRDI1OUQ4NUIwMjVEMDIzOTk2RDU1NzBDQzAxMDAwMDAwMDAwMDAwMDBDQkM5NjBFNjNDQ0I4ODBEQTBFNzlFNDUyMjg1NjBGMTcyMjQxRjgwOUJGREExODZFMTE5NzExOTk4NjY5Mzc0QzBENUNEMzlFRDlCRkYxMDZFQUQ5MTYyREJCN0NEOUZFOThDMUMzRDYwNEJGODc3NkFGRUI0QTM1NTBDQzcwNw==",
    MinimumBridgeLedgers: 3,
    GlobalPhyliBal: 2987665,
    GlobalPhyliHold: 857380,
    PhyliAdded: 28658,
    PhyliHoldRelease: 22365,
    TotalStamps: 2418765,
    StampCreated: 88374,
    BlocksAdded: 67884,
    PeersUnderConsensus: 26,
    TimeAccessed: 0,
  },
  {
    BlockNumber: 22,
    Division: 0,
    BlockType: 9,
    UnixTime: 1630483220,
    PreviousHash:
      "RjFDMkRCMkZEMThGMjM5QTk3MEQ1NEI2MkYxQTY0RkZFNzU2QkFERTE1ODJFNzMxNUM3MjhFODVDMEZCNUVBOENGREUyMDUwNTEyRjAwQzA5NUZFMzQ1NUYxRERERDlCQTlFNDFEMjQ1OTA0MUZEMjUxQUVDRDQ0RDYzNTNDOTI=",
    Hash: "ODE5NTk2ODRGNkI0MEVFMkU5MkUyNkQzRDM5MTI5QUFCRkI0MUM0OTBBRTNEQTJBRjU0MTZGNzBCODFFNkJBMjM1ODI1NzkzQjUxOUY3Nzk0NDM5QUNEQTZFOEZBMTAxMURFRTU5MTM5RjVEMkE3NDYwRjk0QzBBODkxRDcyNTU=",
    BlockContent:
      "MDkwMDAwMDAwMTAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMTAwMDAwMDAwMDAwMDAwMTYwMDAwMDAwMDAwMDAwMDE0MzMyRjYxMDAwMDAwMDBGMUMyREIyRkQxOEYyMzlBOTcwRDU0QjYyRjFBNjRGRkU3NTZCQURFMTU4MkU3MzE1QzcyOEU4NUMwRkI1RUE4Q0ZERTIwNTA1MTJGMDBDMDk1RkUzNDU1RjFEREREOUJBOUU0MUQyNDU5MDQxRkQyNTFBRUNENDRENjM1M0M5MjY4MDAwMDAwMDAwMDAwMDAzNzFBNUY1QjVFRjU1MENDMjc0NTA1MzhCODE0OEZBREEyOEEwODA3QkZBRjQ4QjBGNDNGRTE0M0ZGOUMwMDA3QTgzQTA0NEZCMzZDRDNCN0JFOEM3RjA5Q0U5ODk3MTIwNERERDNENTM1MEEyRDE3MTg2NTM4REQ1Njk2MjBFRDg4MDAwMDAwMDAwMDAwMDA1ODAyMDAwMDAwMDAwMDAwODA3MDAwMDAwMDAwMDAwMDgwNTEwMTAwMDAwMDAwMDAwMzAwMDAwMDAwMDAwMDAwMDEwMDAwMDAwMDAwMDAwMDgwNTEwMTAwMDAwMDAwMDAwMTAwMDAwMDAwMDAwMDAwODA1MTAxMDAwMDAwMDAwMDAzMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAxMDAwMDAwMDAwMDAwMDAwMTAwMDAwMDAwMDAwMDAwMDEwMDAwMDAwMDAwMDAwMDNCNkEyN0JDQ0VCNkE0MkQ2MkEzQThEMDJBNkYwRDczNjUzMjE1NzcxREUyNDNBNjNBQzA0OEExOEI1OUR		BMjlCMDM2OERGQjhCNzRENjU4RkQxQUMzMzU4Q0M0RjkwQjg2QkQ2MUVFMTkwQzI1Mzg0QTNGRjVCMjkxQzFBOTAyQTM4REE2ODA2QzIxQjQxOTlEMDgzRUY2OEMzODQ3MUMwRDk0OEU5MzJDN0VGN0Q5ODI1QkIxODQ1QkRCQ0EyMjAxMDAwMDAwMDAwMDAwMDBCMTk4QkM1QTQ3N0M2MTI1MEE5OTAwMjM5ODBFM0VGQjg2NTUwRUE3OTg2NEJEQkQwREUxNDcxRTgxMUUwQTVGMDNDN0I0NzM3NDgyODMwMkIxRUI5OEVBRTQ5OEI2NDVFQzAyNEI3QTU4QkExOUM2MEI1ODM4MkMwMzE5RTMwOQ==",
    MinimumBridgeLedgers: 3,
    GlobalPhyliBal: 2736558,
    GlobalPhyliHold: 856370,
    PhyliAdded: 18738,
    PhyliHoldRelease: 17865,
    TotalStamps: 2194875,
    StampCreated: 82984,
    BlocksAdded: 67894,
    PeersUnderConsensus: 28,
    TimeAccessed: 0,
  },
  {
    BlockNumber: 21,
    Division: 0,
    BlockType: 9,
    UnixTime: 1630396819,
    PreviousHash:
      "RUM0MjZBNEJEMEFEMDk4RDVFMEYyN0RGQzA2OTU5NDY5ODdCRjNCQzEyODY1OEZFNEI4M0UyQjE5MjYzM0Y0NzczMjc3MTJGQzdCODgzNTJFNDJFMzBGMUIxQzQ0Q0Y5NzgwNzM3NjEwMEU4MTgzNDlCODUyMTVEM0Y1NkQwRDU=",
    Hash: "RjFDMkRCMkZEMThGMjM5QTk3MEQ1NEI2MkYxQTY0RkZFNzU2QkFERTE1ODJFNzMxNUM3MjhFODVDMEZCNUVBOENGREUyMDUwNTEyRjAwQzA5NUZFMzQ1NUYxRERERDlCQTlFNDFEMjQ1OTA0MUZEMjUxQUVDRDQ0RDYzNTNDOTI=",
    BlockContent:
      "MDkwMDAwMDAwMTAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMTAwMDAwMDAwMDAwMDAwMTUwMDAwMDAwMDAwMDAwMDkzRTEyRDYxMDAwMDAwMDBFQzQyNkE0QkQwQUQwOThENUUwRjI3REZDMDY5NTk0Njk4N0JGM0JDMTI4NjU4RkU0QjgzRTJCMTkyNjMzRjQ3NzMyNzcxMkZDN0I4ODM1MkU0MkUzMEYxQjFDNDRDRjk3ODA3Mzc2MTAwRTgxODM0OUI4NTIxNUQzRjU2RDBENTYzMDAwMDAwMDAwMDAwMDBCMDA2Q0EyMURGNzIzREExQ0NBRTg4Qzk4OUY5MTdGQTQ5MkY5OUJBNUI1MUU0RTBENkRGOTAzMDVCMzE4NDlFNDI3M0MzRTkxN0IyMDNERTZEODgzMDMxMjJDODEyNzgyMEU4MTJGQ0I1MDQyRkNDRjM2RTVFNkI5MzcyOTkzMjg4MDAwMDAwMDAwMDAwMDA1ODAyMDAwMDAwMDAwMDAwODA3MDAwMDAwMDAwMDAwMDgwNTEwMTAwMDAwMDAwMDAwMzAwMDAwMDAwMDAwMDAwMDEwMDAwMDAwMDAwMDAwMDgwNTEwMTAwMDAwMDAwMDAwMTAwMDAwMDAwMDAwMDAwODA1MTAxMDAwMDAwMDAwMDAzMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAxMDAwMDAwMDAwMDAwMDAwMTAwMDAwMDAwMDAwMDAwMDEwMDAwMDAwMDAwMDAwMDNCNkEyN0JDQ0VCNkE0MkQ2MkEzQThEMDJBNkYwRDczNjUzMjE1NzcxREUyNDNBNjNBQzA0OEExOEI1OUR		BMjlEOUFDRDk5MzExNUQxQjM5NjlDMTYzM0ZBNTY5QzMyQjBBRTIzOTQyRkY4OTM4MDdBQUFENTExQTc1MTFCNzYyRDkwMjkwRUM0RUFCMDMwRTMxMDYzMjk2QUQyMzY5NUYyMzE0NTZEOTAxMDY1NjY4MzU3ODk5NkM0NjA3NkZCOTAxMDAwMDAwMDAwMDAwMDA1QTEyQUY0RjIwMUVFQjU5QjEwNTkzNDNBODlBNzZCQ0ExMTI5NDA3OEQ2MkQzMTY4QzEwRTM2QUQ2NUQ0NDYzMUJCRUMxNzYzMEE4NkU5QzBDMjgyMUFERjVCNDY2OTYxRjA5MTVCNzhGRjkyMDY3NjE5NkQ1MjI2REZEMzMwMQ==",
    MinimumBridgeLedgers: 3,
    GlobalPhyliBal: 2528376,
    GlobalPhyliHold: 850398,
    PhyliAdded: 28378,
    PhyliHoldRelease: 19865,
    TotalStamps: 2416028,
    StampCreated: 87634,
    BlocksAdded: 65774,
    PeersUnderConsensus: 28,
    TimeAccessed: 0,
  },
  {
    BlockNumber: 20,
    Division: 0,
    BlockType: 9,
    UnixTime: 1630310418,
    PreviousHash:
      "MzAwNjIzNjczQkY4QTRGQjQxM0I3ODMzNEI2MzQ3NkI0OEVENUFDMTVFOUI5MURGODk3QjQzRkQwM0Q5NDEwQTYxQ0JEM0UxMjI1QzgyNThCQkI2Q0NBNTJDOEUwRDQ3REJGREEzRUMyRTIxM0M4QUVDMjFGMzlFNzgwRTZEMTU=",
    Hash: "RUM0MjZBNEJEMEFEMDk4RDVFMEYyN0RGQzA2OTU5NDY5ODdCRjNCQzEyODY1OEZFNEI4M0UyQjE5MjYzM0Y0NzczMjc3MTJGQzdCODgzNTJFNDJFMzBGMUIxQzQ0Q0Y5NzgwNzM3NjEwMEU4MTgzNDlCODUyMTVEM0Y1NkQwRDU=",
    BlockContent:
      "MDkwMDAwMDAwMTAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMTAwMDAwMDAwMDAwMDAwMTQwMDAwMDAwMDAwMDAwMDEyOTAyQzYxMDAwMDAwMDAzMDA2MjM2NzNCRjhBNEZCNDEzQjc4MzM0QjYzNDc2QjQ4RUQ1QUMxNUU5QjkxREY4OTdCNDNGRDAzRDk0MTBBNjFDQkQzRTEyMjVDODI1OEJCQjZDQ0E1MkM4RTBENDdEQkZEQTNFQzJFMjEzQzhBRUMyMUYzOUU3ODBFNkQxNTVFMDAwMDAwMDAwMDAwMDAxRkEzMDEzREE4QTA2NDE1OTRENERFQzI1NjYxREY4REZDMjM4OEUwNDA2Q0YzNTQ2NjdGQkI4RTlGRkFBMUNCN0RGMDgzNTYxODRCQzU5MTk1RjJCMzQ1QTNDNDMzNjBBOUFGMTc2NzE0NEZCODJDMEI0QjI0NkU4NkQ2NjlDOTg4MDAwMDAwMDAwMDAwMDA1ODAyMDAwMDAwMDAwMDAwODA3MDAwMDAwMDAwMDAwMDgwNTEwMTAwMDAwMDAwMDAwMzAwMDAwMDAwMDAwMDAwMDEwMDAwMDAwMDAwMDAwMDgwNTEwMTAwMDAwMDAwMDAwMTAwMDAwMDAwMDAwMDAwODA1MTAxMDAwMDAwMDAwMDAzMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAxMDAwMDAwMDAwMDAwMDAwMTAwMDAwMDAwMDAwMDAwMDEwMDAwMDAwMDAwMDAwMDNCNkEyN0JDQ0VCNkE0MkQ2MkEzQThEMDJBNkYwRDczNjUzMjE1NzcxREUyNDNBNjNBQzA0OEExOEI1OUR		BMjlFOENGNjc3MEYzMEUxODg5NjJDN0ZBQkZCNkQwNjk0RDA0RDhDQ0I0NjY1ODJEQkVGQTc4OTM1OTA5OTQwODVBRTE4RTM4MTc0MUY3NDQ0MTIzOUM5QTVEODc3RjI0MTBGQjRBQzNGQjQ0RjQ5NUQ0ODk3Qjc4QTUzNzIwMzk5QjAxMDAwMDAwMDAwMDAwMDBDMTI4QzlDREYwREE4QzIzQjk4ODEwMjJBQTdEQ0M0MzQ0NjhFNzVBRUU5MDBDNURDQzgwREZFMzk1MkNFRjdGMzIwMDJGM0U0RDU1MzI1OTZDRjcyQkEwNTlBRjEyMkMyMjdGM0Y0MzM2NDAwODQwNzJBREYwODMzRTlCMzYwMw==",
    MinimumBridgeLedgers: 3,
    GlobalPhyliBal: 2534865,
    GlobalPhyliHold: 856820,
    PhyliAdded: 26988,
    PhyliHoldRelease: 19865,
    TotalStamps: 2416855,
    StampCreated: 87344,
    BlocksAdded: 65784,
    PeersUnderConsensus: 28,
    TimeAccessed: 0,
  },
  {
    BlockNumber: 19,
    Division: 0,
    BlockType: 9,
    UnixTime: 1630224017,
    PreviousHash:
      "NDQyQ0Q4MzYxODc1M0UwMjlDNEQ5M0ZDNEM0Njc2OTFEMDgwMjAzQTI0NEU2MEQ3OTcyOEFFMzg0NzY0RDM0MzE2RDY2NTNCQjI3RkE1RjU5NTk1MTI5N0VBQTE5RjhDNUY3MjU5MzJCMjg3NzE4QjdGREUxQUJGRjdDNTlCOTM=",
    Hash: "MzAwNjIzNjczQkY4QTRGQjQxM0I3ODMzNEI2MzQ3NkI0OEVENUFDMTVFOUI5MURGODk3QjQzRkQwM0Q5NDEwQTYxQ0JEM0UxMjI1QzgyNThCQkI2Q0NBNTJDOEUwRDQ3REJGREEzRUMyRTIxM0M4QUVDMjFGMzlFNzgwRTZEMTU=",
    BlockContent:
      "MDkwMDAwMDAwMTAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMTAwMDAwMDAwMDAwMDAwMTMwMDAwMDAwMDAwMDAwMDkxM0UyQjYxMDAwMDAwMDA0NDJDRDgzNjE4NzUzRTAyOUM0RDkzRkM0QzQ2NzY5MUQwODAyMDNBMjQ0RTYwRDc5NzI4QUUzODQ3NjREMzQzMTZENjY1M0JCMjdGQTVGNTk1OTUxMjk3RUFBMTlGOEM1RjcyNTkzMkIyODc3MThCN0ZERTFBQkZGN0M1OUI5MzU5MDAwMDAwMDAwMDAwMDA5Q0U5MTgzM0NGQzgwMDk5M0EwQkM0M0JGMDk1QTQ4NERFMzlENkU0RUVBNEY2MUMzRkI5RDBEMERGRkZDODA0QzIzMkIxNzhBNEQxQTQxNDNDQ0JERTdDMjgxRkM4NjVGRjQ5NzI5NzBEODhDMThGQkI3RTJGMDI0RTQzRUUyODg4MDAwMDAwMDAwMDAwMDA1ODAyMDAwMDAwMDAwMDAwODA3MDAwMDAwMDAwMDAwMDgwNTEwMTAwMDAwMDAwMDAwMzAwMDAwMDAwMDAwMDAwMDEwMDAwMDAwMDAwMDAwMDgwNTEwMTAwMDAwMDAwMDAwMTAwMDAwMDAwMDAwMDAwODA1MTAxMDAwMDAwMDAwMDAzMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAxMDAwMDAwMDAwMDAwMDAwMTAwMDAwMDAwMDAwMDAwMDEwMDAwMDAwMDAwMDAwMDNCNkEyN0JDQ0VCNkE0MkQ2MkEzQThEMDJBNkYwRDczNjUzMjE1NzcxREUyNDNBNjNBQzA0OEExOEI1OUR			BMjk5Q0JEM0Y3MkNERUNGOEEwRTMxNTExQTFEOTkzOEQ0RkY4MERFN0JDODY1RkI1Q0M3MDhCQkNDRjkyNzdCQzY0QjNFMzdGODRDODUxRUUzQzM3OTRBREM5MjE2QjJFRTQzMzkyNEZFMkE0MDQxREJBM0FGRkM4NzdFNjFDRDAzRDAxMDAwMDAwMDAwMDAwMDBDMzZGNzk1MDlENTY5MUNEODlEMDNDMDQ5RkNBNzcxNjNEMEE0ODNFMzAzNjY1NzI0NDZFNzZCOTRDQjEwQUZBRUEzNjk4NDU2OTlGNDgxRTRFN0E5QUYyQ0IwMjhBMjQ4RDVCODUyQkI3RDhBNTEzNzI4Q0YyRDg1NDgzNkQwRQ==",
    MinimumBridgeLedgers: 3,
    GlobalPhyliBal: 2589865,
    GlobalPhyliHold: 852320,
    PhyliAdded: 27878,
    PhyliHoldRelease: 17775,
    TotalStamps: 2416965,
    StampCreated: 87984,
    BlocksAdded: 65334,
    PeersUnderConsensus: 28,
    TimeAccessed: 0,
  },
  {
    BlockNumber: 18,
    Division: 0,
    BlockType: 9,
    UnixTime: 1630108816,
    PreviousHash:
      "NTE4NDk4MEE4NzNBRTEzMDc3MThDQzc0NEU5MURCREQ3NzY5MzUwQ0U5RDE3M0YyOTk1MEMwREE3OUM2RDZBOTQyQzVCQ0FEQ0I4ODVEQUIxQTdEMUFGMDFBMUIxRUFFQ0ZBNzZBRDZERUQyNjJGOUNENEU5M0M4NTNGQzk0NjQ=",
    Hash: "NDQyQ0Q4MzYxODc1M0UwMjlDNEQ5M0ZDNEM0Njc2OTFEMDgwMjAzQTI0NEU2MEQ3OTcyOEFFMzg0NzY0RDM0MzE2RDY2NTNCQjI3RkE1RjU5NTk1MTI5N0VBQTE5RjhDNUY3MjU5MzJCMjg3NzE4QjdGREUxQUJGRjdDNTlCOTM=",
    BlockContent:
      "MDkwMDAwMDAwMTAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMTAwMDAwMDAwMDAwMDAwMTIwMDAwMDAwMDAwMDAwMDkwN0MyOTYxMDAwMDAwMDA1MTg0OTgwQTg3M0FFMTMwNzcxOENDNzQ0RTkxREJERDc3NjkzNTBDRTlEMTczRjI5OTUwQzBEQTc5QzZENkE5NDJDNUJDQURDQjg4NURBQjFBN0QxQUYwMUExQjFFQUVDRkE3NkFENkRFRDI2MkY5Q0Q0RTkzQzg1M0ZDOTQ2NDUzMDAwMDAwMDAwMDAwMDA4NUY4MTAwNzI2RDVFNTZCODM0MzBBQTg4NThFNDk1RUFBMzVBRkFERUVFREMwM0IxQzQ3QjIwMDI0RUNFMzU1RUUwODFBMjk5NUM2OEUwRDg3RUU2RERCMjg3QUJDQUU1Mzc5MjU3NTlDMkU0RjdBQTkzNEY2ODU0MUVDNzlEMjg4MDAwMDAwMDAwMDAwMDA1ODAyMDAwMDAwMDAwMDAwODA3MDAwMDAwMDAwMDAwMDgwNTEwMTAwMDAwMDAwMDAwMzAwMDAwMDAwMDAwMDAwMDEwMDAwMDAwMDAwMDAwMDgwNTEwMTAwMDAwMDAwMDAwMTAwMDAwMDAwMDAwMDAwODA1MTAxMDAwMDAwMDAwMDAzMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAxMDAwMDAwMDAwMDAwMDAwMTAwMDAwMDAwMDAwMDAwMDEwMDAwMDAwMDAwMDAwMDNCNkEyN0JDQ0VCNkE0MkQ2MkEzQThEMDJBNkYwRDczNjUzMjE1NzcxREUyNDNBNjNBQzA0OEExOEI1OUR		BMjkwRkE0OTQ2MkE3RDgxMTUzRDgyQzg3OEM5RTg3NEFFRjYzMTY4MzE4NkE4QkZFNkM1RDEyRjM2OERGNkEzQUZEOTdFNkNCNTUxNjg5NzMxQTY2NzQyQTczQUIxMUY4OTI2REIzNzQ5NEI1OUFGMUM1ODhFNjJFQURFRjZFOTE1NzAxMDAwMDAwMDAwMDAwMDA5QTg4QkJBNTc0MDE2QTNEOTUwNjdEMjY4QkU0NjU0RDM4MjM2NTM4NjBBOTg4RTNBMkQyQ0EzMTc4ODk4MjhENjQyNTkwOUQxMkRFRkM2ODJDNkUyNDlGMDYyMkNCRjI2RTREQjg3QzI5QTUyRjYzQzhGQ0E5OTE3NzA0QzQwMA==",
    MinimumBridgeLedgers: 3,
    GlobalPhyliBal: 2526865,
    GlobalPhyliHold: 854720,
    PhyliAdded: 26378,
    PhyliHoldRelease: 17265,
    TotalStamps: 2165765,
    StampCreated: 87454,
    BlocksAdded: 65764,
    PeersUnderConsensus: 28,
    TimeAccessed: 0,
  },
  {
    BlockNumber: 17,
    Division: 0,
    BlockType: 9,
    UnixTime: 1630022415,
    PreviousHash:
      "NTc5ODMxRDVFRjFDQ0E4NTNFN0I1RDhDN0I0NkI0ODIyMENFNEE1MTk5MDM3MUNBQTBBRTY3QUU2MkJFQUYwRDUyNjFBRjA3MjdENkJCNzVDQUVBMDBDRjJEODEyRUI2RDk0OTY1OEYyMTI2MzI3QjgxMDE2QTIwQzRFRDgxQzM=",
    Hash: "NTE4NDk4MEE4NzNBRTEzMDc3MThDQzc0NEU5MURCREQ3NzY5MzUwQ0U5RDE3M0YyOTk1MEMwREE3OUM2RDZBOTQyQzVCQ0FEQ0I4ODVEQUIxQTdEMUFGMDFBMUIxRUFFQ0ZBNzZBRDZERUQyNjJGOUNENEU5M0M4NTNGQzk0NjQ=",
    BlockContent:
      "MDkwMDAwMDAwMTAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMTAwMDAwMDAwMDAwMDAwMTEwMDAwMDAwMDAwMDAwMDBGMkIyODYxMDAwMDAwMDA1Nzk4MzFENUVGMUNDQTg1M0U3QjVEOEM3QjQ2QjQ4MjIwQ0U0QTUxOTkwMzcxQ0FBMEFFNjdBRTYyQkVBRjBENTI2MUFGMDcyN0Q2QkI3NUNBRUEwMENGMkQ4MTJFQjZEOTQ5NjU4RjIxMjYzMjdCODEwMTZBMjBDNEVEODFDMzRFMDAwMDAwMDAwMDAwMDA0MzQ2NjI4OTE0MzNFNjlEREUyN0ZGMDgxOTgwRjE3QkJBQUFCQjlGNThFNDI1MjI3QTIzQTU5MjFGREIwMTAwNjlDQjE1QkJGNDUzNTU5MTI5REQ3NTU1M0Q1RDMxMUM5NjU2REM2REZGMDg0NTRFREI5NDEwN0YyMTg0RjI5MTg4MDAwMDAwMDAwMDAwMDA1ODAyMDAwMDAwMDAwMDAwODA3MDAwMDAwMDAwMDAwMDgwNTEwMTAwMDAwMDAwMDAwMzAwMDAwMDAwMDAwMDAwMDEwMDAwMDAwMDAwMDAwMDgwNTEwMTAwMDAwMDAwMDAwMTAwMDAwMDAwMDAwMDAwODA1MTAxMDAwMDAwMDAwMDAzMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAxMDAwMDAwMDAwMDAwMDAwMTAwMDAwMDAwMDAwMDAwMDEwMDAwMDAwMDAwMDAwMDNCNkEyN0JDQ0VCNkE0MkQ2MkEzQThEMDJBNkYwRDczNjUzMjE1NzcxREUyNDNBNjNBQzA0OEExOEI1OUR			BMjlGQUNGN0FBQ0JEMzI2RDI0OTQxNzEzRDRGRDIxN0FEMkNBMEY0NUIxMTRCNzEyRDNEMTgwNEYwOEIwM0U2RTg3RTM1Q0EyRDAxNkVFNUUyQTExODRBNDQ2M0EwNkNFMjU0NkM0MzI2MDk5MEVGQ0ZFRjA4MTZFMzg1OTU3MUVCQjAxMDAwMDAwMDAwMDAwMDA1RDA5Q0VENzYyNjkxODNDNEUxNkFDNzAzNjU2MjhCMUJFOUUwRUUxRjJEMEEwNzlDRkMyREREOEFDMUYzRDEzNUMyMkQyRUE5RTlCMkI1NzQ0NkM4MEE1QUMzMDI1NjZCMTYzNzlEMEEwRDBDRDEwMkY4M0QxNUI1N0UxMEUwRA==",
    MinimumBridgeLedgers: 3,
    GlobalPhyliBal: 2520655,
    GlobalPhyliHold: 857420,
    PhyliAdded: 26348,
    PhyliHoldRelease: 16565,
    TotalStamps: 24187965,
    StampCreated: 87354,
    BlocksAdded: 65884,
    PeersUnderConsensus: 28,
    TimeAccessed: 0,
  },
  {
    BlockNumber: 16,
    Division: 0,
    BlockType: 9,
    UnixTime: 1629936014,
    PreviousHash:
      "QUM5QzhEOUEzREExOEJFMDdGMDZGRDlBRjI0NEU0Njg3QTg5MjM5QTIxQjU5NDJCNzUyRDM3QUEzRTc5NDUzRjdEMjQwOEUyNUFDRThDRjgyRkQ0QkNDRDlBNzZDQkJBM0JFNEFDRDMzQTM1OTVEN0VBQ0ZBRjIwQzE3RUQ3Nzc=",
    Hash: "NTc5ODMxRDVFRjFDQ0E4NTNFN0I1RDhDN0I0NkI0ODIyMENFNEE1MTk5MDM3MUNBQTBBRTY3QUU2MkJFQUYwRDUyNjFBRjA3MjdENkJCNzVDQUVBMDBDRjJEODEyRUI2RDk0OTY1OEYyMTI2MzI3QjgxMDE2QTIwQzRFRDgxQzM=",
    BlockContent:
      "MDkwMDAwMDAwMTAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMTAwMDAwMDAwMDAwMDAwMTAwMDAwMDAwMDAwMDAwMDhFRDkyNjYxMDAwMDAwMDBBQzlDOEQ5QTNEQTE4QkUwN0YwNkZEOUFGMjQ0RTQ2ODdBODkyMzlBMjFCNTk0MkI3NTJEMzdBQTNFNzk0NTNGN0QyNDA4RTI1QUNFOENGODJGRDRCQ0NEOUE3NkNCQkEzQkU0QUNEMzNBMzU5NUQ3RUFDRkFGMjBDMTdFRDc3NzQ5MDAwMDAwMDAwMDAwMDAxODUyNkU0QTRBNENDMkJBNjNFQUU3MEI4OTEyRkYwNjdFRThDNDkzRTBERDRGRUI3OTg1NEI2MUY3QTgwQzlDMkY2RTIxRjA0OTZENkQ5MTRBQzNBRTA3QUM4NDk1MEFDQjBDNTRBOUFBMjNFN0Q4RkMyNTE1NTFGQzkxOUMxQTg4MDAwMDAwMDAwMDAwMDA1ODAyMDAwMDAwMDAwMDAwODA3MDAwMDAwMDAwMDAwMDgwNTEwMTAwMDAwMDAwMDAwMzAwMDAwMDAwMDAwMDAwMDEwMDAwMDAwMDAwMDAwMDgwNTEwMTAwMDAwMDAwMDAwMTAwMDAwMDAwMDAwMDAwODA1MTAxMDAwMDAwMDAwMDAzMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAxMDAwMDAwMDAwMDAwMDAwMTAwMDAwMDAwMDAwMDAwMDEwMDAwMDAwMDAwMDAwMDNCNkEyN0JDQ0VCNkE0MkQ2MkEzQThEMDJBNkYwRDczNjUzMjE1NzcxREUyNDNBNjNBQzA0OEExOEI1OUR		BMjlCNjQ5MjUyM0YyQ0QyMEQxMEM0MUY0NTg3Q0E4NTkzMDA0MjcwQUE1RERFOTA2OURGREUwQjlERDRCQ0UyQUI0QTAxNzUxMEE4RTQ0NTAxRjhEQjc3RkE3MTNGOENDMDhGMTJGQjQyNUQwOUI1ODg4RkEwNDk4MDA4Q0I4MUQ2NjAxMDAwMDAwMDAwMDAwMDAzMjdDMUNGNkI3OTJERkE5NzlFMEI5RjM2NDgxNDIwMjUwRDdDNjM3Qzk5RTZBRDc1OEVBNkI3RjM3RjQyMjlCQzUxNkFCQ0Q4MjhBMzk3NDdCOUM3OTMxNTYyQTk4MDY4QzA0QTA2RjUwNTAxMkUyREUyQzY0QTgxRDk4OTgwQQ==",
    MinimumBridgeLedgers: 3,
    GlobalPhyliBal: 2576865,
    GlobalPhyliHold: 854620,
    PhyliAdded: 26878,
    PhyliHoldRelease: 18765,
    TotalStamps: 24198365,
    StampCreated: 82354,
    BlocksAdded: 65894,
    PeersUnderConsensus: 28,
    TimeAccessed: 0,
  },
  {
    BlockNumber: 15,
    Division: 0,
    BlockType: 9,
    UnixTime: 1629849613,
    PreviousHash:
      "NjBDMEIxNTFDQTU5NjJGMjM2MThEQkNCRTk3QTU3NzEwNkZFQjAwNEY3QjUzODc3QTdDQ0Y1QzgzMkMzQzdGREQ4NzFDRUZCNzREMTkzNTZCNjU1QkQ4NTM2QzI3MTYwODgwMUY2Nzg0RjBDRTc2NEQ4Mjk4OEM1NkYwMUIzNjU=",
    Hash: "QUM5QzhEOUEzREExOEJFMDdGMDZGRDlBRjI0NEU0Njg3QTg5MjM5QTIxQjU5NDJCNzUyRDM3QUEzRTc5NDUzRjdEMjQwOEUyNUFDRThDRjgyRkQ0QkNDRDlBNzZDQkJBM0JFNEFDRDMzQTM1OTVEN0VBQ0ZBRjIwQzE3RUQ3Nzc=",
    BlockContent:
      "MDkwMDAwMDAwMTAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMTAwMDAwMDAwMDAwMDAwMEYwMDAwMDAwMDAwMDAwMDBEODgyNTYxMDAwMDAwMDA2MEMwQjE1MUNBNTk2MkYyMzYxOERCQ0JFOTdBNTc3MTA2RkVCMDA0RjdCNTM4NzdBN0NDRjVDODMyQzNDN0ZERDg3MUNFRkI3NEQxOTM1NkI2NTVCRDg1MzZDMjcxNjA4ODAxRjY3ODRGMENFNzY0RDgyOTg4QzU2RjAxQjM2NTQ0MDAwMDAwMDAwMDAwMDAyQzhFOUZERUM5RjNBNUQxNEI5QkQ3N0M5MzMzOEQ4MDg5QUZCOTZCNjY4MzBCQjg4MzhFREYxNTJFQ0RGQjE5QkFBNDI0QUVENzM4M0RGQkI2QjkyRkI4MkM3NkE2MDA1OEI0QUYxQzEwQzIzOTdCMTE3QkU5QjhFNjdGNUU5NDg4MDAwMDAwMDAwMDAwMDA1ODAyMDAwMDAwMDAwMDAwODA3MDAwMDAwMDAwMDAwMDgwNTEwMTAwMDAwMDAwMDAwMzAwMDAwMDAwMDAwMDAwMDEwMDAwMDAwMDAwMDAwMDgwNTEwMTAwMDAwMDAwMDAwMTAwMDAwMDAwMDAwMDAwODA1MTAxMDAwMDAwMDAwMDAzMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAxMDAwMDAwMDAwMDAwMDAwMTAwMDAwMDAwMDAwMDAwMDEwMDAwMDAwMDAwMDAwMDNCNkEyN0JDQ0VCNkE0MkQ2MkEzQThEMDJBNkYwRDczNjUzMjE1NzcxREUyNDNBNjNBQzA0OEExOEI1OUR		BMjk3QTc3M0UxQzg2NzNFNkFBOEJCQzgyMzIzQzc1ODQyOURGRDZCQTcwQTUwM0JCQjYzOUU0OEU5RjQyRjlGMjY2RUEwQURCOEI1RUM3RTNCNDU0MEY4RkEwMUZBRDc2REY5NTNFQjE1M0I0OTE0MTNGMjExMjNFRUYxQzZCOEE3RDAxMDAwMDAwMDAwMDAwMDA1RjYyNjEyMTg0NzlCN0U3NDgyREQzREEyQzhENEM1MjZFRUM0MUU1MTY3MUYyOEE5OEY4RTY3OTAyRTU4ODA3REFBQTQ0MDRCQjlFMDBENkM2MDdCNzlBRjkyN0UwNDJFMjRGNUM3M0M0NTVEMjMxODQ3NTYwQTI5Q0VDQTMwMQ==",
    MinimumBridgeLedgers: 3,
    GlobalPhyliBal: 2355865,
    GlobalPhyliHold: 854320,
    PhyliAdded: 22378,
    PhyliHoldRelease: 17865,
    TotalStamps: 2462465,
    StampCreated: 83454,
    BlocksAdded: 65784,
    PeersUnderConsensus: 28,
    TimeAccessed: 0,
  },
  {
    BlockNumber: 14,
    Division: 0,
    BlockType: 9,
    UnixTime: 1629763212,
    PreviousHash:
      "RDc4OTQyMzA5NUEzMjk0MDhCNjcxQ0ZBQThBRUIxNTg0ODM4ODAwRUZFRkYzNjc0Q0ZCMTNFMDA1NjNGNkRFMEEyNjIyRkYwNDA3MUI3QUIyNTk0QjI5MTE3RTYwRjczRTI0MEMxODkwNjM1RDRFNTM1NzYzODkzNDkxOTI5QUQ=",
    Hash: "NjBDMEIxNTFDQTU5NjJGMjM2MThEQkNCRTk3QTU3NzEwNkZFQjAwNEY3QjUzODc3QTdDQ0Y1QzgzMkMzQzdGREQ4NzFDRUZCNzREMTkzNTZCNjU1QkQ4NTM2QzI3MTYwODgwMUY2Nzg0RjBDRTc2NEQ4Mjk4OEM1NkYwMUIzNjU=",
    BlockContent:
      "MDkwMDAwMDAwMTAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMTAwMDAwMDAwMDAwMDAwMEUwMDAwMDAwMDAwMDAwMDhDMzYyNDYxMDAwMDAwMDBENzg5NDIzMDk1QTMyOTQwOEI2NzFDRkFBOEFFQjE1ODQ4Mzg4MDBFRkVGRjM2NzRDRkIxM0UwMDU2M0Y2REUwQTI2MjJGRjA0MDcxQjdBQjI1OTRCMjkxMTdFNjBGNzNFMjQwQzE4OTA2MzVENEU1MzU3NjM4OTM0OTE5MjlBRDNGMDAwMDAwMDAwMDAwMDBGMjc2NjJDM0ExOTBGNUY1OUFCQUNDQzRBQ0I0N0FDRDYzNjUwRDAyQkIxRjZFMzhENjhGNzk5OUI1QjEzNTFGMDk4M0QwOUQwMUU4RUEyRDE2MTlDQjY2NDA2QTkwM0Y4OEIzQjQwM0Y2NjYxRTIwOUZFMjc4N0M3RUZFRDMwNTg4MDAwMDAwMDAwMDAwMDA1ODAyMDAwMDAwMDAwMDAwODA3MDAwMDAwMDAwMDAwMDgwNTEwMTAwMDAwMDAwMDAwMzAwMDAwMDAwMDAwMDAwMDEwMDAwMDAwMDAwMDAwMDgwNTEwMTAwMDAwMDAwMDAwMTAwMDAwMDAwMDAwMDAwODA1MTAxMDAwMDAwMDAwMDAzMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAxMDAwMDAwMDAwMDAwMDAwMTAwMDAwMDAwMDAwMDAwMDEwMDAwMDAwMDAwMDAwMDNCNkEyN0JDQ0VCNkE0MkQ2MkEzQThEMDJBNkYwRDczNjUzMjE1NzcxREUyNDNBNjNBQzA0OEExOEI1OUR		BMjlBRkFENTMxQUUzNTg3RkRDNTdDRTk1Qjg5OENFNzMxNDRFNDlFMUNDRkMxMDZBRTEyMDZDMkU4RDQ1MjhGQjBGRTQwQTI4MzEzMzkyRTlGOUNBNjE5MEJFOEJBN0YwQzdFMTVGNDIxQjBEQjNEMkQ0RUU4NEVGRjY4RjQ2MEY1MTAxMDAwMDAwMDAwMDAwMDAxRTJENjEzMDhFRkU5NjNCQ0E0Njk0RTIyMDMyMTYzMTMwQTMxQUYxMzlCRTc3QjdBMzc5OTVFMTc3RjU4ODRGMDU5NDI4QjNGQzRFNEY0MTFEMzIzRTQzNjM5RTM3OEVGQkQ4QjlDQTZCRTA1MTcxRjJBOTkyOENEQTVBNUQwNQ==",
    MinimumBridgeLedgers: 3,
    GlobalPhyliBal: 2232865,
    GlobalPhyliHold: 854420,
    PhyliAdded: 26378,
    PhyliHoldRelease: 17965,
    TotalStamps: 2416765,
    StampCreated: 87654,
    BlocksAdded: 65784,
    PeersUnderConsensus: 28,
    TimeAccessed: 0,
  },
  {
    BlockNumber: 13,
    Division: 0,
    BlockType: 9,
    UnixTime: 1629676811,
    PreviousHash:
      "NkIyQ0U2MjFCMTk2Qjc3MzVCQTQ1MzRDQTY4M0REMjc5NTIwNEQxMTdBQTkyMTZFRjlDNTZDRkQyOENDNDFCRkQ3NTE2OTk0RTMyQTdDMzI0MEMwNjdBODQwQUIyNEIxMzJBNkUxMTU3QTQ0QzJGRkZDN0Y3OEU0QTFENjE0Rjk=",
    Hash: "RDc4OTQyMzA5NUEzMjk0MDhCNjcxQ0ZBQThBRUIxNTg0ODM4ODAwRUZFRkYzNjc0Q0ZCMTNFMDA1NjNGNkRFMEEyNjIyRkYwNDA3MUI3QUIyNTk0QjI5MTE3RTYwRjczRTI0MEMxODkwNjM1RDRFNTM1NzYzODkzNDkxOTI5QUQ=",
    BlockContent:
      "MDkwMDAwMDAwMTAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMTAwMDAwMDAwMDAwMDAwMEQwMDAwMDAwMDAwMDAwMDBCRTUyMjYxMDAwMDAwMDA2QjJDRTYyMUIxOTZCNzczNUJBNDUzNENBNjgzREQyNzk1MjA0RDExN0FBOTIxNkVGOUM1NkNGRDI4Q0M0MUJGRDc1MTY5OTRFMzJBN0MzMjQwQzA2N0E4NDBBQjI0QjEzMkE2RTExNTdBNDRDMkZGRkM3Rjc4RTRBMUQ2MTRGOTNBMDAwMDAwMDAwMDAwMDA5Njg4QTk1Mzc0MjFEMzM5MDk3NDc3NjQxQTIyNjgwQzg0RjMzRTI0MjgyMDJERUQxRDE5OTlFRUJCQ0U4RDA1NTNBM0U1QTdBM0QzODM2OEI1QjY3NjZFNkZDNDU5RDdFMjc0ODNEMzU2QTFBRjdCOEQwMDY0RDEzMjZCNTE4Mjg4MDAwMDAwMDAwMDAwMDA1ODAyMDAwMDAwMDAwMDAwODA3MDAwMDAwMDAwMDAwMDgwNTEwMTAwMDAwMDAwMDAwMzAwMDAwMDAwMDAwMDAwMDEwMDAwMDAwMDAwMDAwMDgwNTEwMTAwMDAwMDAwMDAwMTAwMDAwMDAwMDAwMDAwODA1MTAxMDAwMDAwMDAwMDAzMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAxMDAwMDAwMDAwMDAwMDAwMTAwMDAwMDAwMDAwMDAwMDEwMDAwMDAwMDAwMDAwMDNCNkEyN0JDQ0VCNkE0MkQ2MkEzQThEMDJBNkYwRDczNjUzMjE1NzcxREUyNDNBNjNBQzA0OEExOEI1OUR		BMjlCOEM3OEM2ODFCNDA2NUVCMjQyNEQ0RjUwNjVERUNGNjcxOTk2MjEwQTlCRjkwNDMyOTQ5QzlFNUU5MTkxMTg0NUMxRDZGMTlFODdBMjA5N0JDNDQ5QTREMDQ3NDA3NkMzQzc5RDExQjczNDg2QzNBMTY4QjRGOTc5MTEwQ0Q5MTAxMDAwMDAwMDAwMDAwMDBCQjNGNjQyNUFGRDQ3NEY1MzVBNDUzODQxNzU3NDE4MDNFMUY2QjUzQkYzMkI5N0FFOUExQkI2MDcwNzdEOTZEQkQwNjVCMEJFMDFDRUEzMjlDODU5NkUyMjRENjQ1RUJFNUY3Rjk1MTJCMUUyNjNCNERBOUU5NTFFMjU1NDYwMg==",
    MinimumBridgeLedgers: 3,
    GlobalPhyliBal: 2520865,
    GlobalPhyliHold: 854420,
    PhyliAdded: 26578,
    PhyliHoldRelease: 17865,
    TotalStamps: 24165765,
    StampCreated: 87654,
    BlocksAdded: 65784,
    PeersUnderConsensus: 28,
    TimeAccessed: 0,
  },
  {
    BlockNumber: 12,
    Division: 0,
    BlockType: 9,
    UnixTime: 1629590410,
    PreviousHash:
      "OTRCRjRGMTE5NURDRjdFQ0Q4RUQ2NDQ5OTMzRkU3MjdBN0I1ODhEM0RBRTcwRUYwNEFDMEE4NUQwQjc1Q0VENzE0OTRCODUzQzgyNjQ3ODUzRjkwQjZCNjI4MjY5QUI1RkJGNjA2REZDRkYwQ0NGMEFGNjY3RDgyMTFBRUY0RDQ=",
    Hash: "NkIyQ0U2MjFCMTk2Qjc3MzVCQTQ1MzRDQTY4M0REMjc5NTIwNEQxMTdBQTkyMTZFRjlDNTZDRkQyOENDNDFCRkQ3NTE2OTk0RTMyQTdDMzI0MEMwNjdBODQwQUIyNEIxMzJBNkUxMTU3QTQ0QzJGRkZDN0Y3OEU0QTFENjE0Rjk=",
    BlockContent:
      "MDkwMDAwMDAwMTAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMTAwMDAwMDAwMDAwMDAwMEMwMDAwMDAwMDAwMDAwMDhBOTMyMTYxMDAwMDAwMDA5NEJGNEYxMTk1RENGN0VDRDhFRDY0NDk5MzNGRTcyN0E3QjU4OEQzREFFNzBFRjA0QUMwQTg1RDBCNzVDRUQ3MTQ5NEI4NTNDODI2NDc4NTNGOTBCNkI2MjgyNjlBQjVGQkY2MDZERkNGRjBDQ0YwQUY2NjdEODIxMUFFRjRENDM1MDAwMDAwMDAwMDAwMDA3NzlEOTQxMTEzMjc0RDA0QjJFQUNBNkVGQzJCNTQ0QTVCNDNDRkE3RTdDMUY4MTQ1NkExMUFBQzhFMzE3QTQzQzM1QTY4MDVEM0ZFQkYwNjFCODFERTU0MDU0OUNCQTc0RjU4OTA2RjBFRDNBQ0ZFNDAwQkVGNDQyMDA4MzI3RTg4MDAwMDAwMDAwMDAwMDA1ODAyMDAwMDAwMDAwMDAwODA3MDAwMDAwMDAwMDAwMDgwNTEwMTAwMDAwMDAwMDAwMzAwMDAwMDAwMDAwMDAwMDEwMDAwMDAwMDAwMDAwMDgwNTEwMTAwMDAwMDAwMDAwMTAwMDAwMDAwMDAwMDAwODA1MTAxMDAwMDAwMDAwMDAzMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAxMDAwMDAwMDAwMDAwMDAwMTAwMDAwMDAwMDAwMDAwMDEwMDAwMDAwMDAwMDAwMDNCNkEyN0JDQ0VCNkE0MkQ2MkEzQThEMDJBNkYwRDczNjUzMjE1NzcxREUyNDNBNjNBQzA0OEExOEI1OUR		BMjk4ODY3MkE0ODZGMDFCQTlEQjFERjRFODQ1NjcyMUIzMTUzQkVBOEUwQjE4MDBCMTYyMUJBREY1NDQwNDczRUMzNzJFQUU2Qjc5OUZDNUNBOThBQUQwNzYwOTQwRDNFMjRGNkE1MDI5MzY2RDBBNEEwMUEwRjFFREQzRDQ2RkYxMzAxMDAwMDAwMDAwMDAwMDA0MUE2NEUwOEREQzJDNUE1M0Y0MzM1RjJEQzk2NUIyOEI2NzA2NjUxNDg3OThGQTI4MTJEREM4NTM2NUM0RUUzRjMzNTk3N0ZBNjM2QTJGRjc0QzQ0NzM2MTUxNzEyOTc3QzVFRDMwQUEwQjNBQTI4RkVENEE3N0I1RDVGQTEwNQ==",
    MinimumBridgeLedgers: 3,
    GlobalPhyliBal: 2520265,
    GlobalPhyliHold: 852420,
    PhyliAdded: 26778,
    PhyliHoldRelease: 17365,
    TotalStamps: 2416575,
    StampCreated: 87654,
    BlocksAdded: 65784,
    PeersUnderConsensus: 28,
    TimeAccessed: 0,
  },
  {
    BlockNumber: 11,
    Division: 0,
    BlockType: 9,
    UnixTime: 1629504009,
    PreviousHash:
      "RjFGODk5NzkyQjA0NEMyRTlCQ0FEQjA2Rjc2MEJCQUEzNUU0Rjc3NzlCMUIzREEzNDczQ0E5REY4MUVFQzk4NjM0ODk2NDc5RDRDRDZENEQ0NkNGQzY3NDBFQzg4MzdERkE5ODZDODEwMDhCOTNFRjAyN0U1QkRDNEYxNzUwMkY=",
    Hash: "OTRCRjRGMTE5NURDRjdFQ0Q4RUQ2NDQ5OTMzRkU3MjdBN0I1ODhEM0RBRTcwRUYwNEFDMEE4NUQwQjc1Q0VENzE0OTRCODUzQzgyNjQ3ODUzRjkwQjZCNjI4MjY5QUI1RkJGNjA2REZDRkYwQ0NGMEFGNjY3RDgyMTFBRUY0RDQ=",
    BlockContent:
      "MDkwMDAwMDAwMTAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMTAwMDAwMDAwMDAwMDAwMEIwMDAwMDAwMDAwMDAwMDA5NDIyMDYxMDAwMDAwMDBGMUY4OTk3OTJCMDQ0QzJFOUJDQURCMDZGNzYwQkJBQTM1RTRGNzc3OUIxQjNEQTM0NzNDQTlERjgxRUVDOTg2MzQ4OTY0NzlENENENkQ0RDQ2Q0ZDNjc0MEVDODgzN0RGQTk4NkM4MTAwOEI5M0VGMDI3RTVCREM0RjE3NTAyRjMwMDAwMDAwMDAwMDAwMDBCRDRGQTUyMjlCRDI5QzZGNjc2QzdFNDNBQzgzNUNFRTM5NDM2NzkzMzNCOEQ0NDdENDNBNkM0MDkxQzRCQTdDNkY1MzFEQTU5MEI0RDMyMTU2MDY3NDdEQThDMjJBRDkxRjIzQkFEMzc4QTI4RkIwOUYxMzA5NUFCRUYyMUY4MDg4MDAwMDAwMDAwMDAwMDA1ODAyMDAwMDAwMDAwMDAwODA3MDAwMDAwMDAwMDAwMDgwNTEwMTAwMDAwMDAwMDAwMzAwMDAwMDAwMDAwMDAwMDEwMDAwMDAwMDAwMDAwMDgwNTEwMTAwMDAwMDAwMDAwMTAwMDAwMDAwMDAwMDAwODA1MTAxMDAwMDAwMDAwMDAzMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAxMDAwMDAwMDAwMDAwMDAwMTAwMDAwMDAwMDAwMDAwMDEwMDAwMDAwMDAwMDAwMDNCNkEyN0JDQ0VCNkE0MkQ2MkEzQThEMDJBNkYwRDczNjUzMjE1NzcxREUyNDNBNjNBQzA0OEExOEI1OUR		BMjk0RkMwMEE0MzMxN0U3N0Y0NEZFMzA2RkJCNUIzODVERjcwNDQ1MjhCRUZBODhBMTAxRjZCQzIyMEVCMTUwRUY1QTU1MDJFRDc2NDU4ODc1QTc4OUE5RkQ2QzA0QzdFMTFDQTMyRDREMjBFODk0NkI2RDBBNjVFQ0MyQjM0ODVDQzAxMDAwMDAwMDAwMDAwMDBBRTJBMTdCRUI4QjA2NEM3OEVCQkZBRUZERTQxMjI0MkYyMDY2NTUxNDExQzNBRjZCQzQwOTIzN0I3MTM1NDBCODZBMUMwN0Y4RTM2RkNGNTMzNjYwOUFFRTJEMDhEQjY4MTFBN0ZFNDM5RkE1N0M4MDM3RjU5RDBEMEUxQTQwMQ==",
    MinimumBridgeLedgers: 3,
    GlobalPhyliBal: 2520865,
    GlobalPhyliHold: 854420,
    PhyliAdded: 26578,
    PhyliHoldRelease: 17865,
    TotalStamps: 2465765,
    StampCreated: 87654,
    BlocksAdded: 65784,
    PeersUnderConsensus: 28,
    TimeAccessed: 0,
  },
  {
    BlockNumber: 10,
    Division: 0,
    BlockType: 9,
    UnixTime: 1629417608,
    PreviousHash:
      "NzBFODI5MDBDRjU5QjM1QThCOEYzMzlCRDI2NjNGN0JBNEI2NkZBQTIwOEZGMTA5MzI2MUREMkJCMzAyQjJEQTU0NTgwRkU4QjcwNkQ3NUYzRDQ2QUU5QzU0NDQ0Qjk0NTJGMTFBOTk5RDc3QjVENENDQjkyNjIyNzM0NUVEMzI=",
    Hash: "RjFGODk5NzkyQjA0NEMyRTlCQ0FEQjA2Rjc2MEJCQUEzNUU0Rjc3NzlCMUIzREEzNDczQ0E5REY4MUVFQzk4NjM0ODk2NDc5RDRDRDZENEQ0NkNGQzY3NDBFQzg4MzdERkE5ODZDODEwMDhCOTNFRjAyN0U1QkRDNEYxNzUwMkY=",
    BlockContent:
      "MDkwMDAwMDAwMTAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMTAwMDAwMDAwMDAwMDAwMEEwMDAwMDAwMDAwMDAwMDg4RjAxRTYxMDAwMDAwMDA3MEU4MjkwMENGNTlCMzVBOEI4RjMzOUJEMjY2M0Y3QkE0QjY2RkFBMjA4RkYxMDkzMjYxREQyQkIzMDJCMkRBNTQ1ODBGRThCNzA2RDc1RjNENDZBRTlDNTQ0NDRCOTQ1MkYxMUE5OTlENzdCNUQ0Q0NCOTI2MjI3MzQ1RUQzMjJCMDAwMDAwMDAwMDAwMDA0NTE0NUU5MjBFNzc4RDM5RTNGNDVBNDVCRDcwOUZGQjc3QUUwMENCNUI3NzlFRTM3Njk5RkVFQTcyMThCNThBQkIzMUNDNTlBOTUzMjZGQUFCOEEyN0NDMEZDQjJBOEVEMEQ4MzI4QUM1RDcyRUQ1Rjg3NkYzMzIzNkJGMzkzQzg4MDAwMDAwMDAwMDAwMDA1ODAyMDAwMDAwMDAwMDAwODA3MDAwMDAwMDAwMDAwMDgwNTEwMTAwMDAwMDAwMDAwMzAwMDAwMDAwMDAwMDAwMDEwMDAwMDAwMDAwMDAwMDgwNTEwMTAwMDAwMDAwMDAwMTAwMDAwMDAwMDAwMDAwODA1MTAxMDAwMDAwMDAwMDAzMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAxMDAwMDAwMDAwMDAwMDAwMTAwMDAwMDAwMDAwMDAwMDEwMDAwMDAwMDAwMDAwMDNCNkEyN0JDQ0VCNkE0MkQ2MkEzQThEMDJBNkYwRDczNjUzMjE1NzcxREUyNDNBNjNBQzA0OEExOEI1OUR		BMjlDNjZBOEM0OTM5OTBBQ0M1MEVCRTc3RTk5RDA4MDMwMDVERTJDQzcyNDI0MjNBNTZDRUVENzEyNEY2MEQwRjVDMjRFRDVBQjNGM0RDODcyOEEwQjJFNEQ3MjY2RjcxNDc3MTEwNTQ5NzJERDc1N0E2OEQ1RkQxNTUyMDQ1MDM3MTAxMDAwMDAwMDAwMDAwMDAzOEY3MkJEM0VGMUQyRDVCMTBDODNDRDJCRkEyQkFFQURBOTNERTBBQzNCQjk4REUxRDg4NUFBQUYyQjM1Mjc4QUVEQjgxNUNCQjE3MjI2RDMyMUJBM0U1MzMzRENFMEYyM0YyNkEwMDYzMjlEOTgxODY4OUU2RkY1RUY2MjYwNQ==",
    MinimumBridgeLedgers: 3,
    GlobalPhyliBal: 2520865,
    GlobalPhyliHold: 853420,
    PhyliAdded: 26658,
    PhyliHoldRelease: 172365,
    TotalStamps: 24165465,
    StampCreated: 87354,
    BlocksAdded: 65384,
    PeersUnderConsensus: 28,
    TimeAccessed: 0,
  },
  {
    BlockNumber: 9,
    Division: 0,
    BlockType: 9,
    UnixTime: 1629331207,
    PreviousHash:
      "NkE4QUQ5NzUyNjc2QjhDNzJFMjY1NzZDNjRCOUVBNUMwQTczNzAzRDM0NjFGMjAyNURCNjhFN0E3MzJGQzlERkMxMEZEMDNFQzg3NzYwRTMyMEFFRTg3RTY5OTYxMEI5RTg5NUE4QkExQURBRkU4Qzk1NTZCNDk1QkNGQUNCNzU=",
    Hash: "NzBFODI5MDBDRjU5QjM1QThCOEYzMzlCRDI2NjNGN0JBNEI2NkZBQTIwOEZGMTA5MzI2MUREMkJCMzAyQjJEQTU0NTgwRkU4QjcwNkQ3NUYzRDQ2QUU5QzU0NDQ0Qjk0NTJGMTFBOTk5RDc3QjVENENDQjkyNjIyNzM0NUVEMzI=",
    BlockContent:
      "MDkwMDAwMDAwMTAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMTAwMDAwMDAwMDAwMDAwMDkwMDAwMDAwMDAwMDAwMDA3OUYxRDYxMDAwMDAwMDA2QThBRDk3NTI2NzZCOEM3MkUyNjU3NkM2NEI5RUE1QzBBNzM3MDNEMzQ2MUYyMDI1REI2OEU3QTczMkZDOURGQzEwRkQwM0VDODc3NjBFMzIwQUVFODdFNjk5NjEwQjlFODk1QThCQTFBREFGRThDOTU1NkI0OTVCQ0ZBQ0I3NTI2MDAwMDAwMDAwMDAwMDAxQjgzMkVGNTNFNjM5MTRFRTc2RjdCNEIxMzE4QjA2QzQ0QzkzNjBCMUU0RDExMDc3NkZGRkUyMjU3RjBDRDA4QUJGMzJDRTU1RTJFNjNDMjJBMjgwRENGRDgzQUM1NzM1QzM0OTIwRDA3MEVBMzFGMjE2NkZDQTg4QzdGRTQ3Mjg4MDAwMDAwMDAwMDAwMDA1ODAyMDAwMDAwMDAwMDAwODA3MDAwMDAwMDAwMDAwMDgwNTEwMTAwMDAwMDAwMDAwMzAwMDAwMDAwMDAwMDAwMDEwMDAwMDAwMDAwMDAwMDgwNTEwMTAwMDAwMDAwMDAwMTAwMDAwMDAwMDAwMDAwODA1MTAxMDAwMDAwMDAwMDAzMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAxMDAwMDAwMDAwMDAwMDAwMTAwMDAwMDAwMDAwMDAwMDEwMDAwMDAwMDAwMDAwMDNCNkEyN0JDQ0VCNkE0MkQ2MkEzQThEMDJBNkYwRDczNjUzMjE1NzcxREUyNDNBNjNBQzA0OEExOEI1OUR		BMjlFMDEyREFEOEI1Mzg2RTQ4M0FERjE3RDM3ODhDMDVCN0I0MzhBMkQwQTdGN0FEMzI5NEYxNTkxMkRBMjcxNTBDMkExRTM0NzA0ODYxNzM1OTU1ODFCNTdDNjYzQzJBNEYwQjgxRjg4OEVFQkFCQzEyNzcwRDE1OTZCN0U2QjVFRDAxMDAwMDAwMDAwMDAwMDAzQUM4OTFEMzkwQkIxNzM5ODI2QUI1NURFRkJGOTAyMDA1MUE0OTgyM0YyREFGRTAyNDFEOTU1RTRCNzc4MUVGRjM1RjM0Qzg2MTM3MUMyQzI3NkQzOTU4MDIyQzY2QzY1OEM4MUQ4MjM0NkM3NURDMzBGM0E4RDJCNEMwNTQwQw==",
    MinimumBridgeLedgers: 3,
    GlobalPhyliBal: 2520865,
    GlobalPhyliHold: 854420,
    PhyliAdded: 26578,
    PhyliHoldRelease: 17865,
    TotalStamps: 2465765,
    StampCreated: 87654,
    BlocksAdded: 65784,
    PeersUnderConsensus: 28,
    TimeAccessed: 0,
  },
  {
    BlockNumber: 8,
    Division: 0,
    BlockType: 9,
    UnixTime: 1629244806,
    PreviousHash:
      "Njk4OTVFNEI2ODVFODg5NENGMzc4MDE0QzMyRTk5OTBEQUQ4Q0YyQzAxMERFOTk1QTU2QThBRjI1QzcxREJBMzA1RDM5NDIzRjc5ODVCQzc3MzM4MTE3MjREOTBENzQyNzMyNjY2MDg4MDEwOTIxMzFCODMyQjZBMjlDQTAwMzU=",
    Hash: "NkE4QUQ5NzUyNjc2QjhDNzJFMjY1NzZDNjRCOUVBNUMwQTczNzAzRDM0NjFGMjAyNURCNjhFN0E3MzJGQzlERkMxMEZEMDNFQzg3NzYwRTMyMEFFRTg3RTY5OTYxMEI5RTg5NUE4QkExQURBRkU4Qzk1NTZCNDk1QkNGQUNCNzU=",
    BlockContent:
      "MDkwMDAwMDAwMTAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMTAwMDAwMDAwMDAwMDAwMDgwMDAwMDAwMDAwMDAwMDg2NEQxQzYxMDAwMDAwMDA2OTg5NUU0QjY4NUU4ODk0Q0YzNzgwMTRDMzJFOTk5MERBRDhDRjJDMDEwREU5OTVBNTZBOEFGMjVDNzFEQkEzMDVEMzk0MjNGNzk4NUJDNzczMzgxMTcyNEQ5MEQ3NDI3MzI2NjYwODgwMTA5MjEzMUI4MzJCNkEyOUNBMDAzNTIxMDAwMDAwMDAwMDAwMDA2NzA4Mjc5MTUzQ0IyQ0EzOUVDNUM2OTAzNTUzODI1NTAzMEY0MTU5RTE3OEU2RkYzMkE4MzYzMEM1MEIwNEI3OTI4MUZBODAwMjgyNDdCNkIyQURFMzI4OEM1MDZEMEM0MDk0RUNBNTZCNjYyQzhDNUM5OTExNkMxNDI1NzEyMzg4MDAwMDAwMDAwMDAwMDA1ODAyMDAwMDAwMDAwMDAwODA3MDAwMDAwMDAwMDAwMDgwNTEwMTAwMDAwMDAwMDAwMzAwMDAwMDAwMDAwMDAwMDEwMDAwMDAwMDAwMDAwMDgwNTEwMTAwMDAwMDAwMDAwMTAwMDAwMDAwMDAwMDAwODA1MTAxMDAwMDAwMDAwMDAzMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAxMDAwMDAwMDAwMDAwMDAwMTAwMDAwMDAwMDAwMDAwMDEwMDAwMDAwMDAwMDAwMDNCNkEyN0JDQ0VCNkE0MkQ2MkEzQThEMDJBNkYwRDczNjUzMjE1NzcxREUyNDNBNjNBQzA0OEExOEI1OUR		BMjlGRDdBNUIxNEEzODRCMjc4OUUxMjMwRUZDOTM1NjYzMzI2RkMyN0M3MDgxQUMyNTBCMzkxNjZFOUYwM0RCQUU3RDhGMjdDMEU1RUMwNDQyQkU3NjA0QzQ0MDhDQzNENUU4QUYwNEMzMUZFQkRERUI0NzAzNzlCNUNEMTU3NjZCRjAxMDAwMDAwMDAwMDAwMDBGMTY1RDkwMEFCNDY1MzhCMjkxMzg1MzhFMTk2QThCMkI3RTlCREIxQzY5MTkwOTQ2QjAzQ0I2OUIwMzEzODI5MjUxREJBRjcxQTQxRDIyREUxNDY0MDJBMkQ0RDYxRjBGNjFFQkM4OTNBMzhEM0VGMEYzQTQ3Qjc5NTg3QkMwOA==",
    MinimumBridgeLedgers: 3,
    GlobalPhyliBal: 2520865,
    GlobalPhyliHold: 858420,
    PhyliAdded: 22578,
    PhyliHoldRelease: 17965,
    TotalStamps: 2425765,
    StampCreated: 87354,
    BlocksAdded: 65764,
    PeersUnderConsensus: 28,
    TimeAccessed: 0,
  },
  {
    BlockNumber: 7,
    Division: 0,
    BlockType: 9,
    UnixTime: 1629158405,
    PreviousHash:
      "RUQ1RjFDODY5RTZCMUJDREVCQ0ExMUM0RkM0MUFFQUQyRUMzNzMzM0VDRkM3MDVENzgyODAyRkVBOEI2N0UzNzA4OUIyREYzQTIwQTQxMUEyNzQ2RTQwRDNDRTA5RUJBNzBGN0M3NTE3MUYxQzlDMkExOTY2Q0VDQ0VFOTgwNTQ=",
    Hash: "Njk4OTVFNEI2ODVFODg5NENGMzc4MDE0QzMyRTk5OTBEQUQ4Q0YyQzAxMERFOTk1QTU2QThBRjI1QzcxREJBMzA1RDM5NDIzRjc5ODVCQzc3MzM4MTE3MjREOTBENzQyNzMyNjY2MDg4MDEwOTIxMzFCODMyQjZBMjlDQTAwMzU=",
    BlockContent:
      "MDkwMDAwMDAwMTAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMTAwMDAwMDAwMDAwMDAwMDcwMDAwMDAwMDAwMDAwMDA1RkMxQTYxMDAwMDAwMDBFRDVGMUM4NjlFNkIxQkNERUJDQTExQzRGQzQxQUVBRDJFQzM3MzMzRUNGQzcwNUQ3ODI4MDJGRUE4QjY3RTM3MDg5QjJERjNBMjBBNDExQTI3NDZFNDBEM0NFMDlFQkE3MEY3Qzc1MTcxRjFDOUMyQTE5NjZDRUNDRUU5ODA1NDFDMDAwMDAwMDAwMDAwMDBBOTg3NTY4RUNFOERBRDFGOTc0ODQ2NjI2RjU1OTNFMzVDNzdEMzJGNzRGOTY4RkM2OUNGNUM4OUI3RjZGMUFGMjNFNTIzODNDMzFDRThEMUE4RTNDQ0FFM0MxMEZENUY4MTY0MzdFQzdEQ0JFQTNBOUFEMkRGNUQxMjkxNjJDQzg4MDAwMDAwMDAwMDAwMDA1ODAyMDAwMDAwMDAwMDAwODA3MDAwMDAwMDAwMDAwMDgwNTEwMTAwMDAwMDAwMDAwMzAwMDAwMDAwMDAwMDAwMDEwMDAwMDAwMDAwMDAwMDgwNTEwMTAwMDAwMDAwMDAwMTAwMDAwMDAwMDAwMDAwODA1MTAxMDAwMDAwMDAwMDAzMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAxMDAwMDAwMDAwMDAwMDAwMTAwMDAwMDAwMDAwMDAwMDEwMDAwMDAwMDAwMDAwMDNCNkEyN0JDQ0VCNkE0MkQ2MkEzQThEMDJBNkYwRDczNjUzMjE1NzcxREUyNDNBNjNBQzA0OEExOEI1OUR		BMjk4RTE5RUNBN0RCQkFBNTA1NkQ1QTg2OUJFNDlBQTI2NDY5QTkzMUVERjk3OEJBMjNGNTdFQjE1Rjc1MTEyRjc0OUQxREIyMTQ0MUU4QzMzM0YxREFDRjQ3QTU0NjcxQkI4Rjg4ODM4RUU5MUI2MUM3NDc5NTQ1NjFCQ0Y4N0Y3NTAxMDAwMDAwMDAwMDAwMDA2NzRGQTFFRUY5NEJDRkEzRTIyQzBCQTExREMzRkI1RTQwMzI1NENDQzQ2ODg2OUMxNTU5RTZFRjNBMjJCMDA5ODA4OTc4MENCNDVDRkQ4MzlENEU2NEVEQjNCOUExQ0JGN0NBOTgwQTFGNTM2MUMyNkNBRDY2NzE2ODE2MDIwNw==",
    MinimumBridgeLedgers: 3,
    GlobalPhyliBal: 2120865,
    GlobalPhyliHold: 834420,
    PhyliAdded: 26678,
    PhyliHoldRelease: 12865,
    TotalStamps: 2416365,
    StampCreated: 87354,
    BlocksAdded: 63784,
    PeersUnderConsensus: 28,
    TimeAccessed: 0,
  },
  {
    BlockNumber: 6,
    Division: 0,
    BlockType: 9,
    UnixTime: 1629072004,
    PreviousHash:
      "RUJBM0Y2MjczMkRFM0UwQjk2OEQxNEY5RTRBOEI5OUJBOUMwQzlDQUY0N0Q5M0IzQzlDODVENzMwMEI0OTMzMjM0NjBDOTcxRURDMkZERTUxNTc2MjA1NURFRjlBQ0NGOUREMTdDOTI5OUFBODY1QTQ0MUE1MTU0MzQxOEUxNzI=",
    Hash: "RUQ1RjFDODY5RTZCMUJDREVCQ0ExMUM0RkM0MUFFQUQyRUMzNzMzM0VDRkM3MDVENzgyODAyRkVBOEI2N0UzNzA4OUIyREYzQTIwQTQxMUEyNzQ2RTQwRDNDRTA5RUJBNzBGN0M3NTE3MUYxQzlDMkExOTY2Q0VDQ0VFOTgwNTQ=",
    BlockContent:
      "MDkwMDAwMDAwMTAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMTAwMDAwMDAwMDAwMDAwMDYwMDAwMDAwMDAwMDAwMDg0QUExOTYxMDAwMDAwMDBFQkEzRjYyNzMyREUzRTBCOTY4RDE0RjlFNEE4Qjk5QkE5QzBDOUNBRjQ3RDkzQjNDOUM4NUQ3MzAwQjQ5MzMyMzQ2MEM5NzFFREMyRkRFNTE1NzYyMDU1REVGOUFDQ0Y5REQxN0M5Mjk5QUE4NjVBNDQxQTUxNTQzNDE4RTE3MjE3MDAwMDAwMDAwMDAwMDAxN0UxQTk4MzZFQjRGOTExNTBFMEQ2NEIyNDQyMTE2QzI1REFCNDAwMEJCRDQzRUJFNTA1OEE1MkMwQjUyNjYzMEE0RkQ1REY2Nzk5MkM2MEM2RUY4OTY4OTAzRTY0RTlCNEExQjc4QjMzNkNFRkVDQ0NDMTBFMDg1RjVEMzVCMTg4MDAwMDAwMDAwMDAwMDA1ODAyMDAwMDAwMDAwMDAwODA3MDAwMDAwMDAwMDAwMDgwNTEwMTAwMDAwMDAwMDAwMzAwMDAwMDAwMDAwMDAwMDEwMDAwMDAwMDAwMDAwMDgwNTEwMTAwMDAwMDAwMDAwMTAwMDAwMDAwMDAwMDAwODA1MTAxMDAwMDAwMDAwMDAzMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAxMDAwMDAwMDAwMDAwMDAwMTAwMDAwMDAwMDAwMDAwMDEwMDAwMDAwMDAwMDAwMDNCNkEyN0JDQ0VCNkE0MkQ2MkEzQThEMDJBNkYwRDczNjUzMjE1NzcxREUyNDNBNjNBQzA0OEExOEI1OUR		BMjk4QzYzNERCMjVBRjc0MjMwQTgyQTlFNjBFRkNCRTU0NEEwRjBDN0EwNjM0NDQwRjlEMEZDRUNDRTYzQzMzNjI0RTA2NTZCNjdDRDM1RDUyQzQwNUVGQzdFNjAxRjUyMzA2MjYyNjIxNUVDRTUzQ0NFQThDMTVBN0M3QjZEMTA2QTAxMDAwMDAwMDAwMDAwMDAxQkQ1RTY5NDREQUI4QTk5RkQwQTVFRDJDQzJGNkI5MUQ2QjlEQkVDQkVFOTEwMTczM0QwNDIwNDU0ODk1RjU2RkZDN0E5NTgyMkMwRTQ4QTAwOTE4NzA2QzZCODdGRTJGQThCMzIzRkI2REEzMDJGRDBBQTAzOUI1NDVEODcwQw==",
    MinimumBridgeLedgers: 3,
    GlobalPhyliBal: 2521265,
    GlobalPhyliHold: 854520,
    PhyliAdded: 27778,
    PhyliHoldRelease: 18865,
    TotalStamps: 2413765,
    StampCreated: 87554,
    BlocksAdded: 65794,
    PeersUnderConsensus: 28,
    TimeAccessed: 0,
  },
  {
    BlockNumber: 5,
    Division: 0,
    BlockType: 9,
    UnixTime: 1628985603,
    PreviousHash:
      "NUMzNzY0Rjg2MDdGODRDMjFCMzZDMzEzOTMxMjk5M0Y5NjNDREY2MTU3RDU0RTM1RjI4QkU3M0I5NkRBNDBCODgzRjQ2MzJBMjUzNzA1NTdFQUNDODdBNzVDMjhCQjE0MTU4NTRDOERFNzhFOEE5Njc1REQyOTMyMzM3ODA1MTQ=",
    Hash: "RUJBM0Y2MjczMkRFM0UwQjk2OEQxNEY5RTRBOEI5OUJBOUMwQzlDQUY0N0Q5M0IzQzlDODVENzMwMEI0OTMzMjM0NjBDOTcxRURDMkZERTUxNTc2MjA1NURFRjlBQ0NGOUREMTdDOTI5OUFBODY1QTQ0MUE1MTU0MzQxOEUxNzI=",
    BlockContent:
      "MDkwMDAwMDAwMTAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMTAwMDAwMDAwMDAwMDAwMDUwMDAwMDAwMDAwMDAwMDAzNTkxODYxMDAwMDAwMDA1QzM3NjRGODYwN0Y4NEMyMUIzNkMzMTM5MzEyOTkzRjk2M0NERjYxNTdENTRFMzVGMjhCRTczQjk2REE0MEI4ODNGNDYzMkEyNTM3MDU1N0VBQ0M4N0E3NUMyOEJCMTQxNTg1NEM4REU3OEU4QTk2NzVERDI5MzIzMzc4MDUxNDEyMDAwMDAwMDAwMDAwMDAxQURCN0FBMkE0RUI1MDM2NTYwRkMzMEYyNEQzM0IzMzI0RUMzNjFCNUFFMkU4NTA0Nzk3MjA0OTlEMEEzM0M4RjczNTc4QkVENzA0RTYzRTg3NjM0NzQ4N0M5QjU0RjIwNTEwOTZBOEQ4N0QxOTA3RTdFNzc3MkM5NjJCRjE3MDg4MDAwMDAwMDAwMDAwMDA1ODAyMDAwMDAwMDAwMDAwODA3MDAwMDAwMDAwMDAwMDgwNTEwMTAwMDAwMDAwMDAwMzAwMDAwMDAwMDAwMDAwMDEwMDAwMDAwMDAwMDAwMDgwNTEwMTAwMDAwMDAwMDAwMTAwMDAwMDAwMDAwMDAwODA1MTAxMDAwMDAwMDAwMDAzMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAxMDAwMDAwMDAwMDAwMDAwMTAwMDAwMDAwMDAwMDAwMDEwMDAwMDAwMDAwMDAwMDNCNkEyN0JDQ0VCNkE0MkQ2MkEzQThEMDJBNkYwRDczNjUzMjE1NzcxREUyNDNBNjNBQzA0OEExOEI1OUR		BMjk2QjAyQ0M4QzMxOTNDNjhFMDZFRDk1M0E5N0M1NDE1NUZCRUJERDk3OUY0Q0EzQUZGOUZBQzBFQUM3QjJDMzEwM0M3MzE2MENGODNBMzkzMjg0ODM3NDU1RUMzOTczREZGMzk5RDdDMkE4QkUyQTlDMDcyMzQwOEUyOTY2MkFBRTAxMDAwMDAwMDAwMDAwMDA2MzI0QTg3MDYyQTQ3ODVBMEZDODk3MzE3MDBBRkY1QTA1MzA4MUE4M0Q0M0Y2MUQzREM0ODgwM0ZGM0EzMzg2Q0RBOEE2MzgwRjI3RkQ5Mjg1OTBDNjY5QzQzNzQ4QjdGMjQzRERFMUE3NDI5QUUyOTlGMkFERjM1NUVBRDUwNg==",
    MinimumBridgeLedgers: 3,
    GlobalPhyliBal: 2340865,
    GlobalPhyliHold: 855420,
    PhyliAdded: 26878,
    PhyliHoldRelease: 18865,
    TotalStamps: 2416465,
    StampCreated: 81254,
    BlocksAdded: 65234,
    PeersUnderConsensus: 28,
    TimeAccessed: 0,
  },
  {
    BlockNumber: 4,
    Division: 0,
    BlockType: 9,
    UnixTime: 1628899202,
    PreviousHash:
      "OENBODMwNjc4RUZDRDJBMTVBMTkyNUFFNUNENzA3MEI4QjQwQzFFMzE1M0Y0REYyODM1MUU2OTI4NzNCNkM0NThENkJBMzNDRTMwMUQ4QzhBODAzM0RGQUZCOEZEMEE3NUE5RjA0Rjk1MDA2MDZDRjY4MEY1MDI1RTAzMEZDMkI=",
    Hash: "NUMzNzY0Rjg2MDdGODRDMjFCMzZDMzEzOTMxMjk5M0Y5NjNDREY2MTU3RDU0RTM1RjI4QkU3M0I5NkRBNDBCODgzRjQ2MzJBMjUzNzA1NTdFQUNDODdBNzVDMjhCQjE0MTU4NTRDOERFNzhFOEE5Njc1REQyOTMyMzM3ODA1MTQ=",
    BlockContent:
      "MDkwMDAwMDAwMTAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMTAwMDAwMDAwMDAwMDAwMDQwMDAwMDAwMDAwMDAwMDgyMDcxNzYxMDAwMDAwMDA4Q0E4MzA2NzhFRkNEMkExNUExOTI1QUU1Q0Q3MDcwQjhCNDBDMUUzMTUzRjRERjI4MzUxRTY5Mjg3M0I2QzQ1OEQ2QkEzM0NFMzAxRDhDOEE4MDMzREZBRkI4RkQwQTc1QTlGMDRGOTUwMDYwNkNGNjgwRjUwMjVFMDMwRkMyQjBEMDAwMDAwMDAwMDAwMDAyQUQzNUM5MDRBNDNGODUwMzIzMEQ4NjQ2RjEwQzBCMUY0MTM2QjM3NDFGNUQzRTlDNkZCREVBQzk0QkREMDI5MkIxMjgwODExNzc0NDY5QzlFNjg4Q0VDQUEzRDE1OEREMzA3NjBGMTJCQ0FFOTY1NTFBN0VEMzZBREVBM0NGMTg4MDAwMDAwMDAwMDAwMDA1ODAyMDAwMDAwMDAwMDAwODA3MDAwMDAwMDAwMDAwMDgwNTEwMTAwMDAwMDAwMDAwMzAwMDAwMDAwMDAwMDAwMDEwMDAwMDAwMDAwMDAwMDgwNTEwMTAwMDAwMDAwMDAwMTAwMDAwMDAwMDAwMDAwODA1MTAxMDAwMDAwMDAwMDAzMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAxMDAwMDAwMDAwMDAwMDAwMTAwMDAwMDAwMDAwMDAwMDEwMDAwMDAwMDAwMDAwMDNCNkEyN0JDQ0VCNkE0MkQ2MkEzQThEMDJBNkYwRDczNjUzMjE1NzcxREUyNDNBNjNBQzA0OEExOEI1OUR		BMjkxRDQzMDA5OEUzNjYxRkRBQjYzNDgzOUE5MzU3NDc5M0Q4MjI5NzIxOTQyNUQwMzI5N0IzQUM0QjM0Rjk3MDE1NzRCQjBGNTc4RjRCMTJGODFBQTVDMjg4OEYzQzRFODEwNDk1NDMyNTAzRjFEQURGRURFQjBGNEU0Nzg4Mzc0RDAxMDAwMDAwMDAwMDAwMDBDQTlGRjA4MzU2M0VGOUUyNERBMEMzMUY4RkNDOUMwRTgzNTJEQTczMDc1NEUzRUY0MDJBOUZEODk1RjY3QUE4QjJCNTk4NUNDOTk2MUQwMkJBODU3MDIwQUE5RTBFRTIwQjJEQUZGRkM4ODBEMTU3NzdFMjJGNzkzRDkyNkMwNA==",
    MinimumBridgeLedgers: 3,
    GlobalPhyliBal: 2520865,
    GlobalPhyliHold: 854420,
    PhyliAdded: 26578,
    PhyliHoldRelease: 17865,
    TotalStamps: 2416575,
    StampCreated: 87654,
    BlocksAdded: 65784,
    PeersUnderConsensus: 28,
    TimeAccessed: 0,
  },
  {
    BlockNumber: 3,
    Division: 0,
    BlockType: 9,
    UnixTime: 1628812801,
    PreviousHash:
      "NzI5MDc5NTlCMTFDNEMxNTY3RUI2QzkwMDlDNEQ5N0RGMjAxNzI1RDE1RjhGOTFDMEQwQzEwRjUwMjQ5QzNDQ0EyQzlBRjMyMUExQUQ1RDAwMkFBRkZGMDlDRDc5QjY0MDE3NjRCRDAwQkM3MTQ1NjVGOEY2MDY3RDQ5MEFCNDE=",
    Hash: "OENBODMwNjc4RUZDRDJBMTVBMTkyNUFFNUNENzA3MEI4QjQwQzFFMzE1M0Y0REYyODM1MUU2OTI4NzNCNkM0NThENkJBMzNDRTMwMUQ4QzhBODAzM0RGQUZCOEZEMEE3NUE5RjA0Rjk1MDA2MDZDRjY4MEY1MDI1RTAzMEZDMkI=",
    BlockContent:
      "MDkwMDAwMDAwMTAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMTAwMDAwMDAwMDAwMDAwMDMwMDAwMDAwMDAwMDAwMDAxQjYxNTYxMDAwMDAwMDA3MjkwNzk1OUIxMUM0QzE1NjdFQjZDOTAwOUM0RDk3REYyMDE3MjVEMTVGOEY5MUMwRDBDMTBGNTAyNDlDM0NDQTJDOUFGMzIxQTFBRDVEMDAyQUFGRkYwOUNENzlCNjQwMTc2NEJEMDBCQzcxNDU2NUY4RjYwNjdENDkwQUI0MTA4MDAwMDAwMDAwMDAwMDA1OUY3QzEyMzJFRTJFQUEyRDdFOTMxM0RGMjhFRjFCMzI4NUYxQTRDNTE2MjgxRTI5QjhDNTA0RTY3ODY5NzJCNkFFRkVFRTk1QzMzRjkwMjkxMjA4MEZFRTBFQjQ4MUQyMjVFMjkyODI3NEE4RUREMkY2QTRCNzg4MDgyNjcxNDg4MDAwMDAwMDAwMDAwMDA1ODAyMDAwMDAwMDAwMDAwODA3MDAwMDAwMDAwMDAwMDgwNTEwMTAwMDAwMDAwMDAwMzAwMDAwMDAwMDAwMDAwMDEwMDAwMDAwMDAwMDAwMDgwNTEwMTAwMDAwMDAwMDAwMTAwMDAwMDAwMDAwMDAwODA1MTAxMDAwMDAwMDAwMDAzMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAxMDAwMDAwMDAwMDAwMDAwMTAwMDAwMDAwMDAwMDAwMDEwMDAwMDAwMDAwMDAwMDNCNkEyN0JDQ0VCNkE0MkQ2MkEzQThEMDJBNkYwRDczNjUzMjE1NzcxREUyNDNBNjNBQzA0OEExOEI1OUR		BMjk5NkE5RjhDNzk3MzI2NEQ3QUUwRjYyQkZBMTU4OTMxQkRFMzZDQkYzRUMyNDZFRkI2N0MyNzFGRTMyNEE5RTYyODBCRThFNDRCNzJCQUU0RTEzQTE0MkVBNzdDRjY3NUMwNThGMDJBN0ZBMEQ0NkUwMUJCQzU5RkQ5RDE1MzM4RDAxMDAwMDAwMDAwMDAwMDA0OUMzNjA3NkJGNTQ5ODlFM0EzNERBOEU0RTQwOUMxNUI5NDA0MUI1NjcyNDNCNjZGNEZBQjA1NEY1N0VENTk0QUM5RUVGMDkzRDREM0VFNThBQkEzNzcxMTNDNjg0RTBBNjE4RDU2RDZDNzcwNzJEQ0VBRTBDNTUyRDUyNTcwRg==",
    MinimumBridgeLedgers: 3,
    GlobalPhyliBal: 2524565,
    GlobalPhyliHold: 834420,
    PhyliAdded: 32578,
    PhyliHoldRelease: 21865,
    TotalStamps: 2436575,
    StampCreated: 85654,
    BlocksAdded: 65984,
    PeersUnderConsensus: 28,
    TimeAccessed: 0,
  },
  {
    BlockNumber: 2,
    Division: 0,
    BlockType: 9,
    UnixTime: 1628726400,
    PreviousHash:
      "M0I4Nzg2NkU4QTE4QUQzOTQyRjIxOEUwMzM3NUM1REJGQjJFRDI3NUZFRUNFQTk4ODk2NzhGOTQ1QTFGQ0VGMTAzMzMzRDdFOEFBODUzQkRDRDIwQkNDQzI1MkQ3OEIwODkzQjJGMDVGMTY0RENGODRGODI4QUNCNkI0QTY0ODA=",
    Hash: "NzI5MDc5NTlCMTFDNEMxNTY3RUI2QzkwMDlDNEQ5N0RGMjAxNzI1RDE1RjhGOTFDMEQwQzEwRjUwMjQ5QzNDQ0EyQzlBRjMyMUExQUQ1RDAwMkFBRkZGMDlDRDc5QjY0MDE3NjRCRDAwQkM3MTQ1NjVGOEY2MDY3RDQ5MEFCNDE=",
    BlockContent:
      "MDkwMDAwMDAwMTAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMTAwMDAwMDAwMDAwMDAwMDIwMDAwMDAwMDAwMDAwMDgwNjQxNDYxMDAwMDAwMDAzQjg3ODY2RThBMThBRDM5NDJGMjE4RTAzMzc1QzVEQkZCMkVEMjc1RkVFQ0VBOTg4OTY3OEY5NDVBMUZDRUYxMDMzMzNEN0U4QUE4NTNCRENEMjBCQ0NDMjUyRDc4QjA4OTNCMkYwNUYxNjREQ0Y4NEY4MjhBQ0I2QjRBNjQ4MDAyMDAwMDAwMDAwMDAwMDBFNDMwMUFCQTJBQ0U4OEIxMTU2MEMyMzdFRTkwNkZCOUNBNDMyNjQzMTFGM0E3NjQyNzdBNUUxRDQwMDIzRTkwMTlBRThEQUZGMEEwQTNGNUZEMzg1MUYyNkRCNjg5RkVFRUY3MDVENjREMjI3RjM0OTc3QURDMUEwRDIzNjI3MDg4MDAwMDAwMDAwMDAwMDA1ODAyMDAwMDAwMDAwMDAwODA3MDAwMDAwMDAwMDAwMDgwNTEwMTAwMDAwMDAwMDAwMzAwMDAwMDAwMDAwMDAwMDEwMDAwMDAwMDAwMDAwMDgwNTEwMTAwMDAwMDAwMDAwMTAwMDAwMDAwMDAwMDAwODA1MTAxMDAwMDAwMDAwMDAzMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAxMDAwMDAwMDAwMDAwMDAwMTAwMDAwMDAwMDAwMDAwMDEwMDAwMDAwMDAwMDAwMDNCNkEyN0JDQ0VCNkE0MkQ2MkEzQThEMDJBNkYwRDczNjUzMjE1NzcxREUyNDNBNjNBQzA0OEExOEI1OUR		BMjlENjNDRTExODM4MUFFODNCNUU4QjI0NEMxMTE2MDExMDIwQ0EwQzJEMUJFM0I5RDdGQjU5MTlEQUNBRENFRjdGMkUyQTkyNTVCMjkxNzM4MkM3QzA0Qzg3NUNFNERGQjY1MEQ2RkFGMzg4MDg3QjM4QUQyQTE2OEQxODlEMDIwRTAxMDAwMDAwMDAwMDAwMDBBREI4RTFBQzlFQTJGMzczMDc3N0Y5RDUyRUIxNTc5NzVERTY2NjRCNjQwOEU5MjRBNzVENkVERkIyQzhBQTYxNkFDRUYzQTUxMjZCRjk3MTQ1NTJBODYzRkE1MTU5NEEyNkU0OTVFQjkzQjBCODE4RTlBOUM5QzM1ODVFNTEwNA==",
    MinimumBridgeLedgers: 3,
    GlobalPhyliBal: 2520865,
    GlobalPhyliHold: 854420,
    PhyliAdded: 26578,
    PhyliHoldRelease: 17865,
    TotalStamps: 2165765,
    StampCreated: 87654,
    BlocksAdded: 65784,
    PeersUnderConsensus: 28,
    TimeAccessed: 0,
  },
  {
    BlockNumber: 1,
    Division: 0,
    BlockType: 9,
    UnixTime: 1628640000,
    PreviousHash:
      "MDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDA=",
    Hash: "M0I4Nzg2NkU4QTE4QUQzOTQyRjIxOEUwMzM3NUM1REJGQjJFRDI3NUZFRUNFQTk4ODk2NzhGOTQ1QTFGQ0VGMTAzMzMzRDdFOEFBODUzQkRDRDIwQkNDQzI1MkQ3OEIwODkzQjJGMDVGMTY0RENGODRGODI4QUNCNkI0QTY0ODA=",
    BlockContent:
      "MDkwMDAwMDAwMTAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMTAwMDAwMDAwMDAwMDAwMDEwMDAwMDAwMDAwMDAwMDAwMTMxMzYxMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAxMDAwMDAwMDAwMDAwMDBCQTI2MzFDRjYzQ0VERjU4QTZCNkIwQkMzQUNCREExNDc1REVGMTIwRjg4QzBBMDlGREJDQTU0RDM4REMwNDQ4NjdBRENDNUE3ODk1MkM2Q0U5RDZCQUIwN0NGQTc4RTg5MzBCMkFGRDREMjk1NTA1RURCNUZGQkFDNDE4QTk4Qjg4MDAwMDAwMDAwMDAwMDA1ODAyMDAwMDAwMDAwMDAwODA3MDAwMDAwMDAwMDAwMDgwNTEwMTAwMDAwMDAwMDAwMzAwMDAwMDAwMDAwMDAwMDEwMDAwMDAwMDAwMDAwMDgwNTEwMTAwMDAwMDAwMDAwMTAwMDAwMDAwMDAwMDAwODA1MTAxMDAwMDAwMDAwMDAzMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAxMDAwMDAwMDAwMDAwMDAwMTAwMDAwMDAwMDAwMDAwMDEwMDAwMDAwMDAwMDAwMDNCNkEyN0JDQ0VCNkE0MkQ2MkEzQThEMDJBNkYwRDczNjUzMjE1NzcxREUyNDNBNjNBQzA0OEExOEI1OUR		BMjlGQThGQTNCREFBODA2MjYwQUM4MjlFMEVDMjE5MTY0NEFGQTYwNTZCRjBBQzMwQUNFQkMwODdCN0Q5RDVGOEZFNTIzOEVBOTNGOTgxQzQzMkE4OTlFQkYwRTE4REVBOTdERjdBOTY1RjkwOTVGMzREOTIwNDVCRjc3QTE4NEJBNjAxMDAwMDAwMDAwMDAwMDA4NDRDQTk0QTcwRjdCQUExMzZFMEQyQkY0NUYzQjlCNEZDRDgxQzg1MzIwQUIwNkVDQ0M5OTZGODJGODM4QThGRUY4QTJGRTNENzcyQTNBQjVFN0I4RTgyQkM5MjFEQjM5N0JCMDdDQ0I4NTI3QzUwRTc5NjZDRDVDOTJGMjUwQQ==",
    MinimumBridgeLedgers: 3,
    GlobalPhyliBal: 1520865,
    GlobalPhyliHold: 844420,
    PhyliAdded: 26528,
    PhyliHoldRelease: 12865,
    TotalStamps: 2418565,
    StampCreated: 83654,
    BlocksAdded: 65384,
    PeersUnderConsensus: 28,
    TimeAccessed: 0,
  },
];
export default GLOBAL_STATS;
