import { useQuery } from "react-query";
import { PAGE_INFO } from "../data/pageInfo.data";

let newIndex = -1;

const fetchPrimaryData = async (peerInfo, bridges) => {
  bridges.map((bridge, index) => {
    bridge.bridgeData.map((item) => {
      if (item.BlockNumber === parseInt(peerInfo[0])) {
        newIndex = index;
      }
      return newIndex;
    });
    return newIndex;
  });
  await fetch(`${PAGE_INFO.dataPath}/global/${bridges[newIndex].global}`);
  await fetch(`${PAGE_INFO.dataPath}/bridge/${peerInfo[0]}`);
  const respPrimary = await fetch(`${PAGE_INFO.dataPath}/primary/range`);
  return respPrimary.json();
};

export const usePrimaryFetch = (peerInfo, bridges, primarySuccess) => {
  return useQuery(["get-Primary", peerInfo, bridges], () => fetchPrimaryData(peerInfo, bridges), {
    enabled: false,
    onSuccess: primarySuccess,
  });
};
// let newIndex = -1;
// let globalNum = 0;

// const fetchSyncData = async (blockNumber, bridges) => {
//   bridges.map((bridge, index) => {
//     bridge.bridgeData.map((item) => {
//       if (item.BlockNumber === parseInt(blockNumber)) {
//         newIndex = index;
//         globalNum = bridge.global;
//       }
//       return item;
//     });
//     return newIndex;
//   });
//   await fetch(`${PAGE_INFO.dataPath}/global/${globalNum}`);
//   await fetch(`${PAGE_INFO.dataPath}/bridge/${blockNumber}`);
//   const syncData = await fetch(`${PAGE_INFO.dataPath}/psl`);
//   return syncData.json();
// };

// export const useGetSyncLists = (blockNumber, bridges, syncSuccess) => {
//   return useQuery(["get-SyncList", blockNumber, bridges], () => fetchSyncData(blockNumber, bridges), {
//     enabled: false,
//     onSuccess: syncSuccess,
//   });
// };
