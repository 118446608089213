import React from "react";

import "./Footer.Styles.scss";

let copyrightDate = new Date();

const Footer = () => {
  return (
    <div className='footer'>
      <div className='footer-container'>
        <p>&copy; Copyright {copyrightDate.getFullYear()} Blockframe&reg; Inc. All rights reserved.</p>
      </div>
    </div>
  );
};

export default Footer;
