import { useQuery } from "react-query";
import { PAGE_INFO } from "../data/pageInfo.data";

let newIndex = -1;

const fetchContentData = async (bridgeNumber, peerNumber, bridges) => {
  bridges.map((bridge, index) => {
    bridge.bridgeData.map((item) => {
      if (item.BlockNumber === parseInt(bridgeNumber)) {
        newIndex = index;
      }
      return newIndex;
    });
    return newIndex;
  });
  await fetch(`${PAGE_INFO.dataPath}/global/${bridges[newIndex].global}`);
  await fetch(`${PAGE_INFO.dataPath}/bridge/${bridgeNumber}`);
  await fetch(`${PAGE_INFO.dataPath}/peers/${peerNumber}`);
  await fetch(`${PAGE_INFO.dataPath}/content/last`);
  const resContent = await fetch(`${PAGE_INFO.dataPath}/content/pageDown`);
  return resContent.json();
};

export const useGetContent = (bridgeNumber, peerNumber, bridges, contentSuccess) => {
  return useQuery(
    ["get-content", bridgeNumber, peerNumber, bridges, contentSuccess],
    () => fetchContentData(bridgeNumber, peerNumber, bridges),
    {
      enabled: false,
      onSuccess: contentSuccess,
    }
  );
};

// let newIndex = -1;
// let globalNum = 0;

// const fetchSyncData = async (blockNumber, bridges) => {
//   bridges.map((bridge, index) => {
//     bridge.bridgeData.map((item) => {
//       if (item.BlockNumber === parseInt(blockNumber)) {
//         newIndex = index;
//         globalNum = bridge.global;
//       }
//       return item;
//     });
//     return newIndex;
//   });
//   await fetch(`${PAGE_INFO.dataPath}/global/${globalNum}`);
//   await fetch(`${PAGE_INFO.dataPath}/bridge/${blockNumber}`);
//   const syncData = await fetch(`${PAGE_INFO.dataPath}/psl`);
//   return syncData.json();
// };

// export const useGetSyncLists = (blockNumber, bridges, syncSuccess) => {
//   return useQuery(["get-SyncList", blockNumber, bridges], () => fetchSyncData(blockNumber, bridges), {
//     enabled: false,
//     onSuccess: syncSuccess,
//   });
// };
