import { useState } from "react";
import { useLogin } from "../../contexts/Login.contexts";

import "./login.styles.scss";

const LoginPage = () => {
  const [user, setUser] = useState();
  const auth = useLogin();
  const testEmail = /^[]*([^@\s]+)@((?:[-a-z0-9]+\.)+[a-z]{2,})[]*$/i;
  const handleLogin = () => {
    auth.login(user);
  };

  // console.log("hello");
  // console.log(isValidEmail(user));

  return (
    <div className='login-container'>
      <label>
        Thanks for providing your email. Email is only used to allow us to know who has visited our demonstration. Your
        email will not be shared with any third parties.
      </label>
      <label className='login-label'>Please enter your email: </label>
      <input
        className='login-input'
        type='email'
        onChange={(evt) => {
          console.log(testEmail.test(evt.target.value));
          if (testEmail.test(evt.target.value) && evt.target.value.length < 50) {
            setUser(evt.target.value);
          } else {
            setUser();
          }
        }}
        maxLength={50}
      />
      {!testEmail.test(user) ? <label>Please enter a valid email</label> : null}
      <button disabled={!testEmail.test(user)} className='login-button' onClick={handleLogin}>
        Login
      </button>
    </div>
  );
};

export default LoginPage;
