import { useContext, useState, useEffect } from "react";

// *****************************
// ***** COMPONENT IMPORTS *****
// *****************************
import SmallCard from "../../components/smallCard/SmallCard.component";
import BridgeConsensusLine from "../../components/charts/bridgeConsensus/BridgeConsensusLine";
import BridgeConsensusPie from "../../components/charts/bridgeConsensus/BridgeConsensusPie";

// ********************************
// ***** CONTEXT/DATA IMPORTS *****
// ********************************
import { SelectedBlockContext } from "../../contexts/SelectedBlock.context";
// import { GlobalsContext } from "../../contexts/globals.context";
import { BridgesContext } from "../../contexts/Bridges.context";
import { useBridgeDeserializedFetch } from "../../hooks/useBridgeDeserializedFetch.hook";
import { PAGE_INFO } from "../../data/pageInfo.data";
import GLOBAL_STATS from "../../data/globalStats.data"; // TEMPORARY

// *************************
// ***** STYLE IMPORTS *****
// *************************
import "./BridgeConsensuView_1.styles.scss";

const BridgeConsensus_1 = ({ treeData }) => {
  let currentBridge = { global: 0, bridge: {} };
  // const [currentDeserialized, setCurrentDeserialized] = useState();
  const bridges = useContext(BridgesContext);
  const selectedBlock = useContext(SelectedBlockContext);
  const { bridgeDeserialized, setBridgeDeserialized } =
    useContext(BridgesContext);
  const [currentBridgeInfo, setCurrentBridgeInfo] = useState({
    peerCount: 0,
    phyliTransferred: 0,
    globalHoldMargin: 0,
    bridgeHoldMargin: 0,
    cumulativeBridgeTrust: 0,
    globalPLPercentage: 0,
  });

  bridges.bridges.map((bridge) => {
    bridge.bridgeData.map((bridgeItem) => {
      if (bridgeItem.BlockNumber === selectedBlock.selectedBlock.BlockNumber) {
        currentBridge = { global: bridge.global, bridge: bridgeItem };
      }
      return bridgeItem;
    });
    return bridge;
  });

  const deserializedSuccess = async (deserializedReturn) => {
    await setBridgeDeserialized(deserializedReturn);
  };

  const { status, refetch: fetchBridgeDeserialized } =
    useBridgeDeserializedFetch(currentBridge, deserializedSuccess);

  console.log(bridgeDeserialized);
  const setBridgeData = async () => {
    let peerCount;
    let trustTotal;
    let phyliTransferred;
    let totalBalance;
    let totalHold;
    let globalData = {};
    if ((await bridgeDeserialized) !== undefined) {
      peerCount = 0;
      trustTotal = 0;
      phyliTransferred = 0;
      totalBalance = 0;
      totalHold = 0;
      globalData = {};
      GLOBAL_STATS.map((item) => {
        if (item.BlockNumber === currentBridge.global) {
          globalData = item;
        }
        return globalData;
      });

      if (bridgeDeserialized.BlockType === 5) {
        bridgeDeserialized.syncList.PeerStates.map((peer) => {
          peerCount += 1;
          trustTotal += peer.SyncTrust + 1;
          phyliTransferred += peer.Stamps;
          totalBalance += peer.Balance;
          totalHold += peer.Hold;
          return peer;
        });

        setCurrentBridgeInfo({
          ...currentBridgeInfo,
          peerCount: peerCount,
          phyliTransferred: phyliTransferred,
          globalHoldMargin: (
            (totalHold / globalData.GlobalPhyliBal) *
            100
          ).toFixed(2),
          bridgeHoldMargin: ((totalHold / totalBalance) * 100).toFixed(2),
          CumulativeBridgeTrust: trustTotal,
          globalPLPercentage: ((peerCount / 6) * 100).toFixed(2),
        });
      } else if (bridgeDeserialized.BlockType === 6) {
        bridgeDeserialized.Payload.FinalStates[0].PeerStates.map((peer) => {
          peerCount += 1;
          trustTotal += peer.SyncTrust + 1;
          phyliTransferred += peer.Stamps;
          totalBalance += peer.Balance;
          totalHold += peer.Hold;
          return peer;
        });

        setCurrentBridgeInfo({
          ...currentBridgeInfo,
          peerCount: peerCount,
          phyliTransferred: phyliTransferred,
          globalHoldMargin: (
            (totalHold / globalData.GlobalPhyliBal) *
            100
          ).toFixed(2),
          bridgeHoldMargin: ((totalHold / totalBalance) * 100).toFixed(2),
          CumulativeBridgeTrust: trustTotal,
          globalPLPercentage: ((peerCount / 6) * 100).toFixed(2),
        });
      } else if (bridgeDeserialized.BlockType === 7) {
        // bridgeDeserialized.PeerStates.map((peer) => {
        //   peerCount += 1;
        //   trustTotal += peer.SyncTrust + 1;
        //   phyliTransferred += peer.Stamps;
        //   totalBalance += peer.Balance;
        //   totalHold += peer.Hold;
        //   return peer;
        // });

        setCurrentBridgeInfo({
          ...currentBridgeInfo,
          peerCount: 0,
          phyliTransferred: 1287,
          globalHoldMargin: "No Data",
          bridgeHoldMargin: "No Data",
          CumulativeBridgeTrust: trustTotal,
          globalPLPercentage: ((3 / 6) * 100).toFixed(2),
        });
      }
    }
  };

  //       if (bridgeDeserialized.BlockType === 5) {
  //         bridgeDeserialized.PeerStates.map((peer) => {
  //           peerCount += 1;
  //           trustTotal += peer.SyncTrust + 1;
  //           phyliTransferred += peer.Stamps;
  //           totalBalance += peer.Balance;
  //           totalHold += peer.Hold;
  //           return peer;
  //         });

  //         setCurrentBridgeInfo({
  //           ...currentBridgeInfo,
  //           peerCount: peerCount,
  //           phyliTransferred: phyliTransferred,
  //           globalHoldMargin: ((totalHold / globalData.GlobalPhyliBal) * 100).toFixed(2),
  //           bridgeHoldMargin: ((totalHold / totalBalance) * 100).toFixed(2),
  //           cumulativeBridgeTrust: trustTotal,
  //           globalPLPercentage: ((peerCount / 6) * 100).toFixed(2),
  //         });
  //       } else if (bridgeDeserialized.BlockType === 6) {
  //         bridgeDeserialized.Payload.FinalPeerStates[0].map((peer) => {
  //           peerCount += 1;
  //           trustTotal += peer.SyncTrust + 2;
  //           phyliTransferred += peer.Stamps;
  //           totalBalance += peer.Balance;
  //           totalHold += peer.Hold;
  //           return peer;
  //         });

  //         setCurrentBridgeInfo({
  //           ...currentBridgeInfo,
  //           peerCount: peerCount,
  //           phyliTransferred: phyliTransferred,
  //           globalHoldMargin: ((totalHold / globalData.GlobalPhyliBal) * 100).toFixed(2),
  //           bridgeHoldMargin: ((totalHold / totalBalance) * 100).toFixed(2),
  //           cumulativeBridgeTrust: trustTotal,
  //           globalPLPercentage: ((peerCount / 6) * 100).toFixed(2),
  //         });
  //       } else if (bridgeDeserialized.BlockType === 7) {
  //         bridgeDeserialized.BridgeState.DivisionStates[0].ActivePeerStates.map((peer) => {
  //           peerCount += 1;
  //           trustTotal += peer.SyncTrust + 3;
  //           phyliTransferred += peer.Stamps;
  //           totalBalance += peer.Balance;
  //           totalHold += peer.Hold;
  //           return peer;
  //         });

  //         setCurrentBridgeInfo({
  //           ...currentBridgeInfo,
  //           peerCount: peerCount,
  //           phyliTransferred: phyliTransferred,
  //           globalHoldMargin: ((totalHold / globalData.GlobalPhyliBal) * 100).toFixed(2),
  //           bridgeHoldMargin: ((totalHold / totalBalance) * 100).toFixed(2),
  //           cumulativeBridgeTrust: trustTotal,
  //           globalPLPercentage: ((peerCount / 6) * 100).toFixed(2),
  //         });
  //       }
  //     }
  //   }
  //   return;
  // };

  try {
    // ***** FETCH DESERIALIZED BRIDGE USING FETCH FUNCTION ****
    useEffect(() => {
      if (
        parseInt(currentBridge.global) !== 0 &&
        parseInt(currentBridge.bridge.BlockNumber) !== 0
      ) {
        fetchBridgeDeserialized();
      }
      if (status === "success" || status === "idle") {
        setBridgeData();
      }
      // setDeserialized(currentDeserialized);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentBridge.bridge.BlockNumber]);
  } catch (error) {}
  return (
    <div className='bridge-view '>
      <div className='card-row'>
        <SmallCard
          className='card card-15'
          title={PAGE_INFO.bridgeConsensusView.TotalPhyliTransferred}
          content={currentBridgeInfo.phyliTransferred}
        />
        <SmallCard
          className='card card-15'
          title={PAGE_INFO.bridgeConsensusView.GlobalHoldMargin}
          content={`${currentBridgeInfo.globalHoldMargin}%`}
        />
        <SmallCard
          className='card card-15'
          title={PAGE_INFO.bridgeConsensusView.BridgeHoldMargin}
          content={`${currentBridgeInfo.bridgeHoldMargin}%`}
        />
        <SmallCard
          className='card card-15'
          title={PAGE_INFO.bridgeConsensusView.CumulativeBridgeTrust}
          content={currentBridgeInfo.cumulativeBridgeTrust}
        />
        <SmallCard
          className='card card-15'
          title={PAGE_INFO.bridgeConsensusView.NumberOfSL}
          content={`${currentBridgeInfo.peerCount}/6`}
        />
        <SmallCard
          className='card card-15'
          title={PAGE_INFO.bridgeConsensusView.GlobalPLPercentage}
          content={`${currentBridgeInfo.globalPLPercentage}%`}
        />
      </div>
      <div className='bridge-chart-container'>
        <div className='bridge-line'>
          <BridgeConsensusLine treeData={treeData} />
        </div>
        <div className='bridge-pie'>
          <h1>Bridge Trust Distribution</h1>
          <BridgeConsensusPie />
        </div>
      </div>
    </div>
  );
};

export default BridgeConsensus_1;
