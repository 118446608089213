export const AREA1 = [
  { lat: 40.262, lng: -105.0458 },
  { lat: 40.2622, lng: -105.0365 },
  { lat: 40.2696, lng: -105.0365 },
  { lat: 40.2702, lng: -104.999 },
  { lat: 40.2773, lng: -104.999 },
  { lat: 40.2775, lng: -104.9515 },
  { lat: 40.2627, lng: -104.951 },
  { lat: 40.2629, lng: -104.9146 },
  { lat: 40.3019, lng: -104.9142 },
  { lat: 40.3019, lng: -104.8961 },
  { lat: 40.2696, lng: -104.8961 },
  { lat: 40.2696, lng: -104.877 },
  { lat: 40.2584, lng: -104.8961 },
  { lat: 40.2559, lng: -104.8861 },
  { lat: 40.2331, lng: -104.8897 },
  { lat: 40.2327, lng: -104.8577 },
  { lat: 40.2168, lng: -104.8482 },
  { lat: 40.2075, lng: -104.8278 },
  { lat: 40.1884, lng: -104.8295 },
  { lat: 40.1885, lng: -104.8185 },
  { lat: 40.2064, lng: -104.8192 },
  { lat: 40.2064, lng: -104.8017 },
  { lat: 40.2217, lng: -104.7589 },
  { lat: 40.2293, lng: -104.7589 },
  { lat: 40.2293, lng: -104.7489 },
  { lat: 40.2325, lng: -104.7489 },
  { lat: 40.2325, lng: -104.7163 },
  { lat: 40.2545, lng: -104.7153 },
  { lat: 40.2545, lng: -104.6401 },
  { lat: 40.2618, lng: -104.6398 },
  { lat: 40.2618, lng: -104.5897 },
  { lat: 40.175, lng: -104.5842 },
  { lat: 40.1741, lng: -104.4424 },
  { lat: 40.1172, lng: -104.4422 },
  { lat: 40.117, lng: -104.4803 },
  { lat: 40.0629, lng: -104.4821 },
  { lat: 40.0467, lng: -104.5185 },
  { lat: 39.9132, lng: -104.5083 },
  { lat: 39.8984, lng: -104.5378 },
  { lat: 39.8963, lng: -104.7287 },
  { lat: 39.8468, lng: -104.7322 },
  { lat: 39.841, lng: -104.7898 },
  { lat: 39.8695, lng: -104.796 },
  { lat: 39.8726, lng: -104.8839 },
  { lat: 39.8852, lng: -104.8839 },
  { lat: 39.886, lng: -104.9263 },
  { lat: 39.9581, lng: -104.9134 },
  { lat: 39.9507, lng: -105.0531 },
  { lat: 40.0655, lng: -105.0565 },
  { lat: 40.0671, lng: -105.1416 },
  { lat: 40.0808, lng: -105.1399 },
  { lat: 40.0841, lng: -105.0894 },
  { lat: 40.1041, lng: -105.0887 },
  { lat: 40.1031, lng: -105.0517 },
  { lat: 40.2622, lng: -105.0454 },
];

export const AREA2 = [
  { lat: 39.9721, lng: -105.3464 },
  { lat: 39.9716, lng: -105.3234 },
  { lat: 39.9137, lng: -105.3231 },
  { lat: 39.9139, lng: -105.2592 },
  { lat: 39.7689, lng: -105.2591 },
  { lat: 39.7697, lng: -105.3872 },
  { lat: 39.7626, lng: -105.3886 },
  { lat: 39.7697, lng: -105.3872 },
  { lat: 39.7626, lng: -105.3886 },
  { lat: 39.8035, lng: -105.461 },
  { lat: 39.8169, lng: -105.461 },
  { lat: 39.8167, lng: -105.4988 },
  { lat: 39.8138, lng: -105.4991 },
  { lat: 39.8135, lng: -105.5366 },
  { lat: 39.8243, lng: -105.5366 },
  { lat: 39.8232, lng: -105.6139 },
  { lat: 39.8507, lng: -105.661 },
  { lat: 39.8502, lng: -105.6874 },
  { lat: 39.8926, lng: -105.698 },
  { lat: 39.9311, lng: -105.6759 },
  { lat: 39.9327, lng: -105.5187 },
  { lat: 39.9403, lng: -105.5187 },
  { lat: 39.9403, lng: -105.4991 },
  { lat: 39.9477, lng: -105.4988 },
  { lat: 39.9485, lng: -105.4071 },
  { lat: 39.9609, lng: -105.3694 },
  { lat: 39.9612, lng: -105.346 },
  { lat: 39.9721, lng: -105.3464 },
];
