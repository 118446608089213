import { useState } from "react";

import "./Button.styles.scss";

const Button = ({ text, checkBoxes, title, slider }) => {
  const [btnType, setBtnType] = useState(false);
  const [btnTransactions, setBtnTransactions] = useState(false);
  const buttonClicked = () => {
    if (text === "Transactions") {
      setBtnTransactions(!btnTransactions);
      slider.setSlideMenu(false);
    }
    if (text === "Type") {
      setBtnType(!btnType);
      slider.setSlideMenu(false);
    }
  };

  const radioButtonSelected = async (evt) => {
    await checkBoxes.setTransactionRange(evt);
  };

  return (
    <div className='button-group'>
      <button onClick={() => buttonClicked()} title={title}>
        {text}
      </button>
      {btnType && text === "Type" ? (
        <div className='drop-down-menu'>
          <ul>
            <li className='drop-down-item'>
              <input
                className='drop-down-item'
                type='checkbox'
                onChange={() => checkBoxes.setProductionBox(!checkBoxes.productionBox)}
                checked={checkBoxes.productionBox}
                label='Generation'
              />
              {" Generation"}
            </li>
            <li className='drop-down-item'>
              <input
                className='drop-down-item'
                type='checkbox'
                onChange={() => checkBoxes.setSubstationBox(!checkBoxes.substationBox)}
                checked={checkBoxes.substationBox}
              />
              {"Substations"}
            </li>
            <li className='drop-down-item'>
              <input
                className='drop-down-item'
                type='checkbox'
                onChange={() => checkBoxes.setMetersBox(!checkBoxes.metersBox)}
                checked={checkBoxes.metersBox}
              />
              {" Meters"}
            </li>
          </ul>
        </div>
      ) : null}
      {btnTransactions && text === "Transactions" ? (
        <div className='drop-down-menu'>
          <ul>
            <li className='drop-down-item'>
              <input
                type='radio'
                name='transactions'
                value='all'
                checked={checkBoxes.transactionRange === "all"}
                onClick={(evt) => checkBoxes.setTransactionRange(evt.target.value)}
              />
              {"   All"}
            </li>
            <li className='drop-down-item'>
              <input
                type='radio'
                name='transactions'
                value='range1'
                checked={checkBoxes.transactionRange === "range1"}
                onClick={(evt) => checkBoxes.setTransactionRange(evt.target.value)}
              />
              {"   1 - 10"}
            </li>
            <li className='drop-down-item'>
              <input
                type='radio'
                name='transactions'
                value='range2'
                checked={checkBoxes.transactionRange === "range2"}
                onClick={(evt) => checkBoxes.setTransactionRange(evt.target.value)}
              />
              {"   11 - 20"}
            </li>
            <li className='drop-down-item'>
              <input
                type='radio'
                name='transactions'
                value='range3'
                checked={checkBoxes.transactionRange === "range3"}
                onClick={(evt) => radioButtonSelected(evt.target.value)}
              />
              {"   21 - 30"}
            </li>
            <li className='drop-down-item'>
              <input
                type='radio'
                name='transactions'
                value='range4'
                checked={checkBoxes.transactionRange === "range4"}
                onClick={(evt) => radioButtonSelected(evt.target.value)}
              />
              {"   31 - 40"}
            </li>
            <li className='drop-down-item'>
              <input
                type='radio'
                name='transactions'
                value='range5'
                checked={checkBoxes.transactionRange === "range5"}
                onClick={async (evt) => {
                  await radioButtonSelected(evt.target.value);
                }}
              />
              {"   40+"}
            </li>
          </ul>
        </div>
      ) : null}
    </div>
  );
};

export default Button;
