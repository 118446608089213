import { createContext, useState } from "react";

export const GlobalsContext = createContext([]);

const GlobalsContextProvider = (props) => {
  const [globalParameters, setGlobalParameters] = useState([]);
  const [blockLast, setBlockLast] = useState({
    isLoading: false,
    isError: null,
    blockLastData: null,
  });
  const [globals, setGlobals] = useState({
    isLoading: false,
    isError: false,
    globalData: [],
  });

  const [globalDeserialized, setGlobalDeserialized] = useState({});
  // const [cookie, setCookie] = useState();

  const [selectedGlobal, setSelectedGlobal] = useState(0);

  return (
    <GlobalsContext.Provider
      value={{
        blockLast,
        setBlockLast,
        globals,
        setGlobals,
        selectedGlobal,
        setSelectedGlobal,
        globalParameters,
        setGlobalParameters,
        globalDeserialized,
        setGlobalDeserialized,
      }}
    >
      {props.children}
    </GlobalsContext.Provider>
  );
};

export default GlobalsContextProvider;
